// Controller for the voters roll administration page

module.exports = ['$scope', '$rootScope', '$http', '$state', '$timeout', '$interval', '$window', 'popups', '$stateParams', function ($scope, $rootScope, $http, $state, $timeout, $interval, $window, popups, $stateParams) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    $scope.device = {
        Id: null,
        Code: null
    };
    $scope.gettingActivation = false;
    $scope.gettingIdle = false;
    $scope.settingReject = false;
    $scope.msg = null;

    if (localStorage.vdToken) {
        setActiveDevice();
    } else {
        $scope.status = 'Activation';
    }

    $scope.activateDevice = function () {
        $scope.msg = null;
        if (!$scope.device.Id || $scope.device.Id == '' || !$scope.device.Code || $scope.device.Code == '') {
            $scope.msg = 'Please add a device ID and Access Code.';
        } else {
            if (!$scope.gettingActivation) {
                $scope.gettingActivation = true;
                $http.post('/api/manageVoting/activateDevice/' + sessionId, {
                    device: $scope.device
                }).then(function (resp) {
                    if (resp && resp.data && resp.data.status) {
                        localStorage.setItem('vdToken', resp.data.token);
                        setActiveDevice();
                    } else {
                        $scope.msg = 'An unknown error has occurred. Please try again or contact the administrator';
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        $scope.msg = err.data.message;
                    }
                }).finally(function () {
                    $scope.gettingActivation = false;
                });
            } else {
                $scope.msg = null;
            }
        }
    };

    function setActiveDevice() {
        var token = JSON.parse(localStorage.vdToken);
        $scope.device = {
            Id: token.details.Id,
            Name: token.details.Name
        };
        $scope.vdToken = token.token;
        $scope.request = null;
        $scope.voter = null;
        $scope.status = 'Idle';
        $scope.msg = null;
        startUpdates();
    }

    function updateIdle() {
        if (!$scope.gettingIdle) {
            $scope.gettingIdle = true;
            $http.post('/api/manageVoting/getAllocation/' + sessionId, {
                token: $scope.vdToken
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) { // If a voter was allocated to this device
                    $scope.request = {
                        Id: resp.data.request,
                        Code: ''
                    };
                    $scope.status = 'Allocated';
                    stopUpdates(); // Stop the automatic allocation search
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.gettingIdle = false;
            });
        }
    }

    // Get the session settings from the database
    $scope.getSessDetails = function () {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    };

    $scope.activateVoting = function () {
        $scope.msg = null;
        if (!$scope.request.Code || $scope.request.Code == '') {
            $scope.msg = 'Please add an access code to continue.';
        } else {
            if (!$scope.gettingActivation) {
                $scope.gettingActivation = true;
                $http.post('/api/manageVoting/activateVoting/' + sessionId, {
                    request: $scope.request,
                    token: $scope.vdToken
                }).then(function (resp) {
                    if (resp && resp.data && resp.data.status) {
                        $scope.voter = resp.data.voter;
                        $scope.status = 'VoterDetails'; // Activate the confirmation screen
                    } else {
                        $scope.msg = 'An unknown error has occurred. Please try again or contact the administrator';
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        $scope.msg = err.data.message;
                        if (err.data.locked) {// If the record is locked
                            $timeout(function () {
                                setActiveDevice();
                            }, 1000 * 10); // Return to idle after 10 seconds
                        }
                    }
                }).finally(function () {
                    $scope.gettingActivation = false;
                });
            }
        }
    };

    $scope.voterVerified = function (resp) {
        if (resp == "No") {
            if (!$scope.settingReject) {
                $scope.settingReject = true;
                $http.post('/api/manageVoting/rejectVoting/' + sessionId, {
                    token: $scope.vdToken
                }).then(function (resp) {
                    if (resp && resp.data && resp.data.status) {
                        setActiveDevice();
                    } else {
                        $scope.msg = 'An unknown error has occurred. Please try again or contact the administrator';
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        $scope.msg = err.data.message;
                    }
                }).finally(function () {
                    $scope.settingReject = false;
                });
            }
        } else {
            $scope.status = "Ballot";
            openBallot();
        }
    };

    function openBallot() {
        $rootScope.$emit('CreateManagedBallot', {
            Candidates: $scope.Candidates,
            SessionSettings: $scope.SessionSettings
        });
        $scope.status = 'Ballot';
    }

    function reopenBallot(votes) {
        $rootScope.$emit('ReopenManagedBallot', {
            Candidates: votes,
            SessionSettings: $scope.SessionSettings
        });
        $scope.status = 'Ballot';
    }

    // The return from the ballot and submit to the database
    var deregister = $rootScope.$on('CreateManagedBallotReturn', function (event, DataObj) {
        var captureVotes = DataObj.Candidates;
        $scope.msg = null;
        $http.post('/api/manageVoting/sendBallotManaged/' + sessionId, { // Send the votes to the server
            votes: captureVotes,
            token: $scope.vdToken
        }).then(function (resp) {
            if (resp.data.status) { // If the capture was valid
                $scope.status = 'Completed';
                $timeout(function () {
                    setActiveDevice();
                }, 1000 * 5); // Return to idle after 5 seconds
            } else {
                reopenBallot(captureVotes);
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Voting');
                $scope.msg = err.data.message;
                setActiveDevice();
            }
        });
    });

    // Set up a recurring function to search for allocations
    function startUpdates() {
        var waitseconds = 1; // Wait 1 second to request the update the first time
        var intervalseconds = 10; // Run the function every 10 seconds as any current pending requests will be picked up immediately
        $timeout(function () {
            updateIdle();
        }, 1000 * waitseconds); // Request an update after a short period
        $scope.getDataInterval = null;
        $scope.getDataInterval = $interval(function () {
            updateIdle();
        }, 1000 * intervalseconds); // Start the recurring update
    }

    // Stop the automatic updates
    function stopUpdates() {
        if ($scope.getDataInterval) {
            $interval.cancel($scope.getDataInterval);
        }
    }

    $scope.logOut = function () {
        popups.confirmPopup('Are you sure you want to logout this voting client?', 'Log Out').then(function () {
            $http.post('/api/manageVoting/logOut/' + sessionId, { // Set the device to deactivated
                token: $scope.vdToken
            }).then(function (resp) {
                if (!resp.data.status) {
                    popups.alertPopup("An unknown error has occurred. Please contact the administrator");
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Log Out');
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $state.go('el.sessions');
                return;
            });
        });
    };

    // Execute on exiting the page
    $scope.$on('$destroy', function () {
        localStorage.removeItem('vdToken');
        stopUpdates();
        deregister();
    });

    // Load the data after script creation
    $scope.getSessDetails();
    $rootScope.cancelIdleTimer();

}];
