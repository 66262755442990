// Controller for the managed voting agent

module.exports = ['$scope', '$rootScope', '$http', '$state', '$window', '$interval', '$timeout', 'ngDialog', 'popups', '$stateParams', 'appState', function ($scope, $rootScope, $http, $state, $window, $interval, $timeout, ngDialog, popups, $stateParams, appState) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Initiate the variables
    $scope.msg = '';
    $scope.searchCriteria = {
        Name: '',
        Surname: '',
        Email: '',
        Numb: '',
        Numb_2: ''
    };
    $scope.fullRoll = null;
    $scope.filterVoters = [];
    $scope.gettingData = false;
    $scope.activeVoter = null;
    $scope.sendingVoter = false;
    $scope.getUpdate = false;

    localStorage.removeItem('Voter'); // Clear the local storage when the page loads

    // Get the session settings from the database
    $scope.getSessDetails = function () {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    };

    // To filter the roll based on the search criteria
    function filterRoll() {
        $scope.filterVoters = [];
        if ($scope.fullRoll.length > 0) {
            $scope.fullRoll.forEach(function (voter) {
                if (voter.Name.toUpperCase().includes($scope.searchCriteria.Name.toUpperCase()) && voter.Surname.toUpperCase().includes($scope.searchCriteria.Surname.toUpperCase()) && voter.Email.toUpperCase().includes($scope.searchCriteria.Email.toUpperCase()) && (voter.Numb == null || voter.Numb.toUpperCase().includes($scope.searchCriteria.Numb.toUpperCase())) && (voter.Numb_2 == null || voter.Numb_2.toUpperCase().includes($scope.searchCriteria.Numb_2.toUpperCase()))) {
                    $scope.filterVoters.push(voter);
                }
            });
        }
    }

    // To search the voters roll interactively
    $scope.searchRoll = function () {
        var searchLength = $scope.searchCriteria.Name.length + $scope.searchCriteria.Surname.length + $scope.searchCriteria.Email.length + $scope.searchCriteria.Numb.length + $scope.searchCriteria.Numb_2.length;
        if (searchLength < 5 && $scope.fullRoll) { // If the voters roll has been downloaded, but the search criteria changed below five, remove the roll to start over
            $scope.fullRoll = null;
            $scope.filterVoters = [];
        } else if (searchLength >= 5 && !$scope.fullRoll && !$scope.gettingData) { // If the search criteria is above 5 characters, there is no current roll downloaded and the roll is not in the process of being downloaded, download the roll
            $scope.gettingData = true;
            $http.post('/api/getSearchRoll/' + sessionId, {
                Critera: $scope.searchCriteria
            }).then(function (resp) {
                $scope.fullRoll = resp.data;
                filterRoll();
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.gettingData = false;
            });
        } else if (searchLength >= 5 && !!$scope.fullRoll) { // If the search criteria is above 5 characters, but there is a roll, just filter the current roll.
            filterRoll();
        }
    };

    // Get the date in the correct format or return an empty string if the date is blank
    $scope.getDate = function (newDate) {
        if (newDate) {
            var returnDate = new Date(newDate).toLocaleString('en-ZA');
            return returnDate;
        } else {
            return '';
        }
    };

    $scope.clearCrit = function () {
        $scope.searchCriteria = {
            Name: '',
            Surname: '',
            Email: '',
            Numb: '',
            Numb_2: ''
        };
        $scope.fullRoll = null;
        $scope.filterVoters = [];
    };

    // Activate the voter details for confirmation
    $scope.activateVoting = function (voter) {
        $scope.activeVoter = voter;
        $scope.activeVoter.Settings = {
            Name: $scope.SessionSettings.Name,
            Show_Number: $scope.SessionSettings.Show_Number,
            Number_Name: $scope.SessionSettings.Number_Name,
            Show_Number_2: $scope.SessionSettings.Show_Number_2,
            Number_Name_2: $scope.SessionSettings.Number_Name_2
        };
        localStorage.setItem('Voter', JSON.stringify($scope.activeVoter));
    };

    // Voter details confirmed. To add this to the requests for voting.
    $scope.confirmDetails = function () {
        if (!$scope.sendingVoter) { // If the voter is not already being processed
            $scope.sendingVoter = true;
            $http.post('/api/manageVoting/sendVoter/' + sessionId, {
                Voter: $scope.activeVoter
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    $scope.activeVoter.Status = resp.data.status;
                    if (resp.data.status == 'Pending') { // If the request was added
                        startUpdates();
                    } else if (resp.data.status == 'Allocated') { // If the request was previously added and is allocated
                        allocateRequest(resp.data.voteRequest); // Display the device and Access Code
                    } else { // If the request has already been started
                        popups.alertPopup('The request has already been started at the device.');
                        stopUpdates();
                    }
                } else {
                    popups.alertPopup('An unknown error has occurred. Please contact the administrator');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.sendingVoter = false;
            });
        } else {
            popups.alertPopup('The request has already been sent.');
        }
    };

    // Update the status of the request
    $scope.updatePending = function () {
        if (!$scope.getUpdate) { // If there is not already an update in progress
            $scope.getUpdate = true;
            $http.post('/api/manageVoting/getVoterUpdate/' + sessionId, {
                Voter: $scope.activeVoter
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    $scope.activeVoter.Status = resp.data.status;
                    if (resp.data.status == 'Allocated') { // If the request has been allocated
                        allocateRequest(resp.data.voteRequest);
                    } else if (resp.data.status == 'Started') { // If the request has been started
                        popups.alertPopup('The request has already been started at the device.');
                        stopUpdates();
                    }
                } else {
                    popups.alertPopup('An unknown error has occurred. Please contact the administrator');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.getUpdate = false;
            });
        } else {
            popups.alertPopup('There is already an update requested');
        }
    };

    // Set up a recurring function to update the view
    function startUpdates() {
        var waitseconds = 3; // Wait 3 seconds to request the update the first time
        var intervalseconds = 10; // Run the function every 10 seconds
        $timeout(function () {
            $scope.updatePending();
        }, 1000 * waitseconds); // Request an update after a short period
        $scope.getDataInterval = null;
        $scope.getDataInterval = $interval(function () {
            $scope.updatePending();
        }, 1000 * intervalseconds); // Start the recurring update
    }

    // Stop the automatic updates
    function stopUpdates() {
        if ($scope.getDataInterval) {
            $interval.cancel($scope.getDataInterval);
        }
    }

    function allocateRequest(request) {
        stopUpdates();
        $scope.activeVoter.Pending = null;
        $scope.activeVoter.Station = {
            Id: request.Id,
            Name: request.Name,
            Code: request.AccessCode
        };
        localStorage.setItem('Voter', JSON.stringify($scope.activeVoter));
    }

    $scope.cancelVoting = function () {
        localStorage.removeItem('Voter');
        $scope.activeVoter = null;
        $scope.clearCrit();
        stopUpdates();
    };

    $scope.displayCode = function () {
        localStorage.removeItem('Voter');
        localStorage.setItem('Voter', JSON.stringify($scope.activeVoter));
    };

    $scope.openWindow = function () {
        var url = $state.href('eom.managedVotingAgentDisplay', { sessionId: sessionId });
        $window.open(url, '_blank');
    };

    // Load the data after script creation
    $scope.getSessDetails();

    $scope.$on('$destroy', function () {
        localStorage.removeItem('Voter');
        stopUpdates();
    });

}];
