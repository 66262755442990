// Controller to choose to log a query or submit a form for a new session
module.exports = ['$scope', '$cookies', '$state', '$stateParams', function ($scope, $cookies, $state, $stateParams) {

    $scope.csrfToken = $cookies.get('XSRF-TOKEN');

    $scope.Query = function () {
        $state.go('elo.acc-create', {
            email: $stateParams.email
        }, {
                reload: true
            }
        );
    };

}];