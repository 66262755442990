var angular = require('angular');

require('./modules/ui-jq');
require('./modules/ui-load');

angular.module('Elections', [
    'ngDialog',
    'ui.router',
    'ui.load',
    'ui.jq',
    'ngStorage',
    'ngCookies',
    'ngRoute',
    'ngFileUpload',
    'oc.lazyLoad',
    'ui.router.state.events',
    'nvd3'
]).constant('appConsts', {
    formReqHdr: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    jsonReqHdr: {
        'Content-Type': 'application/json'
    }
}).config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push(['appState', function (appState) {
        return {
            request: function ($config) {
                $config.headers['Authorization'] = appState.token;
                return $config;
            }
        };
    }]);

    $httpProvider.interceptors.push(['$q','$state', '$location', 'appState',
        function ($q, $state, $location, appState) {
            return {
                'request': function (config) {
                    config.headers = config.headers || {};
                    return config;
                },
                'responseError': function (response) { // Interceptor for the token validation middleware
                    if (response && response.data) {
                        if (response.data.token_status === "INVALID_TOKEN" || response.data.token_status === "NOT_PROVIDED") {
                            appState.$reset();
                            $state.go('elo.login', {
                                err: response.data.message
                            });
                            //$location.path('/login_page');
                        } else if (response.status == 429) {
                            console.log(response.data.message);
                        }
                        return $q.reject(response);
                    }
                }
            };
        }
    ]);
}]).run(['$rootScope', 'appState', '$document', '$http', '$state', '$timeout', function ($rootScope, appState, $document, $http, $state, $timeout) {
    $rootScope.baseUrl = apiUrl + '/';
    $rootScope.baseUrlDomain = apiUrl;

    if (appState.user) {
        $rootScope.isLoggedIn = true;
        $rootScope.authUser = appState.user;
    }

    // make the dialog draggable
    $rootScope.$on('ngDialog.opened', function (e, $dialog) {
        $('.ngdialog-content').draggable();
    });

     // Show alert
    $rootScope.alert = function (message, type) {
        var el = $('.alerts-container .alert');
        if (el.length === 0)
            return;

        el.removeClass('alert-danger alert-danger alert-warning alert-success alert-info');
        if (type === 'error')
            el.addClass('alert-danger');
        if (type === 'warning')
            el.addClass('alert-warning');
        if (type === 'success')
            el.addClass('alert-success');
        else
            el.addClass('alert-info');

        el.text(message);
        el.fadeIn("fast", function () {
            setTimeout(function () {
                el.fadeOut("slow", function () {
                    el.text('');
                });
            }, 5000);
        });
    };

    /**
     * Logout
     */
    $rootScope.logout = function () {
        $http.post('/api/logout')
            .then(function (resp) {                                    
                appState.$reset();
            })
            .catch(function (err) {
                appState.$reset();
            })
            .finally(function() {
                $rootScope.$broadcast('el.logout');
                $state.go('elo.login');
            });
    };
    
    /*=========================================
     * Set up Idle time-out
     */
    // Timeout timer value
    var TimeOutTimerValue = 1000 * 60 * 15; // 15 min

    // Start a timeout
    var TimeOut_Thread;
    var bodyElement = angular.element($document);

    function userActivityEventHandler(e) {
        TimeOut_Resetter(e)
    }

    function setupIdleTimer() {
        cancelIdleTimer();
        
        TimeOut_Thread = $timeout(function(){ LogoutByTimer() } , TimeOutTimerValue);
        angular.forEach([
            /// Keyboard Events
            'keydown', 'keyup',
            /// Mouse Events
            'click', 'mousemove', 'DOMMouseScroll', 'mousewheel', 'mousedown',
            /// Touch Events
            'touchstart', 'touchmove',
            /// Common Events
            'scroll', 'focus'
        ], function(EventName) {
             bodyElement.bind(EventName, userActivityEventHandler);
        });
    }

    function cancelIdleTimer() {
        if(TimeOut_Thread) {
            $timeout.cancel(TimeOut_Thread);
        }
        // turn off event binders
        angular.forEach([
            /// Keyboard Events
            'keydown', 'keyup',
            /// Mouse Events
            'click', 'mousemove', 'DOMMouseScroll', 'mousewheel', 'mousedown',
            /// Touch Events
            'touchstart', 'touchmove',
            /// Common Events
            'scroll', 'focus'
        ], function(EventName) {
            bodyElement.unbind(EventName, userActivityEventHandler);
        });
    }

    function LogoutByTimer(){
        if (appState.user) {
            // user is now logged in
            $rootScope.logout();       
            alert("You have been inactive for a while. Please login again!");
        } else {
            // user is not logged in now
            cancelIdleTimer();
        }
    }

    function TimeOut_Resetter(e){

        /// Stop the pending timeout
        $timeout.cancel(TimeOut_Thread);

        /// Reset the timeout
        TimeOut_Thread = $timeout(function(){ LogoutByTimer() } , TimeOutTimerValue);
    }

    // The el.login is never set to activate the idle timer.
    $rootScope.$on('el.login', function () {
        setupIdleTimer();
    });
    $rootScope.$on('el.logout', function () {
        cancelIdleTimer();
    });

    $rootScope.cancelIdleTimer = function () {
        cancelIdleTimer();
    };

    $rootScope.startIdleTimer = function () {
        setupIdleTimer();
    };

    if(appState.user) {
        setupIdleTimer();
    }
}]);

require('./filters');
require('./services');
require('./factories');
require('./directives');
require('./controllers');
require('./config.router');
