// Controller for the proxy form

module.exports = ['$scope', '$http', '$state', '$rootScope', 'popups', 'ngDialog',
    function ($scope, $http, $state, $rootScope, popups, ngDialog) {
        // Get the candidates from the list. This is called after the user is verified as active on the voters roll.
        var sessionId = null;
        var batch = null;

        // Create the proxy for capturing
        var deregister1 = $rootScope.$on('CreateCaptureProxy', function (event, DataObj) {
            $scope.ProxySettings = DataObj.ProxySettings;
            $scope.SessionSettings = DataObj.SessionSettings;
            sessionId = DataObj.sessionId;``
            $scope.ProxyType = 'Capture';
            resetProxy();
        });

        // Create the proxy for reviewing
        var deregister2 = $rootScope.$on('CreateReviewProxy', function (event, DataObj) {
            $scope.ProxySettings = DataObj.ProxySettings;
            $scope.SessionSettings = DataObj.SessionSettings;
            sessionId = DataObj.sessionId;
            $scope.ProxyType = 'Review';
            resetProxy();
        });

        // Create the proxy for a second review
        var deregister3 = $rootScope.$on('CreateReview2Proxy', function (event, DataObj) {
            $scope.ProxySettings = DataObj.ProxySettings;
            $scope.SessionSettings = DataObj.SessionSettings;
            sessionId = DataObj.sessionId;
            $scope.ProxyType = 'Review_2';
            resetProxy();
            $scope.Review2List = DataObj.reviewList || null;
            if ($scope.Review2List) { // If there is a current list of second review items
                $scope.activateCapture();
            }
        });

        // Create the proxy for management's reviews
        var deregister4 = $rootScope.$on('CreateManagementProxy', function (event, DataObj) {
            $scope.ProxySettings = DataObj.ProxySettings;
            $scope.SessionSettings = DataObj.SessionSettings;
            sessionId = DataObj.sessionId;
            batch = DataObj.batch;
            $scope.ProxyType = 'ManageReview';
            resetProxy();
            $scope.activateCapture();
        });

        // Create the proxy for viewing only
        var deregister5 = $rootScope.$on('CreateViewProxy', function (event, DataObj) {
            $scope.ProxySettings = DataObj.ProxySettings;
            $scope.SessionSettings = DataObj.SessionSettings;
            sessionId = DataObj.sessionId;
            $scope.ProxyType = 'View';
            resetProxy();
            $scope.activateCapture();
        });

        // Reopen the proxy after a failure
        var deregister6 = $rootScope.$on('ReopenProxy', function (event, DataObj) {
            popups.alertPopup('An error might have occurred. Please try to submit again');
            $scope.ProxySettings = DataObj.ProxySettings;
            $scope.SessionSettings = DataObj.SessionSettings;
            sessionId = DataObj.sessionId;
            $scope.ProxyType = DataObj.ProxyType;
            $scope.Proxy = DataObj.Proxy;
            $scope.Review2List = DataObj.reviewList || null;
            $scope.testpos = $scope.ProxyType == 'Capture' ? 'new' : 'existing';
            $scope.testNumber = 'unique';
            $scope.showView = 'Capture';
        });

        // Set up the top variables
        var validationComments = {
            Voter: {
                name: 'No appointer could be found',
                disqualified: true
            },
            Holder: {
                name: 'No proxy holder could be found',
                disqualified: true
            },
            DupVoterHolder: {
                name: 'The appointer and the proxy holder are the same',
                disqualified: true
            },
            ThisDupVoterProxy: {
                name: 'There is already a proxy captured for this appointer',
                disqualified: true
            },
            OtherDupVoterProxy: {
                name: 'Another proxy was disqualified based on this duplicate',
                disqualified: false
            },
            ThisDupNumProxy: {
                name: 'There is already a proxy captured with this number',
                disqualified: true
            },
            OtherDupNumProxy: {
                name: 'Another proxy was disqualified based on this duplicate number',
                disqualified: false
            }
        };

        function resetProxy() {
            $scope.msg = [];
            $scope.showView = 'Number';
            $scope.submitting = false;
            $scope.testpos = 'unknown';
            $scope.testNumber = 'unknown';
            $scope.Proxy = {};
            $scope.Proxy.Batch = {};
            $scope.Proxy.Batch.Batch = $rootScope.ProxyBatch.Batch;
            $scope.Proxy.Batch.Number = $rootScope.ProxyBatch.Number;
            $scope.testPosition();
        }

        // Add the required fields to the object for the voting screen to function
        function setupProxy() {
            $scope.Proxy.Comments = [];
            $scope.Proxy.Status = '1';
            $scope.Proxy.Voter = null;
            $scope.Proxy.Holder = null;
            if ($scope.ProxySettings.AddFields.length > 0) { // If there are additional fields indicated
                $scope.Proxy.AddFields = {};
                $scope.ProxySettings.AddFields.forEach(function (field) {
                    $scope.Proxy.AddFields[field.Id] = null;
                });
            }
            $scope.testNumber = 'unknown';
            $scope.msg = [];
        }

        // Draw the list of items to be reviewed
        function drawSecondReview() {
            $scope.msg = [];
            $scope.testpos = 'draw';
            $http.post('/api/getSecondReviewNrs/' + sessionId, {
                batch: $scope.Proxy.Batch.Batch,
                percent: $scope.ProxySettings.Percentage_2
            }).then(function (resp) {
                if (resp.data.status) {
                    $scope.Review2List = resp.data.list;
                    $scope.Proxy.Batch.Number = $scope.Review2List[0];
                    $scope.testpos = 'list';
                    getProxy();
                    $scope.testpos = 'existing';
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Proxy Capture/Review');
                    $scope.msg.push(err.data.message);
                }
            });
        }

        // Get the existing proxy from the database
        function getProxy() {
            $scope.msg = [];
            var apiname = '';
            if ($scope.ProxyType == 'View') {
                apiname = '/api/getProxyViewDetails/';
            } else {
                apiname = '/api/getProxyDetails/';
            }
            $http.post(apiname + sessionId, {
                proxy: $scope.Proxy,
                type: $scope.ProxyType
            }).then(function (resp) {
                if (resp.data.status) {
                    var oldProxy = resp.data.proxy;
                    $scope.testNumber = 'unique';
                    $scope.Proxy.Comments = JSON.parse(oldProxy.Comments);
                    $scope.Proxy.Status = oldProxy.Valid == 1 ? '1' : '0';
                    $scope.Proxy.CapStatus = oldProxy.Status;
                    $scope.Proxy.Voter = oldProxy.Voter;
                    $scope.Proxy.Holder = oldProxy.Holder;
                    $scope.Proxy.Number = oldProxy.Number;
                    $scope.Proxy.Capturer = oldProxy.Capturer;
                    $scope.Proxy.Reviewer = oldProxy.Reviewer || null;
                    $scope.Proxy.AuditLog = JSON.parse(oldProxy.AuditLog);
                    $scope.Proxy.AddFields = oldProxy.AddFields;
                    if ($scope.ProxySettings.AddFields.length > 0) { // If there are additional fields indicated
                        $scope.ProxySettings.AddFields.forEach(function (field) {
                            if (!$scope.Proxy.AddFields[field.Id]) {
                                $scope.Proxy.AddFields[field.Id] = null;
                            }
                            if (field.Type == 'DATE') { // If this is a date, convert the value
                                $scope.Proxy.AddFields[field.Id] = new Date($scope.Proxy.AddFields[field.Id]);
                            }
                        });
                    }
                    $scope.showView = 'Capture';
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Proxy Capture/Review');
                    $scope.msg.push(err.data.message);
                    $scope.showView = 'Number';
                    if ($scope.ProxyType == 'Edit') {
                        $scope.ProxyType = 'Capture';
                    }
                }
            });
        }

        $scope.testPosition = function () {
            if ($scope.Proxy.Batch.Number != null && $scope.Proxy.Batch.Number != '') { // If the number is not empty
                $http.post('/api/getBatchTest/' + sessionId, {
                    proxy: $scope.Proxy
                }).then(function (resp) {
                    if (resp.data.status) {
                        if (resp.data.valid) { // If the proxy is new
                            $scope.testpos = 'new';
                        } else {
                            $scope.testpos = 'existing';
                        }
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        popups.alertPopup(err.data.message, 'Proxy Capture/Review');
                        $scope.msg = err.data.message;
                    }
                });
            } else { // If the number is empty
                popups.alertPopup('The batch position cannot be empty', 'Error');
            }
        };

        $scope.activateCapture = function () {
            if ($scope.ProxyType == 'Review_2') {
                if (!$scope.Review2List) { // If there is no current list of second review items
                    drawSecondReview();
                } else {
                    getProxy();
                    $scope.testpos = 'existing';
                }
            } else if ($scope.ProxyType == 'ManageReview' || $scope.ProxyType == 'View') {
                getProxy();
                $scope.testpos = 'existing';
            } else if ($scope.testpos == 'unknown') { // If the positions have not been tested
                $scope.testPosition();
            } else if ($scope.testpos == 'new' && $scope.ProxyType == 'Capture') { // If this is a new proxy that will be captured
                setupProxy();
                $scope.showView = 'Capture';
            } else if ($scope.testpos == 'existing' && $scope.ProxyType == 'Capture') { // If this is an existing proxy that will be edited
                $scope.ProxyType = 'Edit';
                getProxy();
            } else if ($scope.testpos == 'existing' && $scope.ProxyType == 'Review') { // If this is an existing proxy that will be reviewed
                getProxy();
            } else {
                popups.alertPopup('An invalid selection was made', 'Error');
            }
        };

        // To add the selected comment to the list
        $scope.addComment = function () {
            if ($scope.Proxy.Comment_Select.name == 'Other') {
                $scope.Proxy.Comments.push({
                    name: $scope.Proxy.Comment_Other,
                    disqualified: $scope.Proxy.Comment_Other_Disqual
                });
            } else {
                $scope.Proxy.Comments.push($scope.Proxy.Comment_Select);
            }
            $scope.Proxy.Comment_Select = null;
            $scope.Proxy.Comment_Other = null;
            $scope.Proxy.Comment_Other_Disqual = false;
            changeValidity();
        };

        // To delete comments that were added
        $scope.deleteComment = function (comment) {
            popups.confirmPopup("Are you sure you want to delete the comment?").then(function () {
                var item = $scope.Proxy.Comments.indexOf(comment);
                if (item !== -1) {
                    $scope.Proxy.Comments.splice(item, 1);
                }
                changeValidity();
            });
        };

        // To check the validity of the proxy based on the comments
        function checkValidity(callback) {
            var valid = true;
            var count = 0;
            if ($scope.Proxy.Comments.length > 0) {
                $scope.Proxy.Comments.forEach(function (comment) {
                    if (comment.disqualified === true) {
                        valid = false;
                    }
                    count++;
                    if (count >= $scope.Proxy.Comments.length) {
                        callback(valid);
                    }
                });
            } else {
                callback(valid);
            }
        }

        // To change the validity field based on a validity check
        function changeValidity() {
            checkValidity(function (valid) {
                if (valid) {
                    $scope.Proxy.Status = '1';
                } else {
                    $scope.Proxy.Status = '0';
                }
            });
        }

        $scope.showStatus = function () {
            checkValidity(function (valid) {
                if (valid && $scope.Proxy.Status == '0') { // If the proxy was marked as invalid, but there were no reasons given, provide a notification and revert.
                    popups.alertPopup("Please add a reason to the comments for invalidating the proxy");
                    $scope.Proxy.Status = '1';
                } else if (!valid && $scope.Proxy.Status == '1') {
                    popups.alertPopup('Please ensure that you document why the proxy is valid, while there are invalidating comments added.');
                }
            });
        };

        // To add invalidation comments
        function invalidateComment(comment) {
            $scope.Proxy.Comments.push(comment);
            changeValidity();
        }

        // To remove the invalidation comments if needed
        function removeInvalidation(comment) {
            var remove = null;
            var count = 0;
            $scope.Proxy.Comments.forEach(function (item) {
                if (item.name == comment.name) {
                    remove = item;
                }
                count++;
                if (count >= $scope.Proxy.Comments.length) {
                    if (remove) {
                        var found = $scope.Proxy.Comments.indexOf(remove);
                        if (found != -1) {
                            $scope.Proxy.Comments.splice(found, 1);
                        }
                    }
                    changeValidity();
                }
            });
        }

        // To test the proxy number for duplication
        $scope.testProxyNumber = function () {
            $scope.testNumber = 'unknown';
            if ($scope.ProxySettings.Number_Unique) { // If the unique flag was switched on
                removeInvalidation(validationComments['ThisDupNumProxy']);
                $http.post('/api/getDupNumberProxyTest/' + sessionId, {
                    proxy: $scope.Proxy
                }).then(function (resp) {
                    if (resp.data.status) {
                        if (resp.data.proxy) { // If a proxy was returned
                            proxyInvalidationPopup(resp.data.proxy, 'Number');
                        }
                        $scope.testNumber = 'unique';
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        popups.alertPopup(err.data.message, 'Duplicate Number');
                        $scope.msg = err.data.message;
                    }
                });
            }
            if ($scope.ProxySettings.Number_Length && $scope.ProxySettings.Number_Length != 0 && $scope.Proxy.Number.length != $scope.ProxySettings.Number_Length) {
                popups.alertPopup('The proxy number is not the correct length, please revise to ensure the number is correct', 'Proxy Number');
            }
        };

        // Popup to decide which proxy to invalidate
        function proxyInvalidationPopup(proxy, type) {
            var dialog = ngDialog.open({
                template: require('../tpl/select-proxy.html'),
                className: 'ngdialog-theme-default custom-width-60',
                plain: true,
                controller: 'selectProxy',
                showClose: false,
                closeByDocument: false,
                resolve: {
                    'params': function () {
                        return {
                            'type': type,
                            'sessionSettings': $scope.SessionSettings,
                            'sessionId': sessionId,
                            'proxy': proxy,
                            'proxySettings': $scope.ProxySettings
                        };
                    }
                }
            });
            dialog.closePromise.then(function (data) {
                if (data && data.value && data.value == 'Current') { // If the old proxy should be marked as invalid
                    // Send a request to the server to mark the old proxy as invalid
                    $scope.msg = null;
                    $http.post('/api/markDupProxy/' + sessionId, {
                        proxy: proxy,
                        type: type
                    }).then(function (resp) {
                        if (resp.data.status) {
                            popups.alertPopup('The original proxy was marked as disqualified', 'Duplicate Proxy');
                            if (type == 'Appointer') {
                                invalidateComment(validationComments['OtherDupVoterProxy']);
                            } else if (type == 'Number') {
                                invalidateComment(validationComments['OtherDupNumProxy']);
                            }
                        }
                    }).catch(function (err) {
                        if (err && err.data && err.data.message) {
                            popups.alertPopup(err.data.message, 'Duplicate Appointer');
                            $scope.msg = err.data.message;
                        }
                    });
                } else if (data && data.value && data.value == 'New') { // If the new proxy should be marked as invalid
                    popups.alertPopup('The new proxy will be marked as invalid');
                    if (type == 'Appointer') {
                        invalidateComment(validationComments['ThisDupVoterProxy']);
                    } else if (type == 'Number') {
                        invalidateComment(validationComments['ThisDupNumProxy']);
                    }
                    
                }
            });
        }

        // Validation to test if there are duplicate proxies captured for the same voter
        function verifyDuplicateVoter() {
            removeInvalidation(validationComments['ThisDupVoterProxy']);
            $http.post('/api/getDupVoterTest/' + sessionId, {
                proxy: $scope.Proxy
            }).then(function (resp) {
                if (resp.data.status) {
                    if (resp.data.proxy) { // If a voter was returned
                        proxyInvalidationPopup(resp.data.proxy, 'Appointer');
                    }
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Duplicate Appointer');
                    $scope.msg = err.data.message;
                }
            });
        }

        // Popup to select a voter or proxy holder
        $scope.selectMember = function (type) {
            var dialog = ngDialog.open({
                template: require('../tpl/select-voter.html'),
                className: 'ngdialog-theme-default custom-width-100',
                plain: true,
                controller: 'selectVoter',
                showClose: false,
                closeByDocument: false,
                resolve: {
                    'params': function () {
                        return {
                            'type': type,
                            'sessionSettings': $scope.SessionSettings,
                            'sessionId': sessionId
                        };
                    }
                }
            });
            dialog.closePromise.then(function (data) {
                if (data && data.value && data.value.voter && data.value.voter.Id != 0) { // If a voter was selected
                    removeInvalidation(validationComments['DupVoterHolder']);
                    if (type == 'Appointer') {
                        $scope.Proxy.Voter = data.value.voter;
                        removeInvalidation(validationComments['Voter']);
                        if ($scope.Proxy.Holder && $scope.Proxy.Holder.Id == $scope.Proxy.Voter.Id) { // If the same voter and proxy holder was selected
                            popups.alertPopup('The appointer cannot be the same as the proxy holder', 'Validation');
                            invalidateComment(validationComments['DupVoterHolder']);
                        }
                        if ($scope.ProxySettings.Voter_Unique) { // If more than one proxy should not be captured for a voter
                            verifyDuplicateVoter();
                        }
                    } else if (type == 'Proxy Holder') {
                        $scope.Proxy.Holder = data.value.voter;
                        removeInvalidation(validationComments['Holder']);
                        if ($scope.Proxy.Voter && $scope.Proxy.Voter.Id == $scope.Proxy.Holder.Id) { // If the same voter and proxy holder was selected
                            popups.alertPopup('The appointer cannot be the same as the proxy holder', 'Validation');
                            invalidateComment(validationComments['DupVoterHolder']);
                        }
                    }
                } else if (data && data.value && data.value.voter && data.value.voter.Id == 0) { // If a "No voter" flag was selected
                    if (type == 'Appointer') {
                        $scope.Proxy.Voter = { Id: 0 };
                        invalidateComment(validationComments['Voter']);
                    } else if (type == 'Proxy Holder') {
                        $scope.Proxy.Holder = { Id: 0 };
                        invalidateComment(validationComments['Holder']);
                    }
                }
            });
        };

        // Testing the validation on the additional fields
        $scope.testCustomValidation = function (field) {
            if (field.Validation != '' && field.Validation != null) { // If there are validations for this field
                var comment = {
                    name: field.Error || '',
                    disqualified: true
                };
                removeInvalidation(comment);
                if (field.Type == 'BOOLEAN') {
                    if ($scope.Proxy.AddFields[field.Id] != field.Validation) { // If the field is invalidated
                        invalidateComment(comment);
                    }
                } else if (field.Type == 'DATE') {
                    var validation = JSON.parse(field.Validation);
                    var inputDate = new Date($scope.Proxy.AddFields[field.Id]);
                    var minDate = new Date(validation.min);
                    var maxDate = new Date(validation.max);
                    if (inputDate < minDate || inputDate > maxDate) {
                        invalidateComment(comment);
                    }
                } else if (field.Type == 'TEXT') {
                    if (field.Validation == 1 && ($scope.Proxy.AddFields[field.Id] == null || $scope.Proxy.AddFields[field.Id] == '')) {
                        invalidateComment(comment);
                    }
                }
            }
        };

        function allComplete(callback) {
            var valid = true;
            var errors = [];
            if ($scope.Proxy.Voter == null) {
                valid = false;
                errors.push('The appointer cannot be blank');
            }
            if ($scope.Proxy.Holder == null) {
                valid = false;
                errors.push('The proxy holder cannot be blank');
            }
            if ($scope.Proxy.Batch.Batch == null || $scope.Proxy.Batch.Batch == '') {
                valid = false;
                errors.push('The batch number cannot be empty');
            }
            if ($scope.Proxy.Batch.Number == null || $scope.Proxy.Batch.Number == '') {
                valid = false;
                errors.push('The position in the batch cannot be empty');
            }
            if ($scope.ProxySettings.Show_Number && $scope.ProxySettings.Number_Unique && ($scope.Proxy.Number == null || $scope.Proxy.Number == '')) {
                valid = false;
                errors.push('The proxy number cannot be empty');
            }
            if ($scope.ProxySettings.AddFields && $scope.ProxySettings.AddFields.length > 0) {
                var count = 0;
                $scope.ProxySettings.AddFields.forEach(function (field) {
                    if (field.Validation != '' && field.Validation != null && $scope.Proxy.AddFields[field.Id] == null) { // If there are validations for this field
                        valid = false;
                        errors.push('The field "' + field.Name + '" is not completed');
                    }
                    count++;
                    if (count >= $scope.ProxySettings.AddFields.length) {
                        callback(valid, errors);
                    }
                });
            } else {
                callback(valid, errors);
            }
        }

        // To submit the proxy form for processing
        $scope.submitProxy = function (type) {
            $scope.msg = null;
            allComplete(function (valid, errors) {
                if (!valid) {
                    $scope.msg = errors;
                } else {
                    popups.confirmPopup('Are you sure you want to submit the proxy form?', 'Submit').then(function () {
                        if ($scope.ProxyType == 'Capture' || $scope.ProxyType == 'Review' || $scope.ProxyType == 'Review_2' || $scope.ProxyType == 'ManageReview') { // If this is for capturing a proxy or reviewing a form
                            $rootScope.$emit('CreateCaptureProxyReturn', {
                                Proxy: $scope.Proxy,
                                Type: type,
                                ProxyType: $scope.ProxyType,
                                reviewList: $scope.Review2List || null,
                                batch: batch || null
                            });
                            $rootScope.ProxyBatch.Batch = $scope.Proxy.Batch.Batch;
                            $rootScope.ProxyBatch.Number = $scope.Proxy.Batch.Number;
                        } else if ($scope.ProxyType == 'Edit') {
                            $rootScope.$emit('CreateEditProxyReturn', {
                                Proxy: $scope.Proxy
                            });
                        } else if ($scope.ProxyType == 'View') {
                            $rootScope.$emit('CreateViewProxyReturn', {
                                Proxy: $scope.Proxy
                            });
                        }
                        $scope.showView = null;
                    });
                }
            });
        };

        // To go back to the menu
        $scope.closeProxy = function () {
            $rootScope.$emit('CreateProxyBackReturn');
            $scope.showView = null;
        };

        // To remove the listeners from rootscope, to ensure the page is only executed once
        $scope.$on('$destroy', function() {
            deregister1();
            deregister2();
            deregister3();
            deregister4();
            deregister5();
            deregister6();
        });
    }

];




