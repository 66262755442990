// Controller to add or edit sessions

module.exports = ['$scope', '$http', 'params', function (scope, $http, params) {

    // NOTE: closeThisDialog() is injected to the scope by ngDialog

    // Create the variable or passed from the script
    if (params) {
        scope.session = params.session;
    } else {
        scope.session = {
            Name: '',
            Description: ''
        };
    }
    

    scope.isCreating = !(scope.session && scope.session.Id);
    scope.error = false;
    scope.errorMsg = scope.session;
    scope.isProcessing = false;

    // validate the session input
    function validateInput() {
        scope.error = false;
        scope.errorMsg = '';
        if (!scope.session.Name) {
            scope.error = true;
            return false;
        } else {
            return true;
        }
    }

    // Post the information to the database
    function PostSession() {
        scope.isProcessing = true;

        var postData = scope.session;
        var url = '';
        if (scope.isCreating) { // Choose if we are creating a new session, or editing the current one
            url = '/api/sessions/add';
        } else {
            url = '/api/sessions/edit';
        }

        $http.post(url, postData)
            .then(function (resp) {
                scope.closeThisDialog();
            }).catch(function (err) {
                scope.error = true;
                if (err && err.data && err.data.message) {
                    scope.errorMsg = err.data.message;
                } else {
                    scope.errorMsg = 'Unknown error occurred';
                }
            }).finally(function () {
                scope.isProcessing = false;
            });
    }

    scope.saveSession = function () {
        if (!validateInput()) // Validate the input fields
            return;

        if (scope.isProcessing) // If a previous submit is in progress
            return;

        PostSession();
    };
}];