// Controller for the client approvals page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'ngDialog', 'popups', '$stateParams', 'appState', '$interval', '$timeout', function ($scope, $rootScope, $http, $state, ngDialog, popups, $stateParams, appState, $interval) {
    
    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Initiate the variables
    $scope.msg = '';
    $scope.sessionQueries = [];
    $scope.query = null;
    $scope.submitting = false;

    $scope.origins = {
        AccCreation: 'External Account Creation Page',
        AccManagement: 'External Account Management Page',
        votersRoll: 'Internal Votersroll Page',
        clientApproval: 'Client Approval Page'
    };

    $scope.searchCriteria = {
        Name: '',
        Surname: '',
        Email: '',
        Numb: '',
        Numb_2: ''
    };
    $scope.fullRoll = null;
    $scope.filterVoters = [];
    $scope.gettingData = false;


    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // Get the queries from the database
    function getQueries() {
        $scope.msg = '';
        $http.get('/api/getClientQueries/' + sessionId, null).then(function (resp) {
            $scope.sessionQueries = resp.data;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    $scope.Manage = function (showquery) {
        $scope.msg = '';
        $http.get('/api/getSupportQuery/' + sessionId + '/' + showquery.Id, null).then(function (resp) {
            $scope.query = resp.data.query;
            $scope.query.AuditLog.forEach(function (log) {
                var logdate = log.date;
                log.date = new Date(logdate).toLocaleString();
            });
            if ($scope.query.WorkNotes) {
                $scope.query.WorkNotes.forEach(function (note) {
                    var notedate = note.date;
                    note.date = new Date(notedate).toLocaleString();
                });
            }
            if (!!$scope.query.Replacement && $scope.query.Replacement !== '') {
                $http.get('/api/getReplacementVoter/' + sessionId + '/' + $scope.query.Replacement, {}).then(function (resp) {
                    if (resp && resp.data) {
                        $scope.replaceVoter = resp.data;
                    } else {
                        popups.alertPopup('An unknown error has occurred. Please try again later.', 'Error');
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        $scope.msg = err.data.message;
                    } else {
                        popups.alertPopup('An unknown error has occurred. Please try again later.', 'Error');
                    }
                });
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            } else {
                popups.alertPopup('An unknown error has occurred. Please try again later.', 'Error');
            }
        });
    };

    $scope.acceptChange = function () {
        popups.confirmPopup("Please ensure that you have verified the origin of the change. You are responsible for ensuring the accuracy of the changes made to the voters' roll", 'Approval').then(function () {
            $scope.submitting = true;
            $http.post('/api/acceptApproval/' + sessionId, {
                query: $scope.query
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    popups.alertPopup('The change has been approved', 'Approvals');
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later.', 'Error');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later.', 'Error');
                }
            }).finally(function () {
                getQueries();
                $scope.query = null;
                $scope.submitting = false;
            });
        });
    };

    $scope.rejectChange = function () {
        popups.confirmPopup('Are you sure you want to reject this change to the votersroll?', 'Approval').then(function () {
            $scope.submitting = true;
            $http.post('/api/rejectApproval/' + sessionId, {
                query: $scope.query
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    popups.alertPopup('The change has been rejected', 'Approvals');
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later.', 'Error');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later.', 'Error');
                }
            }).finally(function () {
                getQueries();
                $scope.query = null;
                $scope.submitting = false;
            });
        });
    };

    $scope.CancelApproval = function () {
        $scope.query = null;
    };

    // To filter the roll based on the search criteria
    function filterRoll() {
        $scope.filterVoters = [];
        if ($scope.fullRoll.length > 0) {
            $scope.fullRoll.forEach(function (voter) {
                if (voter.Name.toUpperCase().includes($scope.searchCriteria.Name.toUpperCase()) && voter.Surname.toUpperCase().includes($scope.searchCriteria.Surname.toUpperCase()) && voter.Email.toUpperCase().includes($scope.searchCriteria.Email.toUpperCase()) && (voter.Numb == null || voter.Numb.toUpperCase().includes($scope.searchCriteria.Numb.toUpperCase())) && (voter.Numb_2 == null || voter.Numb_2.toUpperCase().includes($scope.searchCriteria.Numb_2.toUpperCase()))) {
                    $scope.filterVoters.push(voter);
                }
            });
        }
    }

    // To search the voters roll interactively
    $scope.searchRoll = function () {
        var searchLength = $scope.searchCriteria.Name.length + $scope.searchCriteria.Surname.length + $scope.searchCriteria.Email.length + $scope.searchCriteria.Numb.length + $scope.searchCriteria.Numb_2.length;
        if (searchLength < 5 && $scope.fullRoll) { // If the voters roll has been downloaded, but the search criteria changed below five, remove the roll to start over
            $scope.fullRoll = null;
            $scope.filterVoters = [];
        } else if (searchLength >= 5 && !$scope.fullRoll && !$scope.gettingData) { // If the search criteria is above 5 characters, there is no current roll downloaded and the roll is not in the process of being downloaded, download the roll
            $scope.gettingData = true;
            $http.post('/api/getSearchRoll/' + sessionId, {
                Critera: $scope.searchCriteria
            }).then(function (resp) {
                $scope.fullRoll = resp.data;
                filterRoll();
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.gettingData = false;
            });
        } else if (searchLength >= 5 && !!$scope.fullRoll) { // If the search criteria is above 5 characters, but there is a roll, just filter the current roll.
            filterRoll();
        }
    };

    // Get the date in the correct format or return an empty string if the date is blank
    $scope.getDate = function (newDate) {
        if (newDate) {
            var returnDate = new Date(newDate).toLocaleString('en-ZA');
            return returnDate;
        } else {
            return '';
        }
    };

    $scope.clearCrit = function () {
        $scope.searchCriteria = {
            Name: '',
            Surname: '',
            Email: '',
            Numb: '',
            Numb_2: ''
        };
        $scope.fullRoll = null;
        $scope.filterVoters = [];
    };

    // Submit a query for this voter
    $scope.addQuery = function (voter) {
        var dialog = ngDialog.open({
            template: require('../tpl/log-query.html'),
            plain: true,
            controller: 'queryInternal',
            showClose: false,
            closeByDocument: false,
            resolve: {
                'params': function () {
                    return {
                        'voter': voter,
                        'origin': 'clientApproval',
                        'session': sessionId
                    };
                }
            }
        });
        dialog.closePromise.then(function (data) {
            if (data.value) { // If the query was raised, indicate this and return to the login screen
                popups.alertPopup('The query was raised for further action.', 'Support Query');
            } else { // If not, make a note that the query was not logged
                popups.alertPopup('The query was not raised', 'Support Query');
            }
        });
    };

    $scope.logNewQuery = function () {
        var dialog = ngDialog.open({
            template: require('../tpl/log-new-query.html'),
            plain: true,
            controller: 'queryNewRoll',
            showClose: false,
            closeByDocument: false,
            resolve: {
                'params': function () {
                    return {
                        'origin': 'clientApproval',
                        'session': sessionId,
                        'show_number': $scope.SessionSettings.Show_Number,
                        'number_name': $scope.SessionSettings.Number_Name,
                        'show_number_2': $scope.SessionSettings.Show_Number_2,
                        'number_name_2': $scope.SessionSettings.Number_Name_2
                    };
                }
            }
        });
        dialog.closePromise.then(function (data) {
            if (data.value) { // If the query was raised, indicate this and return to the login screen
                popups.alertPopup('The query was raised for further action.', 'Support Query');
            } else { // If not, make a note that the query was not logged
                popups.alertPopup('The query was not raised', 'Support Query');
            }
        });
    };





    // Set up a recurring function to get the data and update the view
    function startUpdates() {
        var intervalseconds = 30;
        $scope.getDataInterval = null;
        $scope.getDataInterval = $interval(function () {
            getQueries();
        }, 1000 * intervalseconds);
    }

    function stopUpdates() {
        if ($scope.getDataInterval) {
            $interval.cancel($scope.getDataInterval);
        }
    }

    // To stop the $interval function on destroying the element
    $scope.$on('$destroy', function () {
        stopUpdates();
    });

    // Load the data after script creation
    getSessDetails();
    getQueries();
    startUpdates();
     
}];
