var angular = require('angular');

angular.module('Elections')
    .filter('interpolateColor', function () {
        return function (color1, color2, factor) {
            if (arguments.length < 3) {
                factor = 0.5;
            }
            var result = color1.slice();
            for (var i = 0; i < 3; i++) {
                result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
            }
            return result;
        };
    })
    .filter('hexToRgb', function () {
        return function (hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? 'rgb(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ')' : null;
        };
    })
    .filter('interpolateColors', ['$filter', function ($filter) {
        return function (color1, color2, steps, format) {
            var stepFactor = 1 / (steps - 1),
                interpolatedColorArray = [];
            color1 = color1.match(/\d+/g).map(Number);
            color2 = color2.match(/\d+/g).map(Number);
            for (var i = 0; i < steps; i++) {
                var rgbColorArray = $filter('interpolateColor')(color1, color2, stepFactor * i);
                var rgb;
                if (format === 'hex') {
                    rgbColorArray[0] = Number(rgbColorArray[0]).toString(16);
                    rgbColorArray[0] = (rgbColorArray[0].length < 2 ? '0' : '') + rgbColorArray[0];
                    rgbColorArray[1] = Number(rgbColorArray[1]).toString(16);
                    rgbColorArray[1] = (rgbColorArray[1].length < 2 ? '0' : '') + rgbColorArray[1];
                    rgbColorArray[2] = Number(rgbColorArray[2]).toString(16);
                    rgbColorArray[2] = (rgbColorArray[2].length < 2 ? '0' : '') + rgbColorArray[2];
                    rgb = '#' + rgbColorArray[0] + rgbColorArray[1] + rgbColorArray[2];
                } else {
                    rgb = 'rgba(' + rgbColorArray[0] + ',' + rgbColorArray[1] + ',' + rgbColorArray[2] + ',1)';
                }
                interpolatedColorArray.push(rgb);
            }
            return interpolatedColorArray;
        };
    }]);