// Controller for the sessions page
module.exports = ['$rootScope', '$scope', '$http', '$state', 'ngDialog', 'appState', 'popups', function ($rootScope, $scope, $http, $state, ngDialog, appState, popups) {

    // Scope variables
    $scope.Stations = [];
    $scope.isUserAdmin = appState.isAdmin;
    var userAccess = appState.access;
    $scope.voterdata = appState.voterdata;
    $scope.nomdata = appState.nomdata;
    $rootScope.CurrentSession = null;
    $rootScope.CurrentSessionImage = null;

    // events hook up
    var cleanup1 = $scope.$on('el.sessions.$changed', function (evt) {
        reloadpage();
    });

    $scope.$on('$destroy', function (evt) {
        cleanup1();
    });

    // Get the list of sessions from the database
    $scope.getSessions = function () {
        $http.get('/api/sessions').then(function (value) {
            $scope.Stations = value.data;
        }).catch(function (err) {
            if (err.data && err.data.message)
                alert(err.data.message);
        });
    };

    // Determines whether a person has access to a specific function.
    // This is called various times during loading on the client computer.
    $scope.hasAccess = function (station, type) {
        if ($scope.isUserAdmin) {
            return true;
        } else {
            var found = false;
            userAccess.forEach(function (access) {
                if (access.Session === station.Id && access[type] === 1) {
                    found = true;
                }
            });
            return found;
        }
    };

    // Create dialog box to add or edit a session
    $scope.createStationDialog = function (station) {
        ngDialog.open({
            template: require('../tpl/session-edit-dlg.html'),
            plain: true,
            controller: 'sessionEdit',
            showClose: false,
            closeByDocument: false,
            resolve: {
                'params': function () {
                    if (station) {
                        return {
                            'session': station
                        };
                    } else {
                        return null;
                    }
                }
            },
            preCloseCallback: function () {
                $rootScope.$broadcast('el.sessions.$changed');
                $scope.getSessions();
            }
        });
    };

    $scope.voteSession = function (session) {
        $state.go('el.voting', {
            sessionId: session.session.Id,
            type: 'live'
        });
    };

    $scope.nominateSession = function (session) {
        $state.go('el.nomination', {
            sessionId: session.session.Id
        });
    };

    $scope.deleteSession = function (session) {
        popups.confirmPopup('Are you sure you want to delete this session?', session.Name).then(function () {
            $http.post('/api/sessions/delete/' + session.Id).then(function (resp) {
                $rootScope.$broadcast('el.sessions.$changed');
                $scope.getSessions();
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, session.Name);
                } else {
                    popups.alertPopup('Unknown error received. Please contact your administrator', session.Name);
                }
            });
        });
    };

    // To keep the loading scripts together
    function reloadpage() {
        $scope.getSessions();
    }

    // Initialise
    reloadpage();
}];

