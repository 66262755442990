// Controller for the nomination screen

module.exports = ['$scope', '$http', '$state', '$rootScope', '$stateParams', 'appState', 'popups', 'ngDialog', function ($scope, $http, $state, $rootScope, $stateParams, appState, popups, ngDialog) {
    var sessionId = $stateParams.sessionId;

    if (!sessionId || isNaN(sessionId) || !appState.nomdata) { // If there is no session ID or there is no voterdata on a live session
        $state.go('el.sessions');
        return;
    }

    // Set up the opening variables
    $scope.msg = null;
    $scope.showView = 'Menu';

    function setSession() {
        var nomdata = appState.nomdata;
        nomdata.forEach(function (nomination) {
            if (nomination.session.Id == sessionId) {
                $scope.nomsession = nomination;
            }
        });
    }

    function getNomSettings() {
        $scope.msg = null;
        $http.get('/api/getNomSettings/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.settings.session;
            $scope.NomSettings = resp.data.settings.nomSettings;
            $scope.NomQuestions = resp.data.settings.nomQuestions;
            $scope.NomQuestions.forEach(function (question) {
                if (question.Type == 'DROPDOWN') {
                    question.Value = JSON.parse(question.Value);
                    question.Value.push('Other');
                }
            });
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.message) {
                $scope.msg = err.message;
            }
        });
    }

    $scope.newNomination = function () {
        $scope.showView = 'New';
        $scope.nomination = {
            nomValidation: false
        };
    };

    $scope.validateNominee = function () {
        if (!$scope.nomination.Voter_Numb || $scope.nomination.Voter_Numb == '') {
            popups.alertPopup("The voter's " + $scope.SessionSettings.Number_Name + " cannot be empty.", 'Error');
        } else {
            $scope.msg = null;
            $http.post('/api/validateField/' + sessionId, {
                voter: $scope.nomination.Voter_Numb
            }).then(function (resp) {
                if (resp.data.result) {
                    popups.alertPopup("The nominee's " + $scope.SessionSettings.Number_Name + " validated correctly. Please ensure that you enter the correct name and surname for the nominee.", 'Validation');
                    $scope.nomination.nomValidation = true;
                    $scope.nomination.Voter = resp.data.result;
                } else {
                    popups.alertPopup("The the nominee's " + $scope.SessionSettings.Number_Name + " did not validate correctly. Please try again.", 'Validation');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                } else {
                    popups.alertPopup('An unknown error has occurred. Please contact the administrator', 'Error');
                }
            });
        }
    };

    function secondeeDuplicate(index, callback) {
        var accept = true;
        if ($scope.nomination.Seconder[index] == $scope.nomination.Voter_Numb) {
            popups.alertPopup('You cannot have the nominee as a secondee', 'Error');
            accept = false;
        } else {
            for (var i = 1; i <= $scope.NomSettings.Sec_Required; i++) {
                if (i !== index && $scope.nomination.Seconder[i] == $scope.nomination.Seconder[index]) {
                    popups.alertPopup('You cannot have duplicate seconders', 'Error');
                    accept = false;
                }
            }
        }
        callback(accept);
    }

    $scope.validateSeconder = function (index) {
        if (!$scope.nomination.Seconder[index] || $scope.nomination.Seconder[index] == '') {
            popups.alertPopup("The seconder's " + $scope.SessionSettings.Number_Name + " cannot be empty.", 'Error');
        } else {
            secondeeDuplicate(index, function (resp) {
                if (resp) {
                    $scope.msg = null;
                    $http.post('/api/validateField/' + sessionId, {
                        voter: $scope.nomination.Seconder[index]
                    }).then(function (resp) {
                        if (resp.data.result) {
                            popups.alertPopup("The seconder's " + $scope.SessionSettings.Number_Name + " validated correctly. Please ensure that you enter the correct name and surname.", 'Validation');
                            $scope.nomination.Sec_Verified[index] = true;
                        } else {
                            popups.alertPopup("The the seconder's " + $scope.SessionSettings.Number_Name + " did not validate correctly. Please try again.", 'Validation');
                        }
                    }).catch(function (err) {
                        if (err && err.data && err.data.message) {
                            popups.alertPopup(err.data.message, 'Error');
                        } else {
                            popups.alertPopup('An unknown error has occurred. Please contact the administrator', 'Error');
                        }
                    });
                }
            });
        }
    };

    // Test that all of the mandatory fields are completed
    function testMandatory(callback) {
        var complete = true;
        if (!$scope.nomination.nomValidation) {
            complete = false;
            $scope.msg = 'Please validate the nominee';
        } else if (!$scope.nomination.Name || $scope.nomination.Name == '') {
            complete = false;
            $scope.msg = 'Please complete the name of the nominee';
        } else if (!$scope.nomination.Surname || $scope.nomination.Surname == '') {
            complete = false;
            $scope.msg = 'Please complete the surname of the nominee';
        } else if (!$scope.nomination.Contact || $scope.nomination.Contact == '') {
            complete = false;
            $scope.msg = 'Please complete the contact number of the nominee';
        } else {
            $scope.NomQuestions.forEach(function (question) {
                if (question.Mandatory == 1 && (!$scope.nomination.Questions || !$scope.nomination.Questions[question.Id] || $scope.nomination.Questions[question.Id] == '')) {
                    complete = false;
                    $scope.msg = 'Please complete all of the mandatory questions';
                }
                if (question.Type == 'DROPDOWN' && $scope.nomination.Questions[question.Id] == 'Other' && (!$scope.nomination.Other || !$scope.nomination.Other[question.Id] || $scope.nomination.Other[question.Id] == '')) {
                    complete = false;
                    $scope.msg = 'Please complete the option for the "Other" selected';
                }
            });
            if (!$scope.nomination.Seconder_Name || !$scope.nomination.Seconder_Surname || !$scope.nomination.Seconder || !$scope.nomination.Sec_Verified) {
                complete = false;
                $scope.msg = 'Please complete the seconders';
            } else {
                for (var i = 1; i <= $scope.NomSettings.Sec_Required; i++) {
                    if (!$scope.nomination.Seconder_Name[i] || $scope.nomination.Seconder_Name[i] == '') {
                        complete = false;
                        $scope.msg = 'Please complete the name of seconder ' + i;
                    } else if (!$scope.nomination.Seconder_Surname[i] || $scope.nomination.Seconder_Surname[i] == '') {
                        complete = false;
                        $scope.msg = 'Please complete the surname of seconder ' + i;
                    } else if (!$scope.nomination.Seconder[i] || $scope.nomination.Seconder[i] == '') {
                        complete = false;
                        $scope.msg = 'Please complete the name of seconder ' + i;
                    } else if (!$scope.nomination.Sec_Verified[i]) {
                        complete = false;
                        $scope.msg = 'Please verify seconder ' + i;
                    }
                }
            }
        }
        callback(complete);
    }


    // Submit the nomination form
    $scope.submitNomination = function () {
        $scope.msg = null;
        $scope.submitting = true;
        testMandatory(function (complete) {
            if (complete) { // If all of the fields are completed
                popups.alertPopup('All the fields are complete');
                $http.post('/api/submitNomination/' + sessionId, {
                    nomination: $scope.nomination,
                    questions: $scope.NomQuestions
                }).then(function (resp) {
                    if (resp && resp.data && resp.data.message) {
                        popups.alertPopup(resp.data.message, 'Nomination');
                        // TODO Activate the session reroute after completion
                        //$state.go('el.sessions');
                        //return;
                    }
                    $scope.submitting = false;
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        popups.alertPopup(err.data.message, 'Nomination');
                        $scope.msg = err.data.message;
                    } else {
                        popups.alertPopup('An unknown error has occurred. Please contact the administrator', 'Error');
                    }
                    $scope.submitting = false;
                });
            } else { // If all of the fields are not complete
                popups.alertPopup('All of the mandatory fields are not complete', 'Mandatory Fields');
                $scope.submitting = false;
            }
        });
    };


    // Run the get scripts to setup the page
    setSession();
    getNomSettings();

}];


