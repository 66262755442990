// Controller for the results pages

module.exports = ['$scope','$rootScope', '$timeout', '$window', function ($scope, $rootScope, $timeout, $window) {

    $scope.msg = null;
    $scope.voterRecord = null;

    $window.addEventListener('storage', function (event) {
        if (localStorage.Voter) {
            $scope.voterRecord = JSON.parse(localStorage.Voter);
        } else {
            $scope.voterRecord = null;
        }
        $scope.$apply();
        updateStation();
    }, false);

    function updateStation () {
        if ($scope.voterRecord.Station) {
            $timeout(function () {
                $scope.voterRecord.Station = null;
            }, (1000 * 10)); // Remove the Station details on the display screen after 10 seconds
        }
    }

    $rootScope.cancelIdleTimer();
}];

