// Controller for the voters roll administration page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'ngDialog', 'popups', '$stateParams', 'appState', function ($scope, $rootScope, $http, $state, ngDialog, popups, $stateParams, appState) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Initiate the variables
    $scope.msg = '';
    $scope.searchCriteria = {
        Name: '',
        Surname: '',
        Email: '',
        Numb: '',
        Numb_2: ''
    };
    $scope.fullRoll = null;
    $scope.filterVoters = [];
    $scope.gettingData = false;

    // Get the session settings from the database
    $scope.getSessDetails = function () {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    };

    // To filter the roll based on the search criteria
    function filterRoll() {
        $scope.filterVoters = [];
        if ($scope.fullRoll.length > 0) {
            $scope.fullRoll.forEach(function (voter) {
                if (voter.Name.toUpperCase().includes($scope.searchCriteria.Name.toUpperCase()) && voter.Surname.toUpperCase().includes($scope.searchCriteria.Surname.toUpperCase()) && voter.Email.toUpperCase().includes($scope.searchCriteria.Email.toUpperCase()) && (voter.Numb == null || voter.Numb.toUpperCase().includes($scope.searchCriteria.Numb.toUpperCase())) && (voter.Numb_2 == null || voter.Numb_2.toUpperCase().includes($scope.searchCriteria.Numb_2.toUpperCase()))) {
                    $scope.filterVoters.push(voter);
                }
            });
        }
    }

    // To search the voters roll interactively
    $scope.searchRoll = function () {
        var searchLength = $scope.searchCriteria.Name.length + $scope.searchCriteria.Surname.length + $scope.searchCriteria.Email.length + $scope.searchCriteria.Numb.length + $scope.searchCriteria.Numb_2.length;
        if (searchLength < 5 && $scope.fullRoll) { // If the voters roll has been downloaded, but the search criteria changed below five, remove the roll to start over
            $scope.fullRoll = null;
            $scope.filterVoters = [];
        } else if (searchLength >= 5 && !$scope.fullRoll && !$scope.gettingData) { // If the search criteria is above 5 characters, there is no current roll downloaded and the roll is not in the process of being downloaded, download the roll
            $scope.gettingData = true;
            $http.post('/api/getSearchRoll/' + sessionId, {
                Critera: $scope.searchCriteria
            }).then(function (resp) {
                $scope.fullRoll = resp.data;
                filterRoll();
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.gettingData = false;
            });
        } else if (searchLength >= 5 && !!$scope.fullRoll) { // If the search criteria is above 5 characters, but there is a roll, just filter the current roll.
            filterRoll();
        }
    };

    // Test if the user has a non-pwc mail address.
    $scope.isExternal = function (user) {
        var indexchar = user.Email.indexOf('pwc.com');
        if (indexchar === -1) {
            return true;
        } else {
            return false;
        }
    };

    // Get the date in the correct format or return an empty string if the date is blank
    $scope.getDate = function (newDate) {
        if (newDate) {
            var returnDate = new Date(newDate).toLocaleString('en-ZA');
            return returnDate;
        } else {
            return '';
        }
    };

    $scope.clearCrit = function () {
        $scope.searchCriteria = {
            Name: '',
            Surname: '',
            Email: '',
            Numb: '',
            Numb_2: ''
        };
        $scope.fullRoll = null;
        $scope.filterVoters = [];
    };

    // To syncronise the votersroll with Identity
    $scope.syncRoll = function () {
        popups.confirmPopup('Are you sure you want to start the syncronisation process? It will take between 3 and 15 seconds per user to sync.', 'Sync').then(function () {
            $http.post('/api/syncVotersroll/' + sessionId, {}).then(function (resp) {
                if (resp.data && resp.data.status) {
                    popups.alertPopup("The syncronisation with Identity has started", "GUM Sync");
                } else {
                    popups.alertPopup('The syncronisation did not start. Please try again later', 'GUM Sync');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                } else {
                    popups.alertPopup('An unknown error has occurred. Please contact the administrator', 'Error');
                }
            });
        });
    };

    // Submit a query for this voter
    $scope.addQuery = function (voter) {
        var dialog = ngDialog.open({
            template: require('../tpl/log-query.html'),
            plain: true,
            controller: 'queryInternal',
            showClose: false,
            closeByDocument: false,
            resolve: {
                'params': function () {
                    return {
                        'voter': voter,
                        'origin': 'votersRoll',
                        'session': sessionId
                    };
                }
            }
        });
        dialog.closePromise.then(function (data) {
            if (data.value) { // If the query was raised, indicate this and return to the login screen
                popups.alertPopup('The query was raised for further action.', 'Support Query');
            } else { // If not, make a note that the query was not logged
                popups.alertPopup('The query was not raised', 'Support Query');
            }
        });
    };

    $scope.logNewQuery = function () {
        var dialog = ngDialog.open({
            template: require('../tpl/log-new-query.html'),
            plain: true,
            controller: 'queryNewRoll',
            showClose: false,
            closeByDocument: false,
            resolve: {
                'params': function () {
                    return {
                        'origin': 'votersRoll',
                        'session': sessionId,
                        'show_number': $scope.SessionSettings.Show_Number,
                        'number_name': $scope.SessionSettings.Number_Name,
                        'show_number_2': $scope.SessionSettings.Show_Number_2,
                        'number_name_2': $scope.SessionSettings.Number_Name_2,
                    };
                }
            }
        });
        dialog.closePromise.then(function (data) {
            if (data.value) { // If the query was raised, indicate this and return to the login screen
                popups.alertPopup('The query was raised for further action.', 'Support Query');
            } else { // If not, make a note that the query was not logged
                popups.alertPopup('The query was not raised', 'Support Query');
            }
        });
    };

    $scope.exportRoll = function () {
        window.open(apiUrl + '/api/votersroll/' + sessionId + '/exportxls?token=' + appState.token);
    };

    // Load the data after script creation
    $scope.getSessDetails();

}];

