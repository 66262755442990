// Controller for the managed voting management screen

module.exports = ['$scope', '$rootScope', '$http', '$state', '$window', '$interval', '$timeout', 'ngDialog', 'popups', '$stateParams', 'appState', function ($scope, $rootScope, $http, $state, $window, $interval, $timeout, ngDialog, popups, $stateParams, appState) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Initiate the variables
    $scope.msg = '';
    $scope.gettingUpdate = false;
    $scope.getRejected = false;
    $scope.changeStatus = false;

    $scope.showDevices = false;
    $scope.showLog = {};



    // Get the session settings from the database
    $scope.getSessDetails = function () {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    };

    $scope.updateList = function () {
        if (!$scope.gettingUpdate) {
            $scope.gettingUpdate = true;
            $http.get('/api/manageVoting/getLists/' + sessionId, null).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    $scope.devices = resp.data.devices;
                    $scope.devices.forEach(function (dev) {
                        //dev.showLog = false;
                        dev.AuditLog = JSON.parse(dev.AuditLog);
                        dev.AuditLog.forEach(function (log) {
                            var logdate = log.date;
                            log.date = new Date(logdate).toLocaleString();
                        });
                    });
                    $scope.requests = resp.data.requests;
                    $scope.activeRequests = [];
                    $scope.pendingRequests = [];
                    $scope.requests.forEach(function (req) {
                        req.AuditLog = JSON.parse(req.AuditLog);
                        req.AuditLog.forEach(function (log) {
                            var logdate = log.date;
                            log.date = new Date(logdate).toLocaleString();
                        });
                        if (req.Status == 'Allocated' || req.Status == 'Started') {
                            $scope.activeRequests.push(req);
                        } else {
                            $scope.pendingRequests.push(req);
                        }
                    });
                } else {
                    popups.alertPopup("An unknown error has occurred. Please contact the administrator");
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.gettingUpdate = false;
            });
        }
    };

    // Get the number of active devices on the list
    $scope.getActiveDev = function () {
        var active = 0;
        $scope.devices.forEach(function (dev) {
            if (dev.Status == 'Active') {
                active++;
            }
        });
        return active;
    };

    $scope.drawRejected = function () {
        if (!$scope.getRejected) {
            $scope.getRejected = true;
            $http.get('/api/manageVoting/getRejected/' + sessionId, null).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    $scope.rejectedRequests = resp.data.rejected;
                    $scope.rejectedRequests.forEach(function (req) {
                        req.AuditLog = JSON.parse(req.AuditLog);
                        req.AuditLog.forEach(function (log) {
                            var logdate = log.date;
                            log.date = new Date(logdate).toLocaleString();
                        });
                    });
                } else {
                    popups.alertPopup("An unknown error has occurred. Please contact the administrator");
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.getRejected = false;
            });
        }
    };

    // Add a new device name
    $scope.addNewDevice = function () {
        var dialog = ngDialog.open({
            template: require('../tpl/new-device.html'),
            plain: true,
            controller: 'newDevice',
            showClose: false,
            closeByDocument: false,
            resolve: {
                'params': function () {
                    return {
                        'session': sessionId
                    };
                }
            }
        });
        dialog.closePromise.then(function (data) {
            if (data.value) { // If the device was added, indicate this and update
                popups.alertPopup('The new device was added', 'New Device');
                $scope.updateList();
            } else { // If not, notify the user
                popups.alertPopup('The new device was not added', 'New Device');
            }
        });
    };

    $scope.changeDeviceStatus = function (device, status) {
        popups.confirmPopup('Are you sure you want to ' + status + ' the device?', 'Device Status').then(function () {
            if (!$scope.changeStatus) {
                $scope.changeStatus = true;
                $http.post('/api/manageVoting/changeStatusDevice/' + sessionId, {
                    device: device,
                    status: status
                }).then(function (resp) {
                    if (resp && resp.data && resp.data.status) {
                        $scope.updateList();
                    } else {
                        popups.alertPopup('An unknown error has occurred. Please contact the administrator');
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        $scope.msg = err.data.message;
                    }
                }).finally(function () {
                    $scope.changeStatus = false;
                });
            }
        });
    };

    // To cancel a voting request
    $scope.cancelRequest = function (request) {
        popups.confirmPopup('Are you sure you want to cancel this request?', 'Cancel Request').then(function () {
            $scope.changeStatus = true;
            $http.post('/api/manageVoting/changeStatusRequest/' + sessionId, {
                request: request,
                status: 'deactivate'
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    $scope.updateList();
                } else {
                    popups.alertPopup('An unknown error has occurred. Please contact the administrator');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.changeStatus = false;
            });
        });
    };


    // Set up a recurring function to update the view
    function startUpdates() {
        var intervalseconds = 10; // Run the function every 10 seconds
        $scope.getDataInterval = null;
        $scope.getDataInterval = $interval(function () {
            $scope.updateList();
        }, 1000 * intervalseconds); // Start the recurring update
    }

    // Stop the automatic updates
    function stopUpdates() {
        if ($scope.getDataInterval) {
            $interval.cancel($scope.getDataInterval);
        }
    }

    // On exiting the page
    $scope.$on('$destroy', function () {
        stopUpdates();
    });


    // Load the data after script creation
    $scope.getSessDetails();
    $scope.updateList();
    startUpdates();

}];