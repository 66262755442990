var angular = require('angular');

angular.module('Elections')
    .controller('login', require('./login'))
    .controller('logging_in', require('./logging-in'))
    .controller('altLogin', require('./alternative-login'))
    .controller('navbar', require('./navbar'))
    .controller('sessions', require('./sessions'))
    .controller('sessionEdit', require('./session-edit-dlg'))
    .controller('changePassword', require('./password-change-dlg'))
    .controller('users-mgmt', require('./users-mgmt'))
    .controller('alt-login-mgmt', require('./alt-login-mgmt'))
    .controller('admin-mgmt', require('./admin-mgmt'))
    .controller('userEdit', require('./edit-user-dlg'))
    .controller('editAccess', require('./edit-access-dlg'))
    .controller('activeVoting', require('./voting'))
    .controller('ballot', require('./ballot'))
    .controller('activeNomination', require('./nomination'))
    .controller('sessionAdmin', require('./session-admin'))
    .controller('nominationAdmin', require('./nomination-admin'))
    .controller('votersrollAdmin', require('./votersrollAdmin'))
    .controller('votingStats', require('./voting_stats'))
    .controller('voterEdit', require('./voter-edit-dlg'))
    .controller('results', require('./results'))
    .controller('anonResults', require('./anonResults'))
    .controller('integrityTest', require('./votingIntegrity'))
    .controller('editBio', require('./editBio'))
    .controller('manageAcc', require('./account-management'))
    .controller('createAcc', require('./account-creation'))
    .controller('errorChoose', require('./error-choose'))
    .controller('queryExternal', require('./queryExternal'))
    .controller('queryInternal', require('./queryInternal'))
    .controller('queryNewRoll', require('./queryNewRoll'))
    .controller('support', require('./support'))
    .controller('supportQuery', require('./support-query'))
    .controller('clientApprovals', require('./client-approvals'))
    .controller('voteCapturing', require('./vote-capturing'))
    .controller('voteReview', require('./vote-review'))
    .controller('voteFinalisation', require('./vote-finalisation'))
    .controller('voteLookup', require('./vote-lookup'))
    .controller('proxyCapturing', require('./proxy-capturing'))
    .controller('proxy', require('./proxy'))
    .controller('selectVoter', require('./select_voter'))
    .controller('selectProxy', require('./select_proxy'))
    .controller('proxySettings', require('./proxy-settings'))
    .controller('proxyImport', require('./proxy-import'))
    .controller('proxyManagement', require('./proxy-management'))
    .controller('proxyViews', require('./proxy-views'))
    .controller('meetingRegistration', require('./meeting-registration'))
    .controller('printCertificate', require('./print-certificate'))
    .controller('addNonMember', require('./add-non-member'))
    .controller('meetingManagement', require('./meeting-management'))
    .controller('sqlInterface', require('./sql-interface'))
    .controller('controlDashboard', require('./controlDash'))
    .controller('manageVotingAgent', require('./managed-voting-agent'))
    .controller('manageVotingDisplay', require('./manage-voting-display'))
    .controller('manageVotingClient', require('./manage-voting-client'))
    .controller('manageVotingManage', require('./managed-voting-manage'))
    .controller('newDevice', require('./new-device'));