// Controller for the votersroll support page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'ngDialog', 'popups', '$stateParams', 'appState', function ($scope, $rootScope, $http, $state, ngDialog, popups, $stateParams, appState) {
    
    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    $scope.queryId = $stateParams.queryId;
    if (!$scope.queryId || isNaN($scope.queryId)) {
        $state.go('el.voterSupport', {
            sessionId: sessionId
        }, {
                reload: true
            }
        );
        return;
    }

    // Initiate the variables
    $scope.msg = '';
    $scope.queryType = 'View';
    $scope.emailType = null;
    $scope.resetSubmit = false;
    $scope.showDetails = null;
    $scope.comment = null;
    $scope.statusList = ['In Progress', 'On Hold', 'Resolved'];
    $scope.status = null;
    $scope.changeDetails = null;
    $scope.searchCriteria = {
        Name: '',
        Surname: '',
        Email: '',
        Numb: '',
        Numb_2: ''
    };
    $scope.fullRoll = null;
    $scope.filterVoters = [];
    $scope.gettingData = false;

    $scope.replaceVoter = null;

    $scope.countries = [
        {
            Country: 'South Africa',
            Code: '+27',
            Territory: 'ZA'
        }, {
            Country: 'Lesotho',
            Code: '+266',
            Territory: 'LS'
        }, {
            Country: 'Botswana',
            Code: '+267',
            Territory: 'BW'
        }, {
            Country: 'Namibia',
            Code: '+264',
            Territory: 'NA'
        }, {
            Country: 'Zimbabwe',
            Code: '+263',
            Territory: 'ZW'
        }, {
            Country: 'Mozambique',
            Code: '+258',
            Territory: 'MZ'
        }, {
            Country: 'Swaziland',
            Code: '+268',
            Territory: 'SZ'
        }, {
            Country: 'Malawi',
            Code: '+265',
            Territory: 'MW'
        }
    ];

    $scope.origins = {
        AccCreation: 'External Account Creation Page',
        AccManagement: 'External Account Management Page',
        votersRoll: 'Internal Votersroll Page',
        clientApproval: 'Client Approval Page'
    };


    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // Get the query details from the database
    function getQuery() {
        $scope.msg = '';
        $http.get('/api/getSupportQuery/' + sessionId + '/' + $scope.queryId, null).then(function (resp) {
            $scope.queryType = resp.data.type;
            if ($scope.queryType === 'View') {
                popups.alertPopup('You only have view access to this query');
            }
            $scope.query = resp.data.query;
            $scope.query.AuditLog.forEach(function (log) {
                var logdate = log.date;
                log.date = new Date(logdate).toLocaleString();
            });
            if ($scope.query.WorkNotes) {
                $scope.query.WorkNotes.forEach(function (note) {
                    var notedate = note.date;
                    note.date = new Date(notedate).toLocaleString();
                });
            }
            if ($scope.query.Email === null || $scope.query.Email === '') {
                $scope.emailType = "None";
            } else if ($scope.query.Email.includes('pwc.com')) {
                $scope.emailType = "Internal";
            } else {
                $scope.emailType = "External";
                $scope.gumDetails = null;
                $http.post('/api/getGumUser/' + sessionId, {
                    email: $scope.query.Email
                }).then(function (resp) {
                    if (resp.data) {
                        $scope.gumDetails = resp.data;
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        $scope.msg = err.data.message;
                        popups.alertPopup(err, 'Error');
                    } else {
                        popups.alertPopup('An unknown error has occurred', 'Error');
                    }
                });
            }
            $scope.voterDetails = null;
            $http.post('/api/getVoter/' + sessionId, {
                query: $scope.query
            }).then(function (resp) {
                if (resp.data && resp.data.voter) {
                    $scope.voterDetails = resp.data.voter;
                    $scope.voterDetails.AuditLog.forEach(function (log) {
                        var logdate = log.date;
                        log.date = new Date(logdate).toLocaleString();
                    });
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                    popups.alertPopup(err, 'Error');
                } else {
                    popups.alertPopup('An unknown error has occurred', 'Error');
                }
            });
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    $scope.release = function () {
        $http.post('/api/releaseQueryUser/' + sessionId, {
            query: $scope.query
        }).then(function (resp) {
            if (resp.data && !resp.data.status) {
                popups.alertPopup('An unknown error has occurred', 'Allocation');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Allocation');
            } else {
                popups.alertPopup('An unknown error has occurred', 'Allocation');
            }
        }).finally(function () {
            $state.go('el.voterSupport', {
                sessionId: sessionId
            }, {
                    reload: true
                }
            );
        });
    };

    // To reset the password or resend the activation email
    $scope.resetPassword = function () {
        $scope.resetSubmit = true;
        $http.post('/api/queryHelpUser/' + sessionId, {
            query: $scope.query
        }).then(function (resp) {
            if (resp && resp.data && resp.data.status) {
                popups.alertPopup('The password has been reset /  Activation email resent', 'Activation/Password');
                setStatusFc('On Hold'); // Mark the query as on hold
            } else {
                popups.alertPopup('An unknown error has occurred. Please try again later or contact the administrator', 'Activation/Password');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup('Error: ' + err.data.message, 'Error');
            } else {
                popups.alertPopup('An unknown error has occurred. Please try again later or contact the administrator', 'Activation/Password');
            }
        }).finally(function () {
            $scope.resetSubmit = false;
            getQuery();
        });
    };

    // Add a comment to the query
    $scope.addComment = function () {
        $http.post('/api/queryAddComment/' + sessionId, {
            query: $scope.query,
            comment: $scope.comment
        }).then(function (resp) {
            if (resp && resp.data && !resp.data.status) {
                popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup('Error: ' + err.data.message, 'Error');
            } else {
                popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
            }
        }).finally(function () {
            getQuery();
            $scope.showDetails = null;
            $scope.comment = null;
        });
    };

    function setStatusFc(status) {
        $http.post('/api/setQueryStatus/' + sessionId, {
            query: $scope.query,
            status: status
        }).then(function (resp) {
            if (resp && resp.data && resp.data.status) {
                if (status === 'Resolved' || status === 'On Hold') {
                    $scope.release();
                } else {
                    $scope.status = null;
                    $scope.showDetails = null;
                    getQuery();
                }
            } else {
                popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup('Error: ' + err.data.message, 'Error');
            } else {
                popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
            }
        });
    }

    // Set the status of the ticket
    $scope.setStatus = function () {
        setStatusFc($scope.status);
    };

    // Create the user on GUM
    $scope.createGUM = function () {
        var gumUser = {
            name: $scope.voterDetails.Name,
            surname: $scope.voterDetails.Surname,
            email: $scope.voterDetails.Email,
            organisation: $scope.voterDetails.Organization,
            territory: $scope.voterDetails.Territory,
            mobile: $scope.voterDetails.Mobile
        };
        $http.post('/api/createGUMVoter/' + sessionId, {
            user: gumUser,
            query: $scope.query
        }).then(function (resp) {
            if (resp && resp.data && resp.data.status) {
                popups.alertPopup('The user has been created', 'Identity User');
            } else {
                popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup('Error: ' + err.data.message, 'Error');
            } else {
                popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
            }
        }).finally(function () {
            getQuery();
        });
    };

    // Open the voter editing form
    $scope.editVoter = function () {
        $scope.changeDetails.Id = $scope.voterDetails.Id;
        $http.post('/api/votersroll/edit/' + sessionId, {
            Id: $scope.voterDetails.Id,
            Name: $scope.changeDetails.name || $scope.voterDetails.Name,
            Surname: $scope.changeDetails.surname || $scope.voterDetails.Surname,
            Email: $scope.voterDetails.Email,
            Territory: $scope.voterDetails.Territory,
            Organisation: $scope.changeDetails.organisation || $scope.voterDetails.Organisation,
            Mobile: $scope.changeDetails.mobile || $scope.voterDetails.Mobile,
            query: $scope.query.Id
        }).then(function (resp) {
            if (resp && resp.data && resp.data.status) {
                popups.alertPopup('The voter was updated');
                //setStatusFc('Resolved'); // Mark the query as resolved
            } else {
                popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup('Error: ' + err.data.message, 'Error');
            } else {
                popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
            }
        }).finally(function () {
            getQuery();
            $scope.showDetails = null;
        });
    };

    // To filter the roll based on the search criteria
    function filterRoll() {
        $scope.filterVoters = [];
        if ($scope.fullRoll.length > 0) {
            $scope.fullRoll.forEach(function (voter) {
                if (voter.Name.toUpperCase().includes($scope.searchCriteria.Name.toUpperCase()) && voter.Surname.toUpperCase().includes($scope.searchCriteria.Surname.toUpperCase()) && voter.Email.toUpperCase().includes($scope.searchCriteria.Email.toUpperCase()) && (voter.Numb == null || voter.Numb.toUpperCase().includes($scope.searchCriteria.Numb.toUpperCase())) && (voter.Numb_2 == null || voter.Numb_2.toUpperCase().includes($scope.searchCriteria.Numb_2.toUpperCase()))) {
                    $scope.filterVoters.push(voter);
                }
            });
        }
    }

    // To search the roll for a voter interactively
    $scope.searchRoll = function () {
        var searchLength = $scope.searchCriteria.Name.length + $scope.searchCriteria.Surname.length + $scope.searchCriteria.Email.length + $scope.searchCriteria.Numb.length + $scope.searchCriteria.Numb_2.length;
        if (searchLength < 5 && $scope.fullRoll) { // If the voters roll has been downloaded, but the search criteria changed below five, remove the roll to start over
            $scope.fullRoll = null;
            $scope.filterVoters = [];
        } else if (searchLength >= 5 && !$scope.fullRoll && !$scope.gettingData) { // If the search criteria is above 5 characters, there is no current roll downloaded and the roll is not in the process of being downloaded, download the roll
            $scope.gettingData = true;
            $http.post('/api/getSearchRoll/' + sessionId, {
                Critera: $scope.searchCriteria
            }).then(function (resp) {
                $scope.fullRoll = resp.data;
                filterRoll();
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.gettingData = false;
            });
        } else if (searchLength >= 5 && !!$scope.fullRoll) { // If the search criteria is above 5 characters, but there is a roll, just filter the current roll.
            filterRoll();
        }
    };

    // Clear the search criteria and roll
    $scope.clearCrit = function () {
        $scope.searchCriteria = {
            Name: '',
            Surname: '',
            Email: '',
            Numb: '',
            Numb_2: ''
        };
        $scope.fullRoll = null;
        $scope.filterVoters = [];
    };

    $scope.getDate = function (newDate) {
        if (newDate) {
            var returnDate = new Date(newDate).toLocaleString('en-ZA');
            return returnDate;
        } else {
            return '';
        }
    };

    $scope.selectVoter = function (voter) {
        if (voter.Voted === 1) {
            popups.alertPopup('This voter has already voted and cannot be replaced.', 'Voted');
        } else if (voter.Active === 0 || !voter.Active) {
            popups.alertPopup('This voter is inactive and cannot be replaced.', 'Inactive');
        } else {
            popups.confirmPopup('Are you sure you want to replace this voter with the voter in the query?', 'Replace').then(function () {
                $scope.query.QueryType = 'Replace';
                $scope.replaceVoter = voter;
            });
        }
    };

    // Add a voter with the details in the query
    $scope.addVoter = function () {
        popups.confirmPopup('Are you sure you want to add this as a new voter?', 'New Voter').then(function () {
            $scope.query.QueryType = 'New';
        });
    };

    function validateVoter() {
        $scope.msg = null;
        var validated = true;

        if (!$scope.query.Name || $scope.query.Name === '') {
            validated = false;
            $scope.msg = 'Please add a name';
        }

        if (!$scope.query.Surname || $scope.query.Surname === '') {
            validated = false;
            $scope.msg = 'Please add a surname';
        }

        if (!$scope.query.Email || $scope.query.Email === '') {
            validated = false;
            $scope.msg = 'Please add an email address';
        }

        if (!$scope.query.Organisation || $scope.query.Organisation === '') {
            validated = false;
            $scope.msg = 'Please add an organisation';
        }

        if (!$scope.query.Territory || $scope.query.Territory === '') {
            validated = false;
            $scope.msg = 'Please add a territory';
        }

        return validated;
    }

    // Submit the voter
    $scope.submitChange = function () {
        if (validateVoter()) {
            $http.post('/api/addReplaceVoter/' + sessionId, {
                query: $scope.query,
                replacement: $scope.replaceVoter || null
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    popups.alertPopup('The change has been submitted for approval', $scope.query.QueryType);
                    $scope.query.QueryType = null;
                    $scope.release();
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup('Error: ' + err.data.message, 'Error');
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
                }
            });
        } else {
            popups.alertPopup('Please fix the data issues', 'Error');
        }
    };

    // Reset the voting status and remove the votes from the database
    $scope.resetVoter = function () {
        popups.confirmPopup('Are you sure you want to reset this voter and remove the votes from the database?', 'Confirmation').then(function () {
            $scope.msg = '';
            $http.post('/api/resetVoter/' + sessionId, {
                Voter: $scope.voterDetails.Id,
                query: $scope.query
            }).then(function (resp) {
                if (resp.data.status) {
                    popups.alertPopup('The voter has been reset', 'Information');
                    getQuery();
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                }
            });
        });
    };

    // Deactive the user if they have not voted
    $scope.deactivateUser = function () {
        popups.confirmPopup('Are you sure you want to ' + ($scope.voterDetails.Active == 1 ? 'deactivate' : 'activate') + ' this voter?', 'Confirmation').then(function () {
            $scope.msg = '';
            $http.post('/api/deactivateVoter/' + sessionId, {
                Voter: $scope.voterDetails.Id,
                NewStatus: $scope.voterDetails.Active == 1 ? 0 : 1,
                query: $scope.query
            }).then(function (resp) {
                if (resp.data) {
                    popups.alertPopup('The voter has been ' + (resp.data.newStatus == 0 ? 'deactivated' : 'activated'), 'Information');
                    getQuery();
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                }
            });
        });
    };
    


    // Load the data after script creation
    getSessDetails();
    getQuery();
     
}];
