// Change password dialog - This is not used anywhere
module.exports = ['$scope', '$http', 'appState', 'userId', 'forced', function (scope, $http, appState, userId, forced) {
	scope.forced = !!forced;
	// NOTE: scope.closeThisDialog() is injected by ngDialog
	scope.password = '';
	scope.password2 = '';
	// error state
	scope.isProcessing = false;
	scope.error = false;
	scope.errorMsg = '';

	function validateInput() {
		scope.error = false;
		scope.errorMsg = '';

		// check if password and confirm password matches
		if (!scope.password || !scope.password2 || scope.password !== scope.password2) {
			scope.frm.password2.$error.mismatch = true;
			return false;
        }
        if (scope.password.length < 8 || !/[A-Z]/.test(scope.password) || !/[a-z]/.test(scope.password) || !/\d/.test(scope.password)) {
            scope.error = true;
            scope.errorMsg = 'Password requires a minimum of 8 characters, including one UPPERCASE, one lowercase and one number';
            return false;
        }
		return true;
	}

	// change password action
	scope.passwordChange = function () {
		if(scope.isProcessing)
			return;

		if(!validateInput())
			return;

		scope.isProcessing = true;
        $http.post('/users/change_password', {
            userId: userId,
            password: scope.password,
            forced: scope.forced
        }).then(function (res) {
            if (!scope.forced)
                appState.Valid = true;
			scope.closeThisDialog();
		}).catch(function (err) {
			scope.error = true;
			if(err.data && err.data.message)
				scope.errorMsg = err.data.message;
			else
				scope.errorMsg = 'Unknown error occurred';
		}).finally(function() {
			scope.isProcessing = false;
		});
	};
}];