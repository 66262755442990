// Controller for the integrity tests page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', function ($scope, $rootScope, $http, $state, popups, $stateParams) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Scope variables
    $scope.msg = '';
    $scope.voteDiff = null;
    $scope.notMarked = null;
    $scope.globalFailures = null;
    $scope.sessionFailures = null;

    // Draw the integrity exceptions
    $scope.getIntegrityData = function () {
        $scope.voteDiff = null;
        $scope.notMarked = null;
        $http.get('/api/getIntegrityExceptions/' + sessionId).then(function (resp) {
            $scope.voteDiff = resp.data.voteDiff;
            $scope.notMarked = resp.data.notMarked;
            $scope.globalFailures = resp.data.gumFailures.global;
            $scope.sessionFailures = resp.data.gumFailures.session;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    };


    // Get the date in the correct format or return an empty string if the date is blank
    $scope.getDate = function (newDate) {
        if (newDate) {
            var returnDate = new Date(newDate).toLocaleString('en-ZA');
            return returnDate;
        } else {
            return '';
        }
    };

    function getList(port, callback) {
        var delList = [];
        var remain = port.Votes - port.NumVotes;
        port.Details.forEach(function (vote) {
            if (vote.Delete) {
                delList.push(vote.Id);
                remain--;
            }
        });
        if (remain == 0) {
            callback(true, delList);
        } else {
            callback(false, delList);
        }
    }

    $scope.deleteVotes = function (portfolio) {
        $scope.msg = '';
        getList(portfolio, function (cont, list) {
            if (cont) {
                popups.confirmPopup('Are you sure you want to delete the selected lines?', 'Confirmation').then(function () {
                    $http.post('/api/deleteIntegrityVotes/' + sessionId, {
                        list: list
                    }).then(function (resp) {
                        $scope.getIntegrityData();
                    }).catch(function (err) {
                        if (err && err.data && err.data.message) {
                            popups.alertPopup(err.data.message, 'Error');
                            scope.msg = err.data.message;
                        }
                    });
                });
            } else {
                popups.alertPopup('The deletion will not fix the duplicate votes', 'Error');
            }
        });
    };

    $scope.markVoted = function (Id) {
        $scope.msg = '';
        $http.post('/api/markVotedIntegrity/' + sessionId, {
            Id: Id
        }).then(function (resp) {
            $scope.getIntegrityData();
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                scope.msg = err.data.message;
            }
        });
    };

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    $scope.deactivateFailure = function (type, failure) {
        popups.confirmPopup('Are you sure you want to deactivate this GUM failure?', 'Deactivation').then(function () {
            $http.post('/api/deactivateGUMFailure/' + sessionId, {
                type: type,
                failure: failure
            }).then(function (resp) {
                if (resp && resp.data && !resp.data.status) {
                    popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
                }
            }).finally(function () {
                $scope.getIntegrityData();
            });
        });
    };

    $scope.moveFailure = function (type, failure) {
        popups.confirmPopup('Are you sure you want to move this GUM failure to the session list?', 'Move').then(function () {
            $http.post('/api/moveGUMFailure/' + sessionId, {
                type: type,
                failure: failure
            }).then(function (resp) {
                if (resp && resp.data && !resp.data.status) {
                    popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later', 'Error');
                }
            }).finally(function () {
                $scope.getIntegrityData();
            });
        });
    };


    // Draw the exceptions on page creation
    $scope.getIntegrityData();
    getSessDetails();

}];


