// Controller for the ballot capture finalisation page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', '$timeout', 'appState', function ($scope, $rootScope, $http, $state, popups, $stateParams, $timeout, appState) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }
   
    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
            if (!$scope.SessionSettings.Capture_Enable) { // If vote capturing is not enabled, return to the sessions screen.
                popups.alertPopup('Vote Capturing is not enabled on this session', 'Capturing');
                $state.go('el.sessions');
                return;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    function getSummaryLists() {
        $http.get('/api/getCaptureSummary/' + sessionId, null).then(function (resp) {
            if (resp && resp.data) {
                $scope.summaryList.Pending = resp.data.Pending;
                $scope.summaryList.Dispute = resp.data.Dispute;
                $scope.summaryList.Finalised = resp.data.Finalised;
                $scope.summaryList.NotFinalValid = resp.data.NotFinalValid;
                $scope.summaryList.NotFinalInvalid = resp.data.NotFinalInvalid;
                $scope.summaryList.NotFinalNoDispute = resp.data.NotFinalNoDispute;
                $scope.summaryList.NotFinalDisputed = resp.data.NotFinalDisputed;
                $scope.summaryList.Removed = resp.data.Removed;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    $scope.addValid = function () {
        popups.confirmPopup('Are you sure you want to add the captured valid ballots to the results?', 'Captured Ballots').then(function () {
            $http.post('/api/postCaptureValid/' + sessionId, {}).then(function (resp) {
                if (resp && resp.data && resp.data.status) { // If the ballot was written to the database
                    popups.alertPopup('The captured ballots were posted', 'Post Votes');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Post Votes');
                }
            }).finally(function () {
                resetPage();
            });
        });
    };

    $scope.addResults = function () {
        popups.confirmPopup('Are you sure you want to add the captured ballots, without duplications, to the results?', 'Captured Ballots').then(function () {
            $http.post('/api/postCaptureResult/' + sessionId, {
                ballots: $scope.summaryList.NotFinalNoDispute
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) { // If the ballot was written to the database
                    popups.alertPopup('The captured ballots were posted', 'Post Votes');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Post Votes');
                }
            }).finally(function () {
                resetPage();
            });
        });
    };

    function getCandName(portfolio, candidate, callback) {
        $scope.reviewBallot.Results_C.forEach(function (port) {
            if (port.PortNr == portfolio) {
                port.Candidates.forEach(function (cand) {
                    if (cand.Id == candidate) {
                        callback(cand.Name);
                    }
                });
            }
        });
    }

    // Shows details of the captured ballots to select a winning result, or to activate a new capture
    $scope.resolveDuplication = function (ballot) {
        $scope.showReview = 'Details';
        $scope.reviewBallot = ballot;
        $http.post('/api/getOnlineDuplicateResult/' + sessionId, {
            voter: $scope.reviewBallot.Voter
        }).then(function (resp) {
            if (resp && resp.data && resp.data.results) { // If the results were returned
                $scope.reviewBallot.OnlineResults = resp.data.results;
                $scope.reviewBallot.OnlineDate = $scope.reviewBallot.OnlineResults[0].AddDate;
                $scope.reviewBallot.Results_C = JSON.parse(ballot.Results);
                $scope.reviewBallot.All_Results = [];
                $scope.reviewBallot.Results_C.forEach(function (port1) {
                    var newPort = {};
                    newPort.Nr = port1.PortNr;
                    newPort.Name = port1.Portfolio;
                    newPort.Result_C = [];
                    newPort.Result_O = [];
                    port1.Candidates.forEach(function (cand1) {
                        if (cand1.Voted) {
                            newPort.Result_C.push(cand1.Name);
                        }
                    });
                    for (let i = 0; i < port1.Abstains; i++) {
                        newPort.Result_C.push('Abstain');
                    }
                    $scope.reviewBallot.OnlineResults.forEach(function (result) {
                        if (result.PortNr == port1.PortNr) {
                            getCandName(result.PortNr, result.Candidate, function (name) {
                                newPort.Result_O.push(name);
                            });
                        }
                    });
                    $scope.reviewBallot.All_Results.push(newPort);
                });
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Duplicate Votes');
            }
        });
    };

    // Selecting the winning ballot uploads this to the database
    $scope.selectWinner = function (winner) {
        popups.confirmPopup('Are you sure you want to upload the ' + winner + ' ballot as the winner?', 'Duplicate Votes').then(function () {
            $http.post('/api/duplicateWinner/' + sessionId, {
                ballot: $scope.reviewBallot,
                winner: winner
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) { // If the ballot was written to the database
                    popups.alertPopup('The duplicate ballots were resolved', 'Duplicate');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                }
            }).finally(function () {
                resetPage();
            });
        });
    };

    $scope.deleteBallot = function () {
        popups.confirmPopup('Are you sure you want to void both ballots?', 'Void Ballots').then(function () {
            $http.post('/api/voidBothBallots/' + sessionId, {
                ballot: $scope.reviewBallot
            }).then(function (resp) {
                if (resp.data.status) { // If the delete was valid
                    popups.alertPopup('The ballots were voided', 'Void Ballot');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Disputed Ballot');
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                resetPage();
            });
        });
    };

    $scope.downloadBallots = function () {
        window.open(apiUrl + '/api/getCaptureBallotsExcel/' + sessionId + '/exportxls?token=' + appState.token);
    };

    // To reset the page to the start
    function resetPage() {
        $scope.msg = '';
        $scope.showReview = 'Summary';
        $scope.summaryList = {};
        $scope.reviewBallot = null;
        getSummaryLists();
    }


    // Start the page
    getSessDetails();
    resetPage();

}];
