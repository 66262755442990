// Controller for the account management page
module.exports = ['$scope', '$http', '$cookies', '$state', '$stateParams', 'popups', 'ngDialog', function ($scope, $http, $cookies, $state, $stateParams, popups, ngDialog) {
    $scope.csrfToken = $cookies.get('XSRF-TOKEN');
    $scope.Email = $stateParams.email;
    $scope.Instructions = "Your account has either been created now, or you have a previous account from using any of PwC's software systems. If you did not previously activate an account with PwC, you should now have received an email from PwC requesting you to activate you account. Please follow the instructions on the account activation email. After activating your account, please click the button below to log in. If you previously activated your account, please log in with the password selected. If you did not receive an activation email, please click on the 'Forgot Password / Resend Activation Email' button below.";
    $scope.CellphoneError = 'There was a problem populating your cellphone number on your account. If you have any trouble logging in, please "Log a Query" below, adding your cellphone number to the query. The administrators will then review and correct your account.';
    if ($stateParams.err == 'cellphone') {
        $scope.CellError = true;
    } else {
        $scope.CellError = false;
    }

    $scope.activationSubmit = false;

    $scope.activation = function () {
        $scope.activationSubmit = true;
        $http.post('/api/activateUser', {
            email: $scope.Email
        }).then(function (resp) {
            if (resp.data && resp.data.status) {
                popups.alertPopup('An email was sent to your email address.', 'Activation/Password Reset');
            } else {
                popups.alertPopup('An error has occurred. The administrator has been notified. You will receive the activation/password reset email shortly', 'Activation/Password Reset');
            }
        }).catch(function (err) {
            popups.alertPopup('An error has occurred. The administrator has been notified. If you do not receive an email within a few minutes, please log a query.', 'Activation/Password Reset');
        }).finally(function () {
            $scope.activationSubmit = false;
        });
    };

    // Create a dialog box to log a query
    $scope.createQueryDialog = function () {
        var dialog = ngDialog.open({
            template: require('../tpl/log-query.html'),
            plain: true,
            controller: 'queryExternal',
            showClose: false,
            closeByDocument: false,
            resolve: {
                'params': function () {
                    return {
                        'email': $scope.Email
                    };
                }
            }
        });
        dialog.closePromise.then(function (data) {
            if (data.value) { // If the query was raised, indicate this and return to the login screen
                popups.alertPopup('The query was raised to the administrator.', 'Support Query');
                $state.go('elo.login', {
                    reload: true
                });
            } else { // If not, make a note that the query was not logged
                popups.alertPopup('The query was not raised', 'Support Query');
            }
        });
    };
}];