// Controller for the anonymous results pages

module.exports = ['$rootScope', '$scope', '$http', '$state', 'popups', '$stateParams', function ($rootScope, $scope, $http, $state, popups, $stateParams) {

    var results = JSON.parse(decodeURI($stateParams.data));

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Scope variables
    $scope.msg = '';
    $scope.extRes1 = [];
    $scope.extRes2 = [];

    function getCandidates() {
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.Candidates = resp.data.Portfolios;
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
            $scope.getResults();
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    $scope.getResults = function () {
        $http.post('/api/getAnonResults/' + sessionId, {
            limits: results
        }).then(function (resp) {
            if (resp.data.status) {
                var fullList = resp.data.results;
                splitList(fullList);
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    };

    function splitList(fullList) {
        $scope.extRes1 = [];
        $scope.extRes2 = [];
        var middle = Math.round(fullList.length / 2);
        for (var i = 0; i < middle; i++) {
            $scope.extRes1.push(fullList[i]);
        }
        for (var j = middle; j < fullList.length; j++) {
            $scope.extRes2.push(fullList[j]);
        }
    }

    // Run the get scripts to setup the page
    getCandidates();

}];

