// Controller for the proxy management page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', 'appState', '$window', 'ngDialog', function ($scope, $rootScope, $http, $state, popups, $stateParams, appState, $window, ngDialog) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
        $http.get('/api/getProxySettings/' + sessionId, null).then(function (resp) {
            if (resp.data && resp.data.status) {
                $scope.ProxySettings = resp.data.ProxySettings;
                $scope.ProxySettings.Comment_Options.push({ name: 'Other', disqualified: false });
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // To filter the roll based on the search criteria
    function filterRoll() {
        $scope.filterVoters = [];
        if ($scope.fullRoll.length > 0) {
            $scope.fullRoll.forEach(function (voter) {
                if (voter.Name.toUpperCase().includes($scope.searchCriteria.Name.toUpperCase()) && voter.Surname.toUpperCase().includes($scope.searchCriteria.Surname.toUpperCase()) && voter.Email.toUpperCase().includes($scope.searchCriteria.Email.toUpperCase()) && (voter.Numb == null || voter.Numb.toUpperCase().includes($scope.searchCriteria.Numb.toUpperCase())) && (voter.Numb_2 == null || voter.Numb_2.toUpperCase().includes($scope.searchCriteria.Numb_2.toUpperCase()))) {
                    $scope.filterVoters.push(voter);
                }
            });
        }
    }

    // To search the voters roll interactively
    $scope.searchRoll = function () {
        var searchLength = $scope.searchCriteria.Name.length + $scope.searchCriteria.Surname.length + $scope.searchCriteria.Email.length + $scope.searchCriteria.Numb.length + $scope.searchCriteria.Numb_2.length;
        if (searchLength < 5 && $scope.fullRoll) { // If the voters roll has been downloaded, but the search criteria changed below five, remove the roll to start over
            $scope.fullRoll = null;
            $scope.filterVoters = [];
        } else if (searchLength >= 5 && !$scope.fullRoll && !$scope.gettingData) { // If the search criteria is above 5 characters, there is no current roll downloaded and the roll is not in the process of being downloaded, download the roll
            $scope.gettingData = true;
            $http.post('/api/getSearchRoll/' + sessionId, {
                Critera: $scope.searchCriteria
            }).then(function (resp) {
                $scope.fullRoll = resp.data;
                filterRoll();
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.gettingData = false;
            });
        } else if (searchLength >= 5 && !!$scope.fullRoll) { // If the search criteria is above 5 characters, but there is a roll, just filter the current roll.
            filterRoll();
        }
    };

    // Clear the search criteria and set the variables
    $scope.clearCrit = function () {
        $scope.searchCriteria = {
            Name: '',
            Surname: '',
            Email: '',
            Numb: '',
            Numb_2: ''
        };
        $scope.fullRoll = null;
        $scope.filterVoters = [];
        $scope.gettingData = false;
    };

    // To handle if there is an active proxy for the voter
    function handleActiveProxy(voter, proxy) {
        popups.confirmPopup("This voter has provided a proxy to another voter. Do you want to disqualify this voter's proxy?", 'Active Proxy').then(function (resp) {
            $scope.msg = null;
            $http.post('/api/meetingDeactivateProxy/' + sessionId, { // Deactivate the proxy
                proxy: proxy
            }).then(function (resp) {
                if (resp.data.status) { // If the proxy was disqualified
                    $scope.selectMember(voter);
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Proxy Disqualification');
                    $scope.msg = err.data.message;
                }
            });
        }).catch(function () {
            popups.alertPopup('The voter cannot register or vote until the proxy is removed', 'Active Proxy');
            resetPage();
        });
    }

    // To select the member to register
    $scope.selectMember = function (voter) {
        $scope.msg = null;
        $http.post('/api/getMeetingVoterStats/' + sessionId, {
            voter: voter
        }).then(function (resp) {
            if (resp.data.status) { // If the stats were returned
                resetPage();
                $scope.proxyStats = voter;
                $scope.proxyStats.Stats = resp.data.stats;
                $scope.clearCrit();
                if ($scope.proxyStats.Active == 0) {
                    popups.alertPopup('This is an inactive voter and will be registered as a non-voter', 'Inactive Member');
                }
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Proxy List');
                $scope.msg = err.data.message;
            } else if (err && err.data && err.data.proxy) { // If there is a proxy identified for the user
                handleActiveProxy(voter, err.data.proxy);
            }
        });
    };

    // Cancel the activity on the current voter
    $scope.registerCancel = function () {
        resetPage();
    };

    // To generate the printed certificates
    function generatePrint(type, data) {
        var description = '';
        var Print = {};
        if (type == 'Non-Member') {
            Print = {
                Fields: [
                    {
                        Id: 1,
                        Name: 'Non-Member Name',
                        Value: data.member.Name + ' ' + data.member.Surname
                    },
                    {
                        Id: 2,
                        Name: 'Contact Number',
                        Value: data.member.Contact
                    }, {
                        Id: 3,
                        Name: 'Organisation',
                        Value: data.member.Organisation
                    }]
            };
            Print.Title = 'Certificate of Attendance';
            Print.TimeStamp = new Date(data.timestamp).toLocaleString();
            description = $scope.ProxySettings.Meeting_Cert;
            Print.Description = description + ' as a non-member.';
            openPrint(Print);
        } else {
            Print = {
                Fields: [
                    {
                        Id: 1,
                        Name: 'Member',
                        Value: $scope.proxyStats.Name + ' ' + $scope.proxyStats.Surname
                    }]
            };
            if ($scope.SessionSettings.Show_Number) {
                Print.Fields.push({
                    Id: Print.Fields.length + 1,
                    Name: $scope.SessionSettings.Number_Name,
                    Value: $scope.proxyStats.Numb
                });
            }
            if ($scope.SessionSettings.Show_Number_2) {
                Print.Fields.push({
                    Id: Print.Fields.length + 1,
                    Name: $scope.SessionSettings.Number_Name_2,
                    Value: $scope.proxyStats.Numb_2
                });
            }
            if (type == 'MeetingAttendance') {
                Print.Title = 'Certificate of Attendance';
                Print.TimeStamp = new Date(data).toLocaleString();
                Print.Description = $scope.ProxySettings.Meeting_Cert;
                openPrint(Print);
            } else if (type == 'VotingAttendance') {
                Print.Title = 'Voting Participation';
                Print.TimeStamp = new Date().toLocaleString();
                Print.Description = [];
                Print.Description.push($scope.ProxySettings.Voting_Cert);
                if ($scope.proxyStats.Active == 1 && $scope.proxyStats.Stats && $scope.proxyStats.Stats.Valid.Active > 0) { // If this is a valid member with proxies
                    Print.Description.push('Voting as a member and holding ' + $scope.proxyStats.Stats.Valid.Active + ' valid ' + ($scope.proxyStats.Stats.Valid.Active > 1 ? 'proxies' : 'proxy') + ' on behalf of members');
                } else if ($scope.proxyStats.Active == 1) { // If this is a valid member without proxies
                    Print.Description.push('Voting as a member without holding valid proxies');
                } else if ($scope.proxyStats.Active != 1 && $scope.proxyStats.Stats && $scope.proxyStats.Stats.Valid.Active > 0) { // If this is an invalid member with proxies
                    Print.Description.push('Representing ' + $scope.proxyStats.Stats.Valid.Active + ' ' + ($scope.proxyStats.Stats.Valid.Active > 1 ? 'members' : 'member') + ' during this election as a proxy holder');
                } else { // If the member is not active and does not have any proxies
                    Print.Description.push('Not being an active member and not holding any proxies');
                }
                openPrint(Print);
            } else if (type == 'InactiveMember') {
                Print.Title = 'Certificate of Attendance';
                Print.TimeStamp = new Date(data).toLocaleString();
                description = $scope.ProxySettings.Meeting_Cert;
                Print.Description = description + ' as a non-member';
                openPrint(Print);
            } else {
                popups.alertPopup('Unknown certificate type selected. Please contact the administrator', 'Error');
            }
        }
    }

    // Register for the meeting
    $scope.registerMeeting = function () {
        if (!$scope.proxyStats) {
            popups.alertPopup('Please select a voter', 'Registration');
        } else {
            popups.confirmPopup('Are you sure you want to register this voter for the meeting?', 'Meeting Registration').then(function () {
                $http.post('/api/registerMeeting/' + sessionId, {
                    voter: $scope.proxyStats
                }).then(function (resp) {
                    if (resp.data.status) {
                        if (resp.data.existing) {
                            popups.confirmPopup('This voter has already been registered by: ' + resp.data.existing.Meeting_Capturer + '. Do you want to reprint the confirmation?', 'Meeting Registration').then(function () {
                                if ($scope.proxyStats.Active == 0 && (!$scope.proxyStats.Stats || $scope.proxyStats.Stats.Valid.Active == 0)) { // If the member is inactive and does not hold any proxies
                                    generatePrint('InactiveMember', resp.data.existing.Register_Time);
                                } else {
                                    generatePrint('MeetingAttendance', resp.data.existing.Register_Time);
                                }
                            });
                        } else {
                            popups.alertPopup('The person was registered for the meeting');
                            if ($scope.proxyStats.Active == 0 && (!$scope.proxyStats.Stats || $scope.proxyStats.Stats.Valid.Active == 0)) { // If the member is inactive and does not hold any proxies
                                generatePrint('InactiveMember', resp.data.timestamp);
                            } else {
                                generatePrint('MeetingAttendance', resp.data.timestamp);
                            }
                            resetPage();
                        }
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        popups.alertPopup(err.data.message, 'Meeting Registration');
                        $scope.msg = err.data.message;
                    }
                });
            });
        }
    };

    function getBallots(voter) {
        $http.post('/api/testVotingRegistration/' + sessionId, {
            voter: voter
        }).then(function (resp) {
            $scope.ballotIssuing = true;
            $scope.newBallot = {
                Number: '',
                Weight: 0,
                Allocated: 0,
                Remain: 0
            };
            $scope.issuedBallots = resp.data.ballots;
            var remaining = ($scope.proxyStats.Active == 1 ? 1 : 0) + ($scope.proxyStats.Stats ? $scope.proxyStats.Stats.Valid.Active : 0);
            var total = 0;
            if ($scope.issuedBallots.length > 0) {
                $scope.issuedBallots.forEach(function (ballot) {
                    if (ballot.Active == 1) {
                        total = total + ballot.Weight;
                    }
                });
            }
            $scope.newBallot.Allocated = total;
            $scope.newBallot.Remain = remaining - total;
            $scope.newBallot.Weight = remaining - total;
            // If there are no ballots issued, open the certificates for attendance and the proxy number certificate
            if (!$scope.issuedBallots || $scope.issuedBallots.length == 0) {
                // Open the certificate of attendance
                generatePrint('VotingAttendance', null);
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Voting Registration');
                $scope.msg = err.data.message;
            }
        });
    }

    // Register for voting
    $scope.registerVoting = function () {
        if (!$scope.proxyStats) {
            popups.alertPopup('Please select a voter', 'Registration');
        } else {
            if ($scope.ProxySettings.Inactive_Transfer && !$scope.proxyStats.Active == 1) {
                selectNewMember('Proxy Holder');
            } else {
                popups.confirmPopup('Are you sure you want to register this voter to vote?', 'Meeting Registration').then(function () {
                    getBallots($scope.proxyStats);
                });
            }
        }
    };

    $scope.reprintVoterCerts = function () {
        if (!$scope.proxyStats) {
            popups.alertPopup('Please select a voter', 'Registration');
        } else {
            generatePrint('VotingAttendance', null);
        }
    };

    // To start the process of spoiling the ballot and replacing it with a new ballot
    $scope.spoilBallot = function (ballot) {
        popups.confirmPopup('Are you sure you want to spoil this ballot', 'Spoil Ballot').then(function () {
            $scope.msg = null;
            $http.post('/api/spoilVoterBallot/' + sessionId, {
                ballot: ballot
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    getBallots($scope.proxyStats);
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Voting Registration');
                    $scope.msg = err.data.message;
                }
            });
        });
    };

    // Add the ballot to the list of issued ballots
    $scope.addBallot = function () {
        if ($scope.newBallot.Number == null || $scope.newBallot.Number == '') {
            popups.alertPopup('Please enter the ballot number', 'Ballot Number');
        } else if ($scope.newBallot.Weight == null || $scope.newBallot.Weight < 0) {
            popups.alertPopup('The weighting cannot be less than zero', 'Ballot Weight');
        } else {
            $scope.msg = null;
            $http.post('/api/addVoterBallot/' + sessionId, {
                voter: $scope.proxyStats,
                ballot: $scope.newBallot
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    getBallots($scope.proxyStats);
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Voting Registration');
                    $scope.msg = err.data.message;
                }
            });
        }
    };

    // Popup to select a voter or proxy holder
    function selectNewMember(type) {
        var dialog = ngDialog.open({
            template: require('../tpl/select-voter.html'),
            className: 'ngdialog-theme-default custom-width-100',
            plain: true,
            controller: 'selectVoter',
            showClose: false,
            closeByDocument: false,
            resolve: {
                'params': function () {
                    return {
                        'type': type,
                        'sessionSettings': $scope.SessionSettings,
                        'sessionId': sessionId
                    };
                }
            }
        });
        dialog.closePromise.then(function (data) {
            if (data && data.value && data.value.voter && data.value.voter.Id != 0) { // If a voter was selected
                $http.post('/api/replaceHolder/' + sessionId, {
                    voter: $scope.proxyStats,
                    holder: data.value.voter.Id
                }).then(function (resp) {
                    if (resp && resp.data && resp.data.status) {
                        popups.alertPopup('The proxies were transferred to the new holder', 'Transfer');
                    } else {
                        popups.alertPopup('An unknown error has occurred. Please try again.', 'Transfer');
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        popups.alertPopup(err.data.message, 'Voting Registration');
                        $scope.msg = err.data.message;
                    }
                }).finally(function () {
                    $scope.selectMember($scope.proxyStats); // Refresh the voter
                });
            }

        });
    };

    // Register a non-member for the meeting
    $scope.registerNonMember = function () {
        // Popup to decide which proxy to invalidate
        var dialog = ngDialog.open({
            template: require('../tpl/add_non_member.html'),
            //className: 'ngdialog-theme-default custom-width-60',
            plain: true,
            controller: 'addNonMember',
            showClose: false,
            closeByDocument: false,
            resolve: {
                'params': function () {
                    return {
                        'sessionId': sessionId
                    };
                }
            }
        });
        dialog.closePromise.then(function (data) {
            if (data && data.value) { // If the non-member was added to the database, a timestamp was returned
                generatePrint('Non-Member', data.value);
                resetPage();
            }
        });
    };

    // Reset the page to the menu
    function resetPage() {
        $scope.msg = '';
        $scope.proxyStats = null;
        $scope.clearCrit();
        $scope.ballotIssuing = false;
        $scope.issuedBallots = [];
    }

    $scope.clearPage = function () {
        if ($scope.newBallot.Remain != 0) {
            popups.alertPopup('All of the votes have not been allocated to ballots', 'Allocation');
        } else {
            resetPage();
        }
    };

    // Open the external page
    function openPrint(data) {
        var dataurl = encodeURI(JSON.stringify(data));
        var url = $state.href('eom.printcertificate', { data: dataurl });
        $window.open(url, '_blank');
    }

    // Start the page
    getSessDetails();
    resetPage();

}];
