// Controller for the proxy management page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', 'appState', function ($scope, $rootScope, $http, $state, popups, $stateParams, appState) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
        $http.get('/api/getProxySettings/' + sessionId, null).then(function (resp) {
            if (resp.data && resp.data.status) {
                $scope.ProxySettings = resp.data.ProxySettings;
                $scope.ProxySettings.Comment_Options.push({ name: 'Other', disqualified: false });
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    function drawStats() {
        $scope.msg = '';
        $http.get('/api/getProxyStats/' + sessionId, null).then(function (resp) {
            if (resp.data.status) {
                $scope.allStats = resp.data.stats.Proxies;
                $scope.holderStats = resp.data.stats.Holders;
                createFilters();
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Proxy Statistics');
                $scope.msg = err.data.message;
            }
        });
        $http.post('/api/getDupProxiesFiltered/' + sessionId, {
            settings: $scope.ProxySettings
        }).then(function (resp) {
            if (resp.data.status) { // If the list was returned
                $scope.proxyDups = resp.data.lists;
                $scope.proxyDups.Numbers.sort((a, b) => a.Batch > b.Batch ? 1 : a.Batch == b.Batch && a.P_Position > b.P_Position ? 1 : -1); // Sort the list based on the Batch Position
                $scope.proxyDups.Voters.sort((a, b) => a.Batch > b.Batch ? 1 : a.Batch == b.Batch && a.P_Position > b.P_Position ? 1 : -1); // Sort the list based on the Batch Position
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Proxy List');
                $scope.msg = err.data.message;
            }
        });
    }

    function createFilters() {
        $scope.filters = {
            Batch: [],
            Status: [],
            Valid: [],
            Show: false,
            Total: 0
        };
        var tempnames = {
            Batch: [],
            Status: [],
            Valid: []
        };
        $scope.allStats.forEach(function (stat) {
            if (tempnames.Batch.indexOf(stat.Batch) == -1) { // If the batch does not exist in the available filters
                tempnames.Batch.push(stat.Batch);
                $scope.filters.Batch.push({
                    name: stat.Batch,
                    applied: false
                });
            }
            if (tempnames.Status.indexOf(stat.Status) == -1) { // If the batch does not exist in the available filters
                tempnames.Status.push(stat.Status);
                $scope.filters.Status.push({
                    name: stat.Status,
                    applied: false
                });
            }
            if (tempnames.Valid.indexOf(stat.Valid) == -1) { // If the batch does not exist in the available filters
                tempnames.Valid.push(stat.Valid);
                $scope.filters.Valid.push({
                    name: stat.Valid,
                    applied: false
                });
            }
        });
        $scope.setFilters();
    }

    $scope.setFilters = function () {
        $scope.filteredStats = [];
        $scope.filters.Total = 0;
        $scope.allStats.forEach(function (batch) {
            var include = {
                Batch: false,
                Status: false,
                Valid: false
            };
            var filtered = {
                Batch: false,
                Status: false,
                Valid: false
            };
            $scope.filters.Batch.forEach(function (filterBatch) {
                if (filterBatch.applied) {
                    filtered.Batch = true;
                    if (batch.Batch == filterBatch.name) {
                        include.Batch = true;
                    }
                }
            });
            if (!filtered.Batch) {
                include.Batch = true;
            }
            $scope.filters.Status.forEach(function (filterStatus) {
                if (filterStatus.applied) {
                    filtered.Status = true;
                    if (batch.Status == filterStatus.name) {
                        include.Status = true;
                    }
                }
            });
            if (!filtered.Status) {
                include.Status = true;
            }
            $scope.filters.Valid.forEach(function (filterValid) {
                if (filterValid.applied) {
                    filtered.Valid = true;
                    if (batch.Valid == filterValid.name) {
                        include.Valid = true;
                    }
                }
            });
            if (!filtered.Valid) {
                include.Valid = true;
            }
            if (include.Batch && include.Status && include.Valid) {
                $scope.filteredStats.push(batch);
                $scope.filters.Total = $scope.filters.Total + batch.Items;
            }
        });
    };

    // Draw the proxies based on the line provided
    $scope.drawProxies = function (batch) {
        $http.post('/api/getProxyListFiltered/' + sessionId, {
            batch: batch
        }).then(function (resp) {
            if (resp.data.status) { // If the list was returned
                $scope.batchList = batch;
                $scope.proxyList = resp.data.list;
                $scope.proxyList.sort((a, b) => a.P_Position > b.P_Position ? 1 : -1); // Sort the list based on the Batch Position
                $scope.proxyList.forEach(function (proxy) {
                    if (proxy.ExternalReview == 1) {
                        proxy.ExternalReview = true;
                    } else {
                        proxy.ExternalReview = false;
                    }
                });
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Proxy List');
                $scope.msg = err.data.message;
            }
        });
    };

    // Send to database to enable or disable external review
    $scope.changeExternalReview = function (proxy) {
        $http.post('/api/changeProxyExternalReview/' + sessionId, {
            proxy: proxy
        }).then(function (resp) {
            if (!resp.data.status) { // If the item was changed
                popups.alertPopup('An unknown error has occurred. Please contact the administrator');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'External Review');
                $scope.msg = err.data.message;
            }
        });
    };

    // Open a proxy for review
    $scope.openProxy = function (proxy) {
        $rootScope.ProxyBatch = {};
        $rootScope.ProxyBatch.Batch = proxy.Batch;
        $rootScope.ProxyBatch.Number = proxy.P_Position;
        $rootScope.$emit('CreateManagementProxy', {
            SessionSettings: $scope.SessionSettings,
            ProxySettings: $scope.ProxySettings,
            sessionId: sessionId,
            batch: $scope.batchList
        });
        $scope.captureShow = 'Management Review';
    };

    // To reopen the proxy is something went wrong
    function reopenProxy(Proxy, ProxyType) {
        $rootScope.$emit('ReopenProxy', {
            SessionSettings: $scope.SessionSettings,
            ProxySettings: $scope.ProxySettings,
            sessionId: sessionId,
            Proxy: Proxy,
            ProxyType: ProxyType
        });
    }

    // To capture the proxy on the database based on the return
    var deregister1 = $rootScope.$on('CreateCaptureProxyReturn', function (event, DataObj) {
        var Proxy = DataObj.Proxy;
        var ProxyType = DataObj.ProxyType;
        $http.post('/api/sendProxyCaptureReview/' + sessionId, {
            proxy: Proxy,
            fields: $scope.ProxySettings.AddFields,
            type: ProxyType
        }).then(function (resp) {
            if (resp.data.status) { // If the capture was valid
                if (ProxyType == 'ManageReview') {
                    popups.alertPopup('The proxy form was sucessfully reviewed by management', 'Success');
                    resetPage();
                    if (DataObj.batch) {
                        $scope.drawProxies(DataObj.batch);
                    }
                } else {
                    popups.alertPopup('An unknown error has occurred. Please contact the administrator', 'Error');
                }
            } else {
                reopenProxy(Proxy, ProxyType);
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Proxy Management');
                $scope.msg = err.data.message;
            }
            reopenProxy(Proxy, ProxyType);
        });
    });

    // To close the proxy and return to the menu
    var deregister2 = $rootScope.$on('CreateProxyBackReturn', function (event) {
        resetPage();
    });

    // To download the proxy forms in Excel
    $scope.downloadProxies = function () {
        window.open(apiUrl + '/api/getProxyExcel/' + sessionId + '/exportxls?token=' + appState.token);
    };

    // Reset the page to the menu
    function resetPage() {
        $scope.msg = '';
        $scope.captureShow = 'Menu';
        $scope.allStats = [];
        $scope.holderStats = [];
        $scope.proxyList = null;
        $scope.proxyDups = null;
        drawStats();
    }

    // Destroy the listeners to not have the procedures created and executed more than once
    $scope.$on('$destroy', function () {
        deregister1();
        deregister2();
    });

    // Start the page
    getSessDetails();
    resetPage();

}];

// TODO Later Button to delete the proxy on the proxy form

