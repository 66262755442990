// Controller for the voting screen

module.exports = ['$scope', '$http', '$state', '$rootScope', '$stateParams', 'appState', 'popups', 'ngDialog',
    function ($scope, $http, $state, $rootScope, $stateParams, appState, popups, ngDialog) {
        // Get the candidates from the list. This is called after the user is verified as active on the voters roll.
        var deregister1 = $rootScope.$on('CreateCaptureBallot', function (event, DataObj) {
            $scope.Candidates = DataObj.Candidates;
            $scope.SessionSettings = DataObj.SessionSettings;
            $scope.BallotType = 'Capture';
            $scope.ballotNr = DataObj.ballotNr;
            resetBallot();
            setupVoting();
        });

        var deregister2 = $rootScope.$on('CreateReviewBallot', function (event, DataObj) {
            $scope.Candidates = DataObj.Candidates;
            $scope.SessionSettings = DataObj.SessionSettings;
            $scope.BallotType = 'Review';
            resetBallot();
            setupVoting();
        });

        var deregister3 = $rootScope.$on('CreateManagedBallot', function (event, DataObj) {
            $scope.Candidates = DataObj.Candidates;
            $scope.SessionSettings = DataObj.SessionSettings;
            $scope.BallotType = 'ManagedVoting';
            resetBallot();
            setupVoting();
        });

        var deregister4 = $rootScope.$on('ReopenManagedBallot', function (event, DataObj) {
            $scope.Candidates = DataObj.Candidates;
            $scope.SessionSettings = DataObj.SessionSettings;
            $scope.BallotType = 'ManagedVoting';
            reopenBallot();
        });

        // Set up the top variables
        var inSummary = false;

        function resetBallot() {
            $scope.msg = '';
            $scope.currentPortfolio = 1;
            inSummary = false;
            $scope.showView = 'Details';
            $scope.ballot = {
                Valid: true,
                comments: ''
            };
        }

        function reopenBallot() {
            $scope.msg = '';
            inSummary = true;
            $scope.showView = 'Summary';
        }

        // Add the required fields to the object for the voting screen to function
        function setupVoting() {
            $scope.Candidates.forEach(function (port) {
                port.Candidates.forEach(function (person) {
                    person.Voted = false;
                });
            });
        }

        $scope.calcClass = function () {
            var ending = 85;
            if ($scope.SessionSettings.Show_Party) {
                ending = ending - 20;
            }
            if ($scope.SessionSettings.Show_Photo) {
                ending = ending - 10;
            }
            if ($scope.SessionSettings.Show_Bio) {
                ending = ending - 20;
            }
            var colclass = 'col_' + ending;
            return colclass;
        };

        // Read the biography of the voter
        // TODO Review this when moving the full ballot over. This will only work if accessed by the user itself, otherwise the route should be based on the capture, review or managed access
        $scope.viewBio = function (Cand) {
            if ($scope.BallotType == 'Capture' || $scope.BallotType == 'Review' || $scope.BallotType == 'ManagedVoting') {
                window.open(apiUrl + '/api/getCandBioTest/' + $scope.SessionSettings.Id + '/' + Cand + '?token=' + appState.token);
            } else {
                window.open(apiUrl + '/api/getCandBio/' + sessionId + '/' + Cand + '?token=' + appState.token);
            }
        };

        // Verify that the number of votes per portfolio is correct. Callback(proceed, remaining votes)
        function checkVotes(port, callback) {
            var remaining = port.NumVotes;
            port.Candidates.forEach(function (cand) {
                if (cand.Voted) {
                    remaining--;
                }
            });
            if (remaining < 0) { // Only allow invalid votes if Capture and Review. Disallow for Voting and ManagedVoting
                if ($scope.BallotType == 'Capture' || $scope.BallotType == 'Review') {
                    popups.confirmPopup('You have selected too many votes. You only have ' + port.NumVotes + ' ' + (port.NumVotes > 1 ? 'votes' : 'vote') + ' available. Do you want to mark the ballot as invalid?').then(function () {
                        callback(true, remaining); // Mark as invalid
                        $scope.ballot.Valid = false;
                        $scope.ballot.comments = 'Too many votes were selected';
                    }).catch(function () {
                        callback(false, null);
                    });
                } else {
                    popups.alertPopup('You have selected too many votes. You only have ' + port.NumVotes + ' ' + (port.NumVotes > 1 ? 'votes' : 'vote') + ' available');
                    callback(false, null);
                }
            } else if (remaining > 0) {
                popups.confirmPopup('There ' + (remaining > 1 ? 'are' : 'is') + ' ' + remaining + ' ' + (remaining > 1 ? 'votes' : 'vote') + ' remaining. Do you want to abstain the remaining ' + (remaining > 1 ? 'votes' : 'vote') + '?', 'Abstain Votes').then(function () {
                    callback(true, remaining);
                }).catch(function () {
                    callback(false, null);
                });
            } else {
                callback(true, null);
            }
        }

        // To scroll through each portfolio and add the votes
        $scope.selectVote = function (portfolio) {
            checkVotes(portfolio, function (proceed, remain) {
                if (proceed) {
                    portfolio.Abstains = remain;

                    if (inSummary) {
                        $scope.currentPortfolio = $scope.Candidates.length + 1;
                        $scope.showView = 'Summary';
                    } else {
                        $scope.currentPortfolio += 1;
                    }
                    if ($scope.currentPortfolio > $scope.Candidates.length) {
                        inSummary = true;
                        $scope.showView = 'Summary';
                    }
                }
            });
        };

        // To change the view to the specific portfolio
        $scope.changeVote = function (port) {
            $scope.showView = 'Details';
            $scope.currentPortfolio = port.PortNr;
        };

        // To submit the ballot back to the originating controller for further handling.
        $scope.submitBallot = function () {
            var newVotes = angular.copy($scope.Candidates);
            newVotes.forEach(function (port) {
                port.Candidates.forEach(function (cand) {
                    cand.Image = null;
                });
            });

            if ($scope.BallotType == 'Capture') { // If this is for capturing a ballot
                $rootScope.$emit('CreateCaptureBallotReturn', {
                    Candidates: newVotes,
                    validity: $scope.ballot
                });
            } else if ($scope.BallotType == 'Review') { // If this is for reviewing a ballot
                $rootScope.$emit('CreateReviewBallotReturn', {
                    Candidates: newVotes,
                    validity: $scope.ballot
                });
            } else if ($scope.BallotType == 'ManagedVoting') { // If this is for Managed Voting
                $rootScope.$emit('CreateManagedBallotReturn', {
                    Candidates: newVotes
                });
            }

            $scope.showView = null;
        };

        $scope.$on('$destroy', function() {
            deregister1();
            deregister2();
            deregister3();
            deregister4();
        });
    }

];