// Controller to add or edit voters on the roll

module.exports = ['$scope', '$http', 'params', function (scope, $http, params) {

    // NOTE: closeThisDialog() is injected to the scope by ngDialog

    scope.countries = [
        {
            Country: 'South Africa',
            Code: '+27',
            Territory: 'ZA'
        }, {
            Country: 'Lesotho',
            Code: '+266',
            Territory: 'LS'
        }, {
            Country: 'Botswana',
            Code: '+267',
            Territory: 'BW'
        }, {
            Country: 'Namibia',
            Code: '+264',
            Territory: 'NA'
        }, {
            Country: 'Zimbabwe',
            Code: '+263',
            Territory: 'ZW'
        }, {
            Country: 'Mozambique',
            Code: '+258',
            Territory: 'MZ'
        }, {
            Country: 'Swaziland',
            Code: '+268',
            Territory: 'SZ'
        }, {
            Country: 'Malawi',
            Code: '+265',
            Territory: 'MW'
        }
    ];

    // Create the variable or passed from the script
    if (params.voter) {
        scope.voter = params.voter;
        scope.countries.forEach(function (item) { // Lookup the territory to the list of countries available
            if (item.Territory === scope.voter.Territory || item.Country === scope.voter.Territory) {
                scope.voter.Territory = item;
            }
        });
    } else {
        scope.voter = {
            Name: '',
            Surname: '',
            Email: '',
            Mobile: '',
            Numb: '',
            Numb_2: '',
            Organisation: '',
            Territory: '',
            addFields: []
        };
    }

    scope.settings = params.settings;
    scope.isCreating = !(scope.voter && scope.voter.Id);
    scope.error = false;
    scope.errorMsg = null;
    scope.isProcessing = false;

    $http.get('/api/getPBIFields/' + params.sessionId).then(function (resp) {
        scope.addFields = resp.data;
        if (!scope.isCreating) {
            $http.get('/api/getVoterAddData/' + params.sessionId + '/' + scope.voter.Id).then(function (resp) {
                scope.voter.addFields = resp.data;
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    scope.errorMsg = err.data.message;
                }
            });
        } else {
            scope.addFields.forEach(function (field) {
                scope.voter.addFields[field] = '';
            });
        }
    }).catch(function (err) {
        if (err && err.data && err.data.message) {
            alert(err.data.message);
        }
        scope.closeThisDialog();
    });

    // validate the session input
    function validateInput() {
        scope.error = false;
        scope.errorMsg = '';
        var indexchar = scope.voter.Email.indexOf('@');
        if (scope.voter.Name.length < 2 || scope.voter.Surname.length < 2) {
            scope.error = true;
            scope.errorMsg = 'The name and surname cannot be blank or 1 character long';
            return false;
        } else if (indexchar == -1) {
            scope.error = true;
            scope.errorMsg = 'The email address is incorrect';
            return false;
        } else if (scope.voter.Email.substring(0, indexchar).length < 1 || scope.voter.Email.substring(indexchar + 1, scope.voter.Email.length).length < 1) {
            scope.error = true;
            scope.errorMsg = 'The email address is incorrect';
            return false;
        } else if (scope.voter.Organisation.length < 1) {
            scope.error = true;
            scope.errorMsg = 'The organisation cannot be empty';
            return false;
        } else if (scope.voter.Territory.length < 1) {
            scope.error = true;
            scope.errorMsg = 'The territory cannot be empty';
            return false;
        } else {
            return true;
        }
    }

    // Post the information to the database
    function PostVoter() {

        scope.isProcessing = true;

        var postData = scope.voter;
        postData.Territory = scope.voter.Territory.Territory;
        postData.newFields = scope.addFields;
        var url = '';
        if (scope.isCreating) { // Choose if we are creating a new session, or editing the current one
            url = '/api/votersroll/add/' + params.sessionId;
        } else {
            url = '/api/votersroll/edit/' + params.sessionId;
        }

        $http.post(url, postData)
            .then(function (resp) {
                scope.closeThisDialog();
            }).catch(function (err) {
                scope.error = true;
                if (err && err.data && err.data.message) {
                    scope.errorMsg = err.data.message;
                } else {
                    scope.errorMsg = 'Unknown error occurred';
                }
            }).finally(function () {
                scope.isProcessing = false;
            });
    }

    scope.saveVoter = function () {
        if (!validateInput()) // Validate the input fields
            return;

        if (scope.isProcessing) // If a previous submit is in progress
            return;

        PostVoter();
    };
}];