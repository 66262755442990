// Controller for the ballot capturing page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', '$timeout', function ($scope, $rootScope, $http, $state, popups, $stateParams, $timeout) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }
   
    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
            if (!$scope.SessionSettings.Capture_Enable) { // If vote capturing is not enabled, return to the sessions screen.
                popups.alertPopup('Vote Capturing is not enabled on this session', 'Capturing');
                $state.go('el.sessions');
                return;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // For Numbered Ballots
    // Test the ballot number whether it is on the list of issued ballot papers
    $scope.testnumber = function () {
        $scope.msg = null;
        if (!$scope.ballotNr || $scope.ballotNr == '') { // If the ballot number is not completed
            popups.alertPopup('Please enter a ballot number to continue', 'Ballot Number');
        } else { // If the ballot is within the allowed range
            $http.post('/api/testNumberCapture/' + sessionId, {
                ballot: $scope.ballotNr
            }).then(function (resp) {
                if (resp.data.status) { // If the capture will be valid
                    $scope.showCapture = false;
                    openBallot();
                    $scope.captureType = resp.data.captured == true ? 'Review' : 'Capture';
                    $scope.issuedBallot = resp.data.ballot; // The ballot that was issued
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Ballot Capture');
                    $scope.msg = err.data.message;
                }
            });
        }
    };

    // To filter the roll based on the search criteria
    function filterRoll() {
        $scope.filterVoters = [];
        if ($scope.fullRoll.length > 0) {
            $scope.fullRoll.forEach(function (voter) {
                if (voter.Name.toUpperCase().includes($scope.searchCriteria.Name.toUpperCase()) && voter.Surname.toUpperCase().includes($scope.searchCriteria.Surname.toUpperCase()) && voter.Email.toUpperCase().includes($scope.searchCriteria.Email.toUpperCase()) && (voter.Numb == null || voter.Numb.toUpperCase().includes($scope.searchCriteria.Numb.toUpperCase())) && (voter.Numb_2 == null || voter.Numb_2.toUpperCase().includes($scope.searchCriteria.Numb_2.toUpperCase()))) {
                    $scope.filterVoters.push(voter);
                }
            });
        }
    }

    // To search the voters roll interactively
    $scope.searchRoll = function () {
        var searchLength = $scope.searchCriteria.Name.length + $scope.searchCriteria.Surname.length + $scope.searchCriteria.Email.length + $scope.searchCriteria.Numb.length + $scope.searchCriteria.Numb_2.length;
        if (searchLength < 5 && $scope.fullRoll) { // If the voters roll has been downloaded, but the search criteria changed below five, remove the roll to start over
            $scope.fullRoll = null;
            $scope.filterVoters = [];
        } else if (searchLength >= 5 && !$scope.fullRoll && !$scope.gettingData) { // If the search criteria is above 5 characters, there is no current roll downloaded and the roll is not in the process of being downloaded, download the roll
            $scope.gettingData = true;
            $http.post('/api/getSearchRoll/' + sessionId, {
                Critera: $scope.searchCriteria
            }).then(function (resp) {
                $scope.fullRoll = resp.data;
                filterRoll();
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.gettingData = false;
            });
        } else if (searchLength >= 5 && !!$scope.fullRoll) { // If the search criteria is above 5 characters, but there is a roll, just filter the current roll.
            filterRoll();
        }
    };

    // Get the date in the correct format or return an empty string if the date is blank
    $scope.getDate = function (newDate) {
        if (newDate) {
            var returnDate = new Date(newDate).toLocaleString('en-ZA');
            return returnDate;
        } else {
            return '';
        }
    };

    $scope.clearCrit = function () {
        $scope.searchCriteria = {
            Name: '',
            Surname: '',
            Email: '',
            Numb: '',
            Numb_2: ''
        };
        $scope.fullRoll = null;
        $scope.filterVoters = [];
    };

    $scope.captureVoterBallot = function (voter) {
        $scope.ballotNr = voter.Id;
        $http.post('/api/testVoterCapture/' + sessionId, {
            voter: voter
        }).then(function (resp) {
            if (resp.data.status) { // If the capture will be valid
                $scope.showCapture = false;
                openBallot();
                $scope.captureType = resp.data.captured == true ? 'Review' : 'Capture';
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Ballot Capture');
                $scope.msg = err.data.message;
            }
        });
    };

    function openBallot() {
        $rootScope.$emit('CreateCaptureBallot', {
            Candidates: $scope.Candidates,
            SessionSettings: $scope.SessionSettings,
            ballotNr: $scope.ballotNr
        });
    }

    function reopenBallot() {
        $rootScope.$emit('CreateCaptureBallot', {
            Candidates: $scope.Candidates,
            SessionSettings: $scope.SessionSettings,
            ballotNr: $scope.ballotNr
        });
    }

    var deregister = $rootScope.$on('CreateCaptureBallotReturn', function (event, DataObj) {
        var captureVotes = DataObj.Candidates;
        var validity = DataObj.validity;
        $http.post('/api/sendBallotCapture/' + sessionId, {
            ballot: $scope.ballotNr,
            votes: captureVotes,
            validity: validity
        }).then(function (resp) {
            if (resp.data.status) { // If the capture was valid
                if (resp.data.type == 'Pending') {
                    popups.alertPopup('The ballot was successfully captured', 'Captured');
                } else if (resp.data.type == 'Agree') {
                    popups.alertPopup('The ballot was successfully reviewed', 'Review');
                } else if (resp.data.type == 'Dispute') {
                    popups.alertPopup('The ballot disagrees with the previous captured ballot. Please provide the ballot paper to a supervisor for review', 'Ballot Not Captured');
                } else if (resp.data.type == 'Invalid') {
                    popups.alertPopup('The ballot was seccessfully reviewed, but is invalid', 'Invalid Reviewed');
                }
                resetPage();
            } else {
                // TODO Return to the ballot with everything populated
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Ballot Capture');
                $scope.msg = err.data.message;
            }
        });
    });

    function resetPage() {
        $scope.msg = '';
        $scope.ballotNr = null;
        $scope.showCapture = true;
        $scope.captureType = 'Capture';
        $scope.searchCriteria = {
            Name: '',
            Surname: '',
            Email: '',
            Numb: '',
            Numb_2: ''
        };
        $scope.fullRoll = null;
        $scope.filterVoters = [];
        $scope.gettingData = false;
    }

    $scope.$on('$destroy', function () {
        deregister();
    });
    
    // Start the page
    getSessDetails();
    resetPage();

}];

// TODO Remove the starting and ending numbers from the settings
