// Controller for the results pages

module.exports = ['$rootScope', '$scope', '$http', '$state', 'popups', '$stateParams', '$window', '$interval', '$timeout', function ($rootScope, $scope, $http, $state, popups, $stateParams, $window, $interval, $timeout) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Scope variables
    $scope.msg = '';
    $scope.extRes1 = [];
    $scope.extRes2 = [];

    function getCandidates() {
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.Candidates = resp.data.Portfolios;
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
            $scope.Candidates.forEach(function (port) {
                var abstainCand = {
                    Id: '',
                    Name: 'Voting Abstains',
                    Party: '',
                    Votes: 0
                };
                port.Candidates.push(abstainCand);
            });
            $scope.getResults();
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    $scope.getResults = function () {
        $http.get('/api/getResults/' + sessionId).then(function (resp) {
            allocateVotes(resp.data);
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    };

    // Allocate the votes to the candidates
    function allocateVotes(votes) {
        $scope.Candidates.forEach(function (port) {
            port.Candidates.forEach(function (person) {
                person.Votes = 0;
                person.Online = 0;
                person.Captured = 0;
                votes.forEach(function (vote) {
                    if (vote.PortNr == port.PortNr && vote.Candidate == person.Id) {
                        person.Votes = person.Votes + vote.Votes;
                        if (vote.Status == 'Online') {
                            person.Online = vote.Votes;
                        } else if (vote.Status == 'Capture') {
                            person.Captured = vote.Votes;
                        }
                    }
                });
            });
        });
        calcExternal();
    }

    function calcPercent(callback) {
        $scope.Candidates.forEach(function (port) {
            var total = 0;
            port.Candidates.forEach(function (person) {
                if (person.Votes) {
                    total = total + person.Votes;
                }
            });
            port.Total = total;
        });
        callback(null);
    }

    function calcExternal() {
        calcPercent(function (non) {
            $scope.fullList = [];
            $scope.Candidates.forEach(function (port) {
                port.Candidates.forEach(function (person) {
                    var newperson = {
                        PortNr: port.PortNr,
                        Portfolio: port.Portfolio,
                        PortColour: port.PortColour,
                        TextColour: port.TextColour,
                        Id: person.Id,
                        Name: person.Name,
                        Party: person.Party,
                        Votes: person.Votes,
                        Online: person.Online,
                        Captured: person.Captured,
                        Perc: Math.round(person.Votes / port.Total * 10000) / 100
                    };
                    $scope.fullList.push(newperson);
                });
            });
            splitList();
        });
    }

    function splitList() {
        $scope.extRes1 = [];
        $scope.extRes2 = [];
        var middle = Math.round($scope.fullList.length / 2);
        for (var i = 0; i < middle; i++) {
            $scope.extRes1.push($scope.fullList[i]);
        }
        for (var j = middle; j < $scope.fullList.length; j++) {
            $scope.extRes2.push($scope.fullList[j]);
        }
    }

    function calcTotalVotes(callback) {
        var totalvotes = 0;
        $scope.Candidates.forEach(function (port) {
            totalvotes = totalvotes + port.NumVotes;
        });
        callback(totalvotes);
    }

    function getOrigRoll(callback) {
        $http.get('/api/getRollAdmin/' + sessionId + '/Original').then(function (resp) {
            $scope.rollInfo.Original = resp.data.RowNr;
            callback(true);
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    }

    function getSuppRoll(callback) {
        $http.get('/api/getRollAdmin/' + sessionId + '/Supplemental').then(function (resp) {
            $scope.rollInfo.Supplemental = resp.data.RowNr;
            callback(true);
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    }

    function getVotesCount(callback) {
        $http.get('/api/getVotesAdmin/' + sessionId).then(function (resp) {
            $scope.rollInfo.Votes = resp.data.RowNr;
            $scope.rollInfo.Percent = Math.round($scope.rollInfo.Votes / $scope.rollInfo.TotalRoll * 10000) / 100 || 0;
            calcTotalVotes(function (total) {
                $scope.rollInfo.Allocate = $scope.rollInfo.Votes * total;
                $scope.$apply();
                callback(true);
            });
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    }

    // Get the statistics for the print final
    function getStats() {
        $scope.rollInfo = {};
        getOrigRoll(function (cont) {
            getSuppRoll(function (cont) {
                $scope.rollInfo.TotalRoll = $scope.rollInfo.Original + $scope.rollInfo.Supplemental;
                getVotesCount(function (cont) {
                    $scope.rollInfo.TotalAllocated = 0;
                    $scope.fullList.forEach(function (cand) {
                        if (cand.Votes) {
                            $scope.rollInfo.TotalAllocated = $scope.rollInfo.TotalAllocated + cand.Votes;
                        }
                    });
                });
            });
        });
    }

    // To open the results page in a new window
    $scope.openExternal = function () {
        var url = $state.href('eom.resultsexternal', { sessionId: sessionId });
        $window.open(url, '_blank');
    };

    // To open the results in a printable page
    $scope.openFinal = function () {
        var url = $state.href('eom.resultsprint', { sessionId: sessionId });
        $window.open(url, '_blank');
    };

    // Set up a recurring function to get the data and update the view
    function startUpdates() {
        var intervalseconds = 30;
        $scope.getDataInterval = null;
        $scope.getDataInterval = $interval(function () {
            $scope.getResults();
        }, 1000 * intervalseconds);
    }

    function stopUpdates() {
        if ($scope.getDataInterval) {
            $interval.cancel($scope.getDataInterval);
        }
    }

    // Run the get scripts to setup the page
    getCandidates();

    if ($state.current.name == 'eom.resultsexternal') {
        $rootScope.cancelIdleTimer(); // Cancelling timer to ensure logout does not occur while monitoring is in progress.
        startUpdates();
    }

    if ($state.current.name == 'eom.resultsprint') {
        $timeout(function () {
            getStats();
        }, 1000);
    }

    // To stop the $interval function on destroying the element
    $scope.$on('$destroy', function () {
        stopUpdates();
        $rootScope.startIdleTimer();
    });

}];

