// Controller for the vote lookup page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', '$timeout', function ($scope, $rootScope, $http, $state, popups, $stateParams, $timeout) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }
   
    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // To withdraw the list of ballots for the selected category
    $scope.drawList = function () {
        if ($scope.captureType == null || $scope.captureType == '') { // If there is no list selected
            popups.alertPopup('Please select a list to withdraw', 'Manual ballots');
        } else {
            $scope.ballotList = null;
            $http.post('/api/getBallotList/' + sessionId, {
                list: $scope.captureType
            }).then(function (resp) {
                if (resp && resp.data && resp.data.list) { // If the list was withdrawn
                    $scope.ballotList = resp.data.list;
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Lookup Votes');
                }
            });
        }
    };

    function formatCaptured(results) {
        results.forEach(function (port) {
            var newPort = {};
            newPort.Nr = port.PortNr;
            newPort.Name = port.Portfolio;
            newPort.Results = [];
            port.Candidates.forEach(function (cand) {
                if (cand.Voted) {
                    newPort.Results.push(cand.Name);
                }
            });
            for (let i = 0; i < port.Abstains; i++) {
                newPort.Results.push('Abstain');
            }
            $scope.Results.push(newPort);
        });
    }

    function formatOnline(results) {
        $scope.Candidates.forEach(function (portfolio) {
            var newPort = {};
            newPort.Nr = portfolio.PortNr;
            newPort.Name = portfolio.Portfolio;
            newPort.Results = [];
            portfolio.Candidates.forEach(function (cand) {
                results.forEach(function (res) {
                    if (res.PortNr == portfolio.PortNr && res.Candidate == cand.Id) {
                        newPort.Results.push(cand.Name);
                    }
                });
            });
            results.forEach(function (res) {
                if (res.PortNr == portfolio.PortNr && res.Candidate == 0) {
                    newPort.Results.push('Abstain');
                }
            });
            $scope.Results.push(newPort);
        });
    }

    // User selects a ballot and the results are displayed
    $scope.drawBallotResults = function (ballot) {
        popups.confirmPopup('Are you sure you want to draw the voting results of this ballot. This action will be logged', 'Voter lookup').then(function () {
            $scope.reviewBallot = ballot;
            $http.post('/api/getVoterResults/' + sessionId, {
                list: $scope.captureType,
                ballot: ballot,
                type: 'manual'
            }).then(function (resp) {
                if (resp && resp.data && resp.data.results) { // If the list was withdrawn
                    $scope.Results = [];
                    var results = null;
                    if ($scope.captureType == 'Capture_Removed' && ballot.Source == 'Online') { // If the results are in the online votes format
                        results = JSON.parse(resp.data.results);
                        formatOnline(results);
                    } else {
                        results = JSON.parse(resp.data.results);
                        formatCaptured(results);
                    }
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Lookup Votes');
                }
            });
        });
    };

    // To filter the roll based on the search criteria
    function filterRoll() {
        $scope.filterVoters = [];
        if ($scope.fullRoll.length > 0) {
            $scope.fullRoll.forEach(function (voter) {
                if (voter.Name.toUpperCase().includes($scope.searchCriteria.Name.toUpperCase()) && voter.Surname.toUpperCase().includes($scope.searchCriteria.Surname.toUpperCase()) && voter.Email.toUpperCase().includes($scope.searchCriteria.Email.toUpperCase()) && (voter.Numb == null || voter.Numb.toUpperCase().includes($scope.searchCriteria.Numb.toUpperCase())) && (voter.Numb_2 == null || voter.Numb_2.toUpperCase().includes($scope.searchCriteria.Numb_2.toUpperCase()))) {
                    $scope.filterVoters.push(voter);
                }
            });
        }
    }

    // To search the voters roll interactively
    $scope.searchRoll = function () {
        var searchLength = $scope.searchCriteria.Name.length + $scope.searchCriteria.Surname.length + $scope.searchCriteria.Email.length + $scope.searchCriteria.Numb.length + $scope.searchCriteria.Numb_2.length;
        if (searchLength < 5 && $scope.fullRoll) { // If the voters roll has been downloaded, but the search criteria changed below five, remove the roll to start over
            $scope.fullRoll = null;
            $scope.filterVoters = [];
        } else if (searchLength >= 5 && !$scope.fullRoll && !$scope.gettingData) { // If the search criteria is above 5 characters, there is no current roll downloaded and the roll is not in the process of being downloaded, download the roll
            $scope.gettingData = true;
            $http.post('/api/getSearchRoll/' + sessionId, {
                Critera: $scope.searchCriteria
            }).then(function (resp) {
                $scope.fullRoll = resp.data;
                filterRoll();
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.gettingData = false;
            });
        } else if (searchLength >= 5 && !!$scope.fullRoll) { // If the search criteria is above 5 characters, but there is a roll, just filter the current roll.
            filterRoll();
        }
    };

    // Get the date in the correct format or return an empty string if the date is blank
    $scope.getDate = function (newDate) {
        if (newDate) {
            var returnDate = new Date(newDate).toLocaleString('en-ZA');
            return returnDate;
        } else {
            return '';
        }
    };

    // User selects a ballot and the results are displayed
    $scope.drawOnlineResults = function (voter) {
        popups.confirmPopup('Are you sure you want to draw the voting results of this voter. This action will be logged', 'Voter lookup').then(function () {
            $scope.reviewBallot = voter;
            $http.post('/api/getVoterResults/' + sessionId, {
                voterId: voter.Id,
                type: 'online'
            }).then(function (resp) {
                if (resp && resp.data && resp.data.results) { // If the list was withdrawn
                    $scope.Results = [];
                    var results = resp.data.results;
                    formatOnline(results);
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Lookup Votes');
                }
            });
        });
    };












    // To reset the page to the start
    $scope.resetPage = function () {
        $scope.msg = '';
        $scope.showReview = 'Summary';
        $scope.captureType = null;
        $scope.ballotList = null;
        $scope.Results = null;
        $scope.reviewBallot = null;
        $scope.searchCriteria = {
            Name: '',
            Surname: '',
            Email: '',
            Numb: '',
            Numb_2: ''
        };
        $scope.fullRoll = null;
        $scope.filterVoters = [];
        $scope.gettingData = false;
    };


    // Start the page
    getSessDetails();
    $scope.resetPage();

}];