// User management page controller
module.exports = ['$scope', '$rootScope', '$http', '$stateParams', 'ngDialog', '$state', 'popups', function ($scope, $rootScope, $http, $stateParams, ngDialog, $state, popups) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }


    // Initiate variables
    $scope.Users = [];
    $scope.filterusers = [];

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    };

    // Get all the users from the database
    function getAllUsers () {
        $scope.emailinput = '';
        $http.get('/users/getAllUsers/' + sessionId).then(function (resp) {
            $scope.Users = resp.data;
            $scope.Users.forEach(function (user) {
                user.AuditLog.forEach(function (log) {
                    var logdate = log.date;
                    log.date = new Date(logdate).toLocaleString();
                });
            });
            $scope.filterEmails();
        }).catch(function (err) {
            if (err.status === 403) {
                popups.alertPopup("You don't have enough privilege to access the user data.", "Error");
                $state.go('el.sessions', null, { reload: true });
            } else {
                $scope.msg = err;
                popups.alertPopup("There was an error while fetching users data. Please try refresh the page.", "Error");
            }
        });
    }

    // Filter the emails based on the input box
    $scope.filterEmails = function () {
        $scope.filterusers = [];
        $scope.Users.forEach(function (user) {
            if ($scope.emailinput.length === 0) {
                $scope.filterusers = $scope.Users;
            } else {
                if (user.Email.toUpperCase().includes($scope.emailinput.toUpperCase())) {
                    $scope.filterusers.push(user);
                }
            }
        });
    };

    // Dialog to create or edit a user
    $scope.editUserDialog = function (user) {
        ngDialog.open({
            template: require('../tpl/user-edit-dlg.html'),
            plain: true,
            controller: 'userEdit',
            resolve: {
                'params': function () {
                    if (user) {
                        return {
                            'users': $scope.Users,
                            'user': user,
                            'type': 'Session',
                            'session': sessionId
                        };
                    } else {
                        return {
                            'users': $scope.Users,
                            'type': 'Session',
                            'session': sessionId
                        };
                    }
                }
            },
            preCloseCallback: function () {
                getAllUsers();
            },
            showClose: false,
            closeByDocument: false
        });
    };

    // Test if the user has a non-pwc mail address
    $scope.isExternal = function (user) {
        var indexchar = user.Email.indexOf('pwc.com');
        if (indexchar === -1) {
            return true;
        } else {
            return false;
        }
    };

    // To call the password reset for external users
    $scope.resetPassword = function (user) {
        popups.confirmPopup("Are you sure you want to reset this user's password?", user.Name).then(function () {
            $http.post('/users/change_password/' + sessionId, {
                email: user.Email
            }).then(function (resp) {
                if (resp.data && resp.data.status) {
                    popups.alertPopup('The password has been reset', user.Name);
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later.', user.Name);
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, user.Name);
                }
            });
        });
    };

    // Confirmation to activate or deactivate a user
    $scope.deactiveUser = function (user) {
        var message = user.pwdLock ? 'Do you want to activate this user?' : 'Do you want to deactivate this user?';
        popups.confirmPopup(message).then(function () {
            $http.post('/users/ActivateUser', {
                id: user.Id,
                email:user.Email,
                activate: user.pwdLock ? 0 : 1,
                type: 'Session',
                sessionId: sessionId
            }).then(function (resp) {
                if (resp.data && !resp.data.status) {
                    popups.alertPopup('An unknown error has occurred', 'User Activation');
                }
                getAllUsers();
            }).catch(function (err) {
                if (err.data && err.data.message)
                    alert(err.data.message);
            });
        });
    };

    // Dialog to change the user access for the specified user
    $scope.changeUserAccess = function (user) {
        ngDialog.open({
            template: require('../tpl/edit-access-dlg.html'),
            plain: true,
            controller: 'editAccess',
            resolve: {
                'params': function () {
                    return {
                        'user': user,
                        'sessionId': sessionId,
                        'sessionName': $scope.SessionSettings.Name
                    };
                }
            },
            showClose: false,
            closeByDocument: false
        });
    };

    // Run upon loading the page. This will get the data and set up the form.
    getSessDetails();
    getAllUsers();
}];


