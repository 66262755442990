var angular = require('angular');

angular.module('Elections')
    .directive('directive', require('./draggable'))
    .directive('modalMovable', require('./modal-movable'))
    .directive('uiModule', require('./ui-module'))
    .directive('uiToggleGhPanel', function () {
        return {
            restrict: 'A',
            link: function (scope, elem, attr) {
                $(elem).on('click', function () {
                    if ($('body').hasClass('glb-nav-active')) {
                        $('#dropdownTrigger_lg').trigger('click');
                    }
                });
            }
        };
    })
    .directive('backBtn', ['$window', function ($window) {
        return {
            restrict: 'A',
            link: function (scope, elem, attrs) {
                elem.bind('click', function () {
                    $window.history.back();
                });
            }
        };
    }]);