// Controller for the voting statistics pages

module.exports = ['$rootScope', '$scope', '$http', '$state', 'popups', '$stateParams', '$window', '$interval', 'appState', function ($rootScope, $scope, $http, $state, popups, $stateParams, $window, $interval, appState) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Scope variables
    $scope.msg = '';
    $scope.rollInfo = {};
    $scope.globalQueries = [];
    $scope.sessionQueries = [];
    $scope.voteDiff = null;
    $scope.notMarked = null;
    $scope.globalFailures = null;
    $scope.sessionFailures = null;

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    function getOrigRoll(callback) {
        $http.get('/api/getRollAdmin/' + sessionId + '/Original').then(function (resp) {
            $scope.rollInfo.Original = resp.data.RowNr;
            callback(true);
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    }

    function getSuppRoll(callback) {
        $http.get('/api/getRollAdmin/' + sessionId + '/Supplemental').then(function (resp) {
            $scope.rollInfo.Supplemental = resp.data.RowNr;
            $scope.rollInfo.TotalRoll = $scope.rollInfo.Original + $scope.rollInfo.Supplemental;
            callback(true);
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    }

    function getVotes() {
        $http.get('/api/getVotesAdmin/' + sessionId).then(function (resp) {
            $scope.rollInfo.Votes = resp.data.RowNr;
            $scope.rollInfo.Percent = Math.round($scope.rollInfo.Votes / $scope.rollInfo.TotalRoll * 10000) / 100 || 0;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    }

    // Get the number of users on the voters rolls as well as the votes cast on the database for display as well as to create the Voted chart
    function getVotersandVotes () {
        getOrigRoll(function (cont) {
            getSuppRoll(function (cont) {
                getVotes();
            });
        });
    }

    $scope.countQueries = function (type) {
        var count = 0;
        $scope.sessionQueries.forEach(function (query) {
            if ((type == 'Current' && (query.CurrentStatus == 'Created' || query.CurrentStatus == 'In Progress' || query.CurrentStatus == 'Unapproved')) || (type == 'On Hold' && query.CurrentStatus == 'On Hold')) {
                count++;
            }
        });
        return count;
    };

    function getQueries() {
        $scope.msg = '';
        $http.get('/api/getQueries/' + sessionId, null).then(function (resp) {
            $scope.globalQueries = resp.data.global;
            $scope.sessionQueries = resp.data.session;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    $scope.countFailures = function (type) {
        var count = 0;
        if (type == 'Global') {
            if ($scope.globalFailures) {
                $scope.globalFailures.forEach(function (failure) {
                    if (failure.CurrentStatus != 'Complete') {
                        count++;
                    }
                });
            }
        } else if (type == 'Session') {
            if ($scope.sessionFailures) {
                $scope.sessionFailures.forEach(function (failure) {
                    if (failure.CurrentStatus != 'Complete') {
                        count++;
                    }
                });
            }
        }
        return count;
    };

    function getIntegrityData() {
        $scope.voteDiff = null;
        $scope.notMarked = null;
        $http.get('/api/getIntegrityExceptions/' + sessionId).then(function (resp) {
            $scope.voteDiff = resp.data.voteDiff;
            $scope.notMarked = resp.data.notMarked;
            $scope.globalFailures = resp.data.gumFailures.global;
            $scope.sessionFailures = resp.data.gumFailures.session;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // Set up a recurring function to get the data and update the view
    function startUpdates() {
        var intervalseconds = 30;
        $scope.getDataInterval = null;
        $scope.getDataInterval = $interval(function () {
            getVotersandVotes();
            getQueries();
            getIntegrityData();
        }, 1000 * intervalseconds);
    }

    // Stop the automatic updates
    function stopUpdates() {
        if ($scope.getDataInterval) {
            $interval.cancel($scope.getDataInterval);
        }
    }

    // On startup
    getSessDetails();
    getVotersandVotes();
    getQueries();
    getIntegrityData();
    startUpdates();
    $rootScope.cancelIdleTimer(); // Cancelling timer to ensure logout does not occur while monitoring is in progress.

    // To stop the $interval function on destroying the element
    $scope.$on('$destroy', function () {
        stopUpdates();
        $rootScope.startIdleTimer();
    });
}];

