// Login controller
module.exports = ['$rootScope', '$scope', '$http', '$cookies', 'appState', '$state', '$stateParams', function ($rootScope, $scope, $http, $cookies, appState, $state, $stateParams) {
    $scope.submitting = false;

    // Clear the appState and display any error there might be
    appState.$reset();
    $scope.msg = $stateParams.err || null;
    $scope.csrfToken = $cookies.get('XSRF-TOKEN');

    // Post to the server if the access token is received
    if ($stateParams.code) {
        $http.post(apiUrl + '/oauth/auth', {
            code: $stateParams.code,
            state: $stateParams.state
        }).then(function (resp) {
            if (resp.data.jwt_token) {
                $http.post(apiUrl + '/users/me', {
                    token: resp.data.jwt_token
                }).then(function (resp) {
                    var data = resp.data;
                    if (data.userdata) {
                        appState.token = data.userdata.token;
                        appState.user = data.userdata.user;
                        appState.userId = data.userdata.user.id || null;
                        appState.isAdmin = data.userdata.user.isAdmin || 0;
                        appState.access = data.userdata.access || null;
                        appState.voterdata = data.voterdata || null;
                        appState.nomdata = data.nomdata || null;
                    } else {
                        appState.token = data.votertoken;
                        appState.user = data.voterdata[0] || data.nomdata[0];
                        appState.userId = null;
                        appState.isAdmin = 0;
                        appState.access = null;
                        appState.voterdata = data.voterdata || null;
                        appState.nomdata = data.nomdata || null;
                    }
                    $rootScope.$broadcast('el.login');
                    // Redirect to the home screen
                    redirectToUserLanding();
                }).catch(function (err) {
                    $state.go('elo.login', {
                        err: 'Invalid Token!'
                    }, {
                            reload: true
                        }
                    );
                });
            }
        }).catch(function (err) {
            if (err.data && err.data.message) {
                $state.go('elo.login', {
                    err: err.data.message
                }, {
                        reload: true
                    }
                );
            }
            // Handle the error
        });
    } else {
        $state.go('elo.login', {
            err: 'Failed to authenticate'
        }, {
                reload: true
            }
        );
    }

    // To redirect to the correct landing page, depending on variables. Currently, all users redirect to the sessions page. A split can be made here.
    function redirectToUserLanding() {
        $state.go('el.sessions', {
            reload: true
        });
    }

}];