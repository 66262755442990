// Controller to manage the voter selection screen

module.exports = ['$scope', 'params', function (scope, params) {

    // NOTE: closeThisDialog() is injected to the scope by ngDialog

    // Set up the initial parameters
    var sessionId = null;
    if (params) {
        scope.type = params.type;
        scope.SessionSettings = params.sessionSettings;
        sessionId = params.sessionId;
        scope.Proxy = params.proxy;
        scope.ProxySettings = params.proxySettings;
    }
    scope.errorMsg = null;
    window.scroll(0, 0);

    // Convert the date field to a readable format and the comments to a list
    scope.ProxySettings.AddFields.forEach(function (field) {
        if (field.Type == 'DATE') {
            scope.Proxy.AddFields[field.Id] = new Date(scope.Proxy.AddFields[field.Id]);
        }
    });
    scope.Proxy.Comments = JSON.parse(scope.Proxy.Comments);

    scope.submitChoice = function (type) {
        scope.closeThisDialog(type);
    };

}];