// Controller for the proxy capturing and reviewing page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', '$timeout', 'Upload', function ($scope, $rootScope, $http, $state, popups, $stateParams, $timeout, Upload) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Set up the top variables
    $scope.msg = null;
    $scope.importProcessing = null;

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
        $http.get('/api/getProxySettings/' + sessionId, null).then(function (resp) {
            if (resp.data && resp.data.status) {
                $scope.ProxySettings = resp.data.ProxySettings;
                $scope.ProxySettings.AddFields.forEach(function (field) {
                    field.changed = false;
                    if (field.Type == 'DATE' && field.Validation != null && field.Validation != '') { // Parse the dates in the validation
                        field.Validation = JSON.parse(field.Validation);
                        field.Validation.min = new Date(field.Validation.min);
                        field.Validation.max = new Date(field.Validation.max);
                    } else if (field.Validation == 1) {
                        field.Validation = true;
                    }
                });
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // Upload the selected file to the server. The file is returned in an object converted manner for allocation.
    $scope.uploadfile = function (file) {
        $scope.msg = '';
        $scope.importData = null;
        $scope.errorData = [];
        $scope.rollMapping = {};
        $scope.submittingFile = 'Unavailable';
        if (file) {
            $scope.importProcessing = 'Sending to server...';
            Upload.upload({
                url: '/api/uploadProxyfile/' + sessionId,
                file: file
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    $scope.importData = resp.data.result;
                    $scope.importKeys = Object.keys($scope.importData[0]);
                    testKeys($scope.importKeys, function (test) {
                        if (!test) {
                            popups.alertPopup('The fields cannot be blank. Please check the import data', 'Error');
                            $scope.importData = null;
                            $scope.importKeys = null;
                        }
                    });
                    $scope.rollMapping = {
                        Batch: 'batch',
                        Position: 'position',
                        Number: 'proxy',
                        Voter: 'voter',
                        Holder: 'holder',
                        AddFields: {
                            1: 'signed',
                            2: 'date'
                        }
                    }; // TODO Remove
                    $scope.testProxyChange();
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.importProcessing = null;
            });
        }
    };

    // Test if any of the keys are empty
    function testKeys(keys, callback) {
        var empty = true;
        var process = 0;
        keys.forEach(function (key) {
            if (!key || key == '') {
                empty = false;
            }
            process++;
            if (process >= keys.length) {
                callback(empty);
            }
        });
    }

    // Ensure that all the required fields are mapped before allowing the import.
    $scope.testProxyChange = function () {
        if ($scope.submittingFile !== 'Validating') {
            $scope.submittingFile = 'Unavailable';
            if ($scope.rollMapping.Batch != null && $scope.rollMapping.Position != null && $scope.rollMapping.Number != null && $scope.rollMapping.Voter != null && $scope.rollMapping.Holder != null ) { // Test that all of the fields should have values
                $scope.submittingFile = 'Import File';
            }
        }
    };

    // Post the imported proxies to the database
    $scope.submitFile = function () {
        $scope.submittingFile = 'Validating';

        popups.confirmPopup('Are you sure you want to submit the list of proxies?', 'Submit Proxies').then(function () {
            $scope.submittingFile = 'Submitting';
            $scope.importProcessing = 'Submitting to server...';
            $scope.msg = '';
            $http.post('/api/submitProxies/' + sessionId, {
                Data: $scope.importData, // Send the dataset to the server for import
                Mapping: $scope.rollMapping
            }).then(function (resp) {
                if (resp.data.status) { // If the status is true, the import was successful
                    var newrows = resp.data.Rows;
                    popups.alertPopup('The number of proxies imported was: ' + newrows);
                    $scope.importProcessing = null;
                    $scope.importData = null;
                    $scope.submittingFile = 'Unavailable';
                } else { // Data errors were noted
                    if (resp.data.Data) {
                        $scope.errorData = resp.data.Data;
                        popups.alertPopup('Some errors were noted in the data to be imported. Please correct this and import the file again.', 'Error');
                        $scope.importProcessing = 'Please Correct Data First';
                    } else {
                        popups.alertPopup('An unknown error has occurred. Please contact your administrator');
                    }
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                    $scope.importProcessing = null;
                    $scope.importData = null;
                    $scope.submittingFile = 'Unavailable';
                }
            });
        }).finally(function () {
            $scope.submittingFile = 'Import Roll';
        });
    };






    // Start the page
    getSessDetails();

}];



