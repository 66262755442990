// Controller for the results pages

module.exports = ['$scope', '$stateParams', '$timeout', function ($scope, $stateParams, $timeout) {

    $scope.Print = JSON.parse(decodeURI($stateParams.data));

    $scope.Type = typeof($scope.Print.Description);

    $timeout(function () {
        window.print();
        window.close();
    }, 500); // TODO This will not work in IE, as it closes the page immediately

}];

