// popups service
module.exports = ['$q', 'ngDialog', function ($q, ngDialog) {

	// Asks to confirm a specific request
	this.confirmPopup = function confirmPopup(message, heading) {
		var deferred = $q.defer();
		ngDialog.open({
			template: require('../tpl/confirm-dlg.html'),
			plain: true,
			controller: ['$scope', function (scope) {
				scope.message = message;
				scope.heading = heading || null;

				scope.yes = function () {
					deferred.resolve();
					scope.closeThisDialog();
				};

				scope.no = function () {
					deferred.reject();
					scope.closeThisDialog();
				};
			}],
			showClose: false,
			closeByDocument: false
		});
		return deferred.promise;
    };

    // Shows various alerts on request
	this.alertPopup = function alertPopup(message, heading) {
		var deferred = $q.defer();
		ngDialog.open({
			template: require('../tpl/alert-dlg.html'),
			plain: true,
			controller: ['$scope', function (scope) {
				scope.message = message;
				scope.heading = heading || null;

				scope.yes = function () {
					deferred.resolve();
					scope.closeThisDialog();
				};
			}],
			showClose: false,
			closeByDocument: false
		});
		return deferred.promise;
	};
}];