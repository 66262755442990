var _ = require('lodash');

module.exports = ['$rootScope', '$localStorage',
    function ($rootScope, $localStorage) {

        // when user detail of the current user changes, update the appState's data.
        $rootScope.$on('el.data.user.$update', function (evt, u) {
            if (u.id == $localStorage.user.id) {
                var u1 = _.extend({}, $localStorage.user, u);
                $localStorage.user = u1;
            }
        });


        return $localStorage;
    }
];