// Navbar controller
module.exports = ['$rootScope', '$scope', '$state', '$http', 'appState', '$cookies', 'popups', 'ngDialog', function ($rootScope, $scope, $state, $http, appState, $cookies, popups, ngDialog) {

    // scope variables
    $scope.isAdmin = appState.isAdmin;
    $scope.Stations = [];
    var userAccess = appState.access;
    $scope.username = appState.user.email || appState.user.Email;
    $scope.showSub = null;

    // events hook up
    var cleanup1 = $scope.$on('el.sessions.$changed', function (evt) {
        reloadpage();
    });

    var cleanup2 = $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        $scope.isSessionOpened = /^el\.session\./.test(toState.name || '');
        $scope.openedSessionId = $scope.isSessionOpened && toParams.sessionId;
        if ($scope.openedSessionId) {
            $scope.openedSession = mySessions.getLocalSession($scope.openedSessionId);
        }
    });

    $scope.$on('$destroy', function (evt) {
        cleanup1();
        cleanup2();
    });

    // Get the list of sessions to include in the navbar
    $scope.getSessions = function () {
        $http.get('/api/sessions').then(function (value) {
            $scope.Stations = value.data;
        }).catch(function (err) {
            if (err.data && err.data.message)
                alert(err.data.message);
        });
    };

    // Determines whether a person has access to a specific function.
    // This is called various times during loading on the client computer.
    $scope.hasAccess = function (station, type) {
        var found = false;
        if ($scope.isAdmin) {
            return true;
        } else {
            userAccess.forEach(function (access) {
                type.forEach(function (item) {
                    if (access.Session === station.Id && access[item] === 1) {
                        found = true;
                    }
                });
            });
        }
        return found;
    };

    // Function to keep the updates together
    function reloadpage() {
        $scope.getSessions();
        $scope.showSub = null;
    }

    // Initialise
    reloadpage();

}];

