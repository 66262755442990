// Login controller
module.exports = ['$scope', '$cookies', 'appState', '$stateParams', function ($scope, $cookies, appState, $stateParams) {
    $scope.submitting = false;
    $scope.Accepted = false;
    $scope.loggingIn = false;

    // Clear the appState and display any error there might be
    appState.$reset();
    var errors = $stateParams.err || '';
    if (errors === 'Registered' || errors[0] === "Registered" || errors[1] === "Registered") {
        $scope.Registration = true;
    } else {
        $scope.Registration = false;
    }
    $scope.msg = $stateParams.err || '';
    $scope.csrfToken = $cookies.get('XSRF-TOKEN');

    $scope.login = function () {
        $scope.loggingIn = true;
    };

}];