// Controller for the account management page
module.exports = ['$rootScope', '$scope', '$http', '$cookies', '$state', 'appState', '$stateParams', 'popups', function ($rootScope, $scope, $http, $cookies, $state, appState, $stateParams, popups) {
    $scope.csrfToken = $cookies.get('XSRF-TOKEN');
    $scope.Email = null;
    $scope.Code1 = null;
    $scope.Code2 = null;
    $scope.GUID = null;
    $scope.status = 'Email';
    $scope.msg = null;
    $scope.submittingEmail = false;
    $scope.submittingCode = false;
    $scope.Instructions1 = "This page is only to be used to login with the assistance of an administrator. Users will not be able to log in or create queries from this view. Normal logins and creating queries should be done from the normal landing page.";

    // Submit the email address to test for validity
    $scope.submitEmail = function () {
        $scope.msg = null;
        if (!$scope.Email || $scope.Email.length < 1) { // If the email is empty
            $scope.msg = 'Please enter an email address to continue';
        } else {
            $scope.submittingEmail = true;
            $http.post('/altLogin/submitEmail', { // Submit the email address
                email: $scope.Email
            }).then(function (resp) {
                if (resp.data && resp.data.status) { // If a valid email address was entered
                    $scope.GUID = resp.data.guid;
                    $scope.status = 'Code1';
                } else { // If an invalid email address was entered, route to the account creation page
                    $state.go('elo.acc-create', {
                        email: $scope.Email
                    }, {
                            reload: true
                        }
                    );
                }
            }).catch(function (err) {
                if (err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                $scope.submittingEmail = false;
            });
        }
    };

    // Submit the code entered
    $scope.submitCode = function (type) {
        $scope.msg = null;
        if ((type == 1 && (!$scope.Code1 || $scope.Code1.length < 1)) || (type == 2 && (!$scope.Code2 || $scope.Code2.length < 1))) {
            $scope.msg = 'Please enter a code to continue';
        } else {
            $scope.submittingCode = true;
            $http.post('/altLogin/submitCode', { // Submit the code to the server
                email: $scope.Email,
                guid: $scope.GUID,
                type: type,
                code: type == 1 ? $scope.Code1 : $scope.Code2
            }).then(function (resp) {
                if (resp.data && resp.data.status) { // If the code was accepted
                    if (type == 1) {
                        $scope.status = 'Code2';
                    } else {
                        $http.post(apiUrl + '/users/me', {
                            token: resp.data.token
                        }).then(function (resp) {
                            var data = resp.data;
                            if (data.userdata) {
                                appState.token = data.userdata.token;
                                appState.user = data.userdata.user;
                                appState.userId = data.userdata.user.id || null;
                                appState.isAdmin = data.userdata.user.isAdmin || 0;
                                appState.access = data.userdata.access || null;
                                appState.voterdata = data.voterdata || null;
                                appState.nomdata = data.nomdata || null;
                            } else {
                                appState.token = data.votertoken;
                                appState.user = data.voterdata[0] || data.nomdata[0];
                                appState.userId = null;
                                appState.isAdmin = 0;
                                appState.access = null;
                                appState.voterdata = data.voterdata || null;
                                appState.nomdata = data.nomdata || null;
                            }
                            $rootScope.$broadcast('el.login');
                            // Redirect to the home screen
                            redirectToUserLanding();
                        }).catch(function (err) {
                            $state.go('elo.login', {
                                err: 'Invalid Token!'
                            }, {
                                    reload: true
                                }
                            );
                        });
                    }
                } else {
                    $scope.msg = 'An unknown error has occurred. Please submit the code again';
                }
            }).catch(function (err) {
                if (err.data && err.data.message) {
                    $scope.msg = err.data.message;
                    $scope.Email = null;
                    $scope.Code1 = null;
                    $scope.Code2 = null;
                    $scope.GUID = null;
                    $scope.status = 'Email';
                }
            }).finally(function () {
                $scope.submittingCode = false;
            });
        }
    };

    // To redirect to the correct landing page, depending on variables. Currently, all users redirect to the sessions page. A split can be made here.
    function redirectToUserLanding() {
        $state.go('el.sessions', {
            reload: true
        });
    }
}];