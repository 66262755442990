// Controller for the meeting registrations management page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', 'appState', '$window', 'ngDialog', function ($scope, $rootScope, $http, $state, popups, $stateParams, appState, $window, ngDialog) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
        $http.get('/api/getProxySettings/' + sessionId, null).then(function (resp) {
            if (resp.data && resp.data.status) {
                $scope.ProxySettings = resp.data.ProxySettings;
                $scope.ProxySettings.Comment_Options.push({ name: 'Other', disqualified: false });
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // Draw the meeting statistics
    $scope.drawMeetingStats = function () {
        $scope.msg = null;
        $http.get('/api/getMeetingStats/' + sessionId, null).then(function (resp) {
            if (resp && resp.data && resp.data.status) {
                $scope.meetingStats = resp.data.stats;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    };

    // Draw the ballot statistics
    $scope.drawBallotStats = function () {
        $scope.msg = null;
        $http.get('/api/getBallotStats/' + sessionId, null).then(function (resp) {
            if (resp && resp.data && resp.data.status) {
                $scope.ballotStats = resp.data.stats;
                $scope.ballotExceptions = resp.data.exceptions;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    };

    // Download the meeting register
    $scope.downloadRegister = function () {
        window.open(apiUrl + '/api/getMeetingExcel/' + sessionId + '/exportxls?token=' + appState.token);
    };

    // Download the ballots that were issued
    $scope.downloadBallots = function () {
        window.open(apiUrl + '/api/getBallotsExcel/' + sessionId + '/exportxls?token=' + appState.token);
    };

    // To get the current list of ballots for a voter
    $scope.getBallots = function (voter) {
        $http.post('/api/testVotingRegistration/' + sessionId, {
            voter: voter
        }).then(function (resp) {
            $scope.ballotIssuing = true;
            $scope.issuedBallots = resp.data.ballots;
            var remaining = voter.Total;
            var total = 0;
            $scope.issuedBallots.forEach(function (ballot) {
                if (ballot.Active == 1) {
                    total = total + ballot.Weight;
                }
                ballot.Changed = false;
            });
            $scope.currentVoter.Allocated = total;
            $scope.currentVoter.Remain = remaining - total;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Voting Management');
                $scope.msg = err.data.message;
            }
        });
    };

    // To select the member to register
    $scope.resolveException = function (exception) {
        $scope.currentVoter = {
            Id: exception.Voter,
            Total: exception.Total
        };
        $scope.getBallots($scope.currentVoter);
    };

    $scope.saveChange = function (ballot) {
        $scope.msg = null;
        $http.post('/api/saveBallotWeightChange/' + sessionId, {
            ballot: ballot
        }).then(function (resp) {
            if (resp && resp.data && resp.data.status) {
                $scope.getBallots($scope.currentVoter);
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Voting Management');
                $scope.msg = err.data.message;
            }
        });
    };

    // Reset the page to the menu
    function resetPage() {
        $scope.msg = null;
        $scope.meetingStats = [];
        $scope.ballotExceptions = [];
        $scope.ballotIssuing = false;
        $scope.drawMeetingStats();
        $scope.drawBallotStats();
    }

    // Start the page
    getSessDetails();
    resetPage();

}];


// TODO Reprint non-member certificate