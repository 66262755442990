// Controller for the proxy capturing and reviewing page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', '$timeout', function ($scope, $rootScope, $http, $state, popups, $stateParams, $timeout) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Set up the top variables
    $scope.newComment = null;
    $scope.newDisqual = false;
    $scope.newField = {
        Name: '',
        Type: null,
        Validation: null,
        Error: null
    };

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
        $http.get('/api/getProxySettings/' + sessionId, null).then(function (resp) {
            if (resp.data && resp.data.status) {
                $scope.ProxySettings = resp.data.ProxySettings;
                $scope.ProxySettings.AddFields.forEach(function (field) {
                    field.changed = false;
                    if (field.Type == 'DATE' && field.Validation != null && field.Validation != '') { // Parse the dates in the validation
                        field.Validation = JSON.parse(field.Validation);
                        field.Validation.min = new Date(field.Validation.min);
                        field.Validation.max = new Date(field.Validation.max);
                    } else if (field.Validation == 1) {
                        field.Validation = true;
                    }
                });
                $scope.changed = false;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // To flag the settings as changed
    $scope.changeItem = function (comment) {
        $scope.changed = true;
    };

    // Add a new comment to the list
    $scope.AddComment = function () {
        if (!$scope.newComment || $scope.newComment == '') { // If the comment is blank
            popups.alertPopup('The comment cannot be blank');
        } else {
            var newItem = {
                name: $scope.newComment,
                disqualified: $scope.newDisqual
            };
            $scope.ProxySettings.Comment_Options.push(newItem);
            $scope.newComment = null;
            $scope.newDisqual = false;
            $scope.changed = true;
        }
    };

    // Delete a comment
    $scope.deleteComment = function (comment) {
        popups.confirmPopup("Are you sure you want to delete the comment?").then(function () {
            var item = $scope.ProxySettings.Comment_Options.indexOf(comment);
            if (item !== -1) {
                $scope.ProxySettings.Comment_Options.splice(item, 1);
            }
        });
    };

    $scope.clearDates = function (field) {
        field.Validation = {
            min: null,
            max: null
        };
        field.changed = true;
    };

    $scope.setValidation = function (field) {
        if (field.Type == 'DATE') {
            field.Validation = {
                min: null,
                max: null
            };
        } else {
            field.Validation = null;
        }
    };

    // Send an individual field to the database to change
    $scope.saveField = function (field) {
        if (field.Name == null || field.Name == '') {
            popups.alertPopup('The additional field name cannot be blank', 'Additional field');
        } else {
            $http.post('/api/saveProxyField/' + sessionId, {
                field: field
            }).then(function (resp) {
                if (resp.data.status) {
                    getSessDetails();
                    $scope.newField = {
                        Name: '',
                        Type: null,
                        Validation: null,
                        Error: null
                    };
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Additional Fields');
                    $scope.msg = err.data.message;
                }
            });
        }
    };

    // To delete an additional field
    $scope.deleteField = function (field) {
        popups.confirmPopup('Are you sure you want to delete the field?', 'Additional field').then(function (resp) {
            $http.post('/api/deleteProxyField/' + sessionId, {
                field: field
            }).then(function (resp) {
                if (resp.data.status) {
                    getSessDetails();
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Additional Fields');
                    $scope.msg = err.data.message;
                }
            });
        });
    };

    $scope.saveChanges = function () {
        $http.post('/api/saveProxySettings/' + sessionId, {
            settings: $scope.ProxySettings
        }).then(function (resp) {
            if (resp.data.status) {
                popups.alertPopup('The changes were saved', 'Settings');
                $scope.changed = false;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Settings');
                $scope.msg = err.data.message;
            }
        });
    };

    // Start the page
    getSessDetails();

}];



