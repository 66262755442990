// Controller for the independent review page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', 'appState', function ($scope, $rootScope, $http, $state, popups, $stateParams, appState) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
        $http.get('/api/getProxySettings/' + sessionId, null).then(function (resp) {
            if (resp.data && resp.data.status) {
                $scope.ProxySettings = resp.data.ProxySettings;
                $scope.ProxySettings.Comment_Options.push({ name: 'Other', disqualified: false });
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // Draw the list of proxies available to view
    function drawProxyList() {
        $scope.msg = '';
        var list = [];
        $http.get('/api/getProxyViewList/' + sessionId, null).then(function (resp) {
            if (resp.data.status) {
                resp.data.list.forEach(function (item) {
                    if (item.Status != 'ExternalReview') {
                        list.push(item);
                    }
                });
                $scope.proxyList = list;
                $scope.proxyList.sort((a, b) => (a.Batch > b.Batch) ? 1 : (a.Batch == b.Batch && a.P_Position > b.P_Position) ? 1 : -1); // Sort the list based on the Batch and Position
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Proxy Views');
                $scope.msg = err.data.message;
            }
        });
    }

    // Open a proxy for viewing
    $scope.openProxy = function (proxy) {
        $rootScope.ProxyBatch = {};
        $rootScope.ProxyBatch.Batch = proxy.Batch;
        $rootScope.ProxyBatch.Number = proxy.P_Position;
        $rootScope.$emit('CreateViewProxy', {
            SessionSettings: $scope.SessionSettings,
            ProxySettings: $scope.ProxySettings,
            sessionId: sessionId
        });
        $scope.captureShow = 'View Proxy';
    };

    // To reopen the proxy is something went wrong
    function reopenProxy(Proxy) {
        $rootScope.$emit('ReopenProxy', {
            SessionSettings: $scope.SessionSettings,
            ProxySettings: $scope.ProxySettings,
            sessionId: sessionId,
            Proxy: Proxy,
            ProxyType: 'View'
        });
    }

    // To capture the proxy on the database based on the return
    var deregister1 = $rootScope.$on('CreateViewProxyReturn', function (event, DataObj) {
        var Proxy = DataObj.Proxy;
        $http.post('/api/sendProxyViewReview/' + sessionId, {
            proxy: Proxy
        }).then(function (resp) {
            if (resp.data.status) { // If the review was valid
                popups.alertPopup('The proxy form was sucessfully externally reviewed', 'Success');
                resetPage();
            } else {
                reopenProxy(Proxy);
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Proxy Review');
                $scope.msg = err.data.message;
            }
            reopenProxy(Proxy);
        });
    });

    // To close the proxy and return to the menu
    var deregister2 = $rootScope.$on('CreateProxyBackReturn', function (event) {
        resetPage();
    });

    // Reset the page to the menu
    function resetPage() {
        $scope.msg = '';
        $scope.captureShow = 'Menu';
        $scope.proxyList = null;
        drawProxyList();
    }

    // Destroy the listeners to not have the procedures created and executed more than once
    $scope.$on('$destroy', function () {
        deregister1();
        deregister2();
    });

    // Start the page
    getSessDetails();
    resetPage();

}];

