// Controller to add a user to the voters roll

module.exports = ['$scope', '$http', 'params', function (scope, $http, params) {

    // NOTE: closeThisDialog() is injected to the scope by ngDialog

    // Create the variable or passed from the script

    var sessionId = params.session;
    scope.name = '';

    scope.msg = null;
    scope.isProcessing = false;

    function _validateInput() {
        scope.msg = null;
        var validated = true;

        if (!scope.name || scope.name === '') {
            validated = false;
            scope.msg = 'Please add a name for the device';
        }

        return validated;
    }


    scope.saveDev = function () {
        if (scope.isProcessing)
            return;

        if (!_validateInput())
            return;

        
        scope.isProcessing = true;
        var url = '/api/manageVoting/addDevice/' + sessionId;
        var postData = {
            name: scope.name
        };
        $http.post(url, postData).then(function (resp) {
            if (resp.data && resp.data.status) { // If the submit was successful
                scope.closeThisDialog(true);
            } else {
                scope.msg = 'An unknown error has occurred. Please try again.';
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                scope.msg = err.data.message
            }
        }).finally(function () {
            scope.isProcessing = false;
        });
    };
}];