// Controller to add a query from inside the login - where the user details would be populated

module.exports = ['$scope', '$http', 'params', function (scope, $http, params) {

    // NOTE: closeThisDialog() is injected to the scope by ngDialog

    // Create the variable or passed from the script

    scope.query = null;
    scope.error = false;
    scope.errorMsg = '';
    
    if (params) {
        var voter = params.voter;
        var origin = params.origin;
        var sessionId = params.session;
        scope.email = voter.Email;
    } else {
        scope.email = 'Unknown';
    }


    scope.saveQuery = function () {
        scope.error = false;
        scope.errorMsg = '';
        if (scope.query !== '' && scope.query !== null) { // If the query was populated
            scope.isProcessing = true;
            var url = '/api/postQueryInternal/' + sessionId;
            var postData = {
                voter: voter,
                query: scope.query,
                origin: origin
            };
            $http.post(url, postData).then(function (resp) {
                if (resp.data && resp.data.status) { // If the submit was successful
                    scope.closeThisDialog(true);
                } else {
                    scope.error = true;
                    scope.errorMsg = 'An unknown error has occurred. Please try again.';
                }
            }).catch(function (err) {
                scope.error = true;
                scope.errorMsg = 'An unknown error has occurred. Please try again.';
            }).finally(function () {
                scope.isProcessing = false;
            });
        } else { // If the query was not populated
            scope.error = true;
            scope.errorMsg = 'Please enter a query to submit';
        }
    };
}];