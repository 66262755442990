// Controller for the ballot capture review page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', '$timeout', function ($scope, $rootScope, $http, $state, popups, $stateParams, $timeout) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }
   
    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
            if (!$scope.SessionSettings.Capture_Enable) { // If vote capturing is not enabled, return to the sessions screen.
                popups.alertPopup('Vote Capturing is not enabled on this session', 'Capturing');
                $state.go('el.sessions');
                return;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    function getDisputeList() {
        $http.get('/api/getDisputeList/' + sessionId, null).then(function (resp) {
            if (resp && resp.data && resp.data.status) {
                $scope.disputeList = resp.data.disputeList;
                $scope.pendingList = resp.data.pendingList;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // Shows details of the captured ballots to select a winning result, or to activate a new capture
    $scope.resolveDispute = function (ballot) {        
        $scope.showReview = 'Details';
        $scope.reviewBallot = ballot;
        $scope.reviewBallot.Results_1_C = JSON.parse(ballot.Results_1);
        $scope.reviewBallot.Results_2_C = JSON.parse(ballot.Results_2);
        $scope.reviewBallot.Valid_1_C = JSON.parse(ballot.Valid_1);
        $scope.reviewBallot.Valid_2_C = JSON.parse(ballot.Valid_2);
        $scope.reviewBallot.Results = [];
        $scope.reviewBallot.Results_1_C.forEach(function (port1) {
            var newPort = {};
            newPort.Nr = port1.PortNr;
            newPort.Name = port1.Portfolio;
            newPort.Result_1 = [];
            newPort.Result_2 = [];
            port1.Candidates.forEach(function (cand1) {
                if (cand1.Voted) {
                    newPort.Result_1.push(cand1.Name);
                }
            });
            for (let i = 0; i < port1.Abstains; i++) {
                newPort.Result_1.push('Abstain');
            }
            $scope.reviewBallot.Results_2_C.forEach(function (port2) {
                port2.Candidates.forEach(function (cand2) {
                    if (port1.PortNr == port2.PortNr && cand2.Voted) {
                        newPort.Result_2.push(cand2.Name);
                    }
                });
                if (port1.PortNr == port2.PortNr) {
                    for (let i = 0; i < port2.Abstains; i++) {
                        newPort.Result_2.push('Abstain');
                    }
                }
            });
            $scope.reviewBallot.Results.push(newPort);
        });
    };

    // Selecting the winning ballot uploads this to the database
    $scope.selectWinner = function (winner) {
        popups.confirmPopup('Are you sure you want to upload the ' + (winner == 'Results_1' ? 'Captured' : 'Reviewed') + ' ballot as the winner?', 'Disputes').then(function () {
            $http.post('/api/selectWinner/' + sessionId, {
                ballot: $scope.reviewBallot,
                winner: winner
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) { // If the ballot was written to the database
                    popups.alertPopup('The dispute was resolved', 'Dispute');
                }
                }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Resolve Dispute');
                }
            }).finally(function () {
                resetPage();
            });
        });
    };

    $scope.captureBallot = function () {
        $scope.showReview = 'Ballot';
        openBallot();
    };

    $scope.deleteBallot = function () {
        popups.confirmPopup('Are you sure you want to delete the disputed ballot?', 'Delete Ballot').then(function () {
            $http.post('/api/deleteBallot/' + sessionId, {
                Id: $scope.reviewBallot.Id,
                type: 'Dispute'
            }).then(function (resp) {
                if (resp.data.status) { // If the delete was valid
                    popups.alertPopup('The disputed ballot was deleted', 'Dispute Ballot');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Disputed Ballot');
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                resetPage();
            });
        });
    };

    $scope.deletePending = function (ballot) {
        popups.confirmPopup('Are you sure you want to delete the pending ballot?', 'Delete Ballot').then(function () {
            $http.post('/api/deleteBallot/' + sessionId, {
                Id: ballot.Id,
                type: 'Pending'
            }).then(function (resp) {
                if (resp.data.status) { // If the delete was valid
                    popups.alertPopup('The pending ballot was deleted', 'Pending Ballot');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Pending Ballot');
                    $scope.msg = err.data.message;
                }
            }).finally(function () {
                resetPage();
            });
        });
    };

    function openBallot() {
        $rootScope.$emit('CreateReviewBallot', {
            Candidates: $scope.Candidates,
            SessionSettings: $scope.SessionSettings
        });
    }

    var deregister = $rootScope.$on('CreateReviewBallotReturn', function (event, DataObj) {
        var captureVotes = DataObj.Candidates;
        var validity = DataObj.validity;
        $http.post('/api/sendDisputeBallotReview/' + sessionId, {
            ballot: $scope.reviewBallot,
            votes: captureVotes,
            validity: validity
        }).then(function (resp) {
            if (resp.data.status) { // If the capture was valid
                popups.alertPopup('The ballot was successfully captured', 'Dispute Ballot');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Disputed Ballot');
                $scope.msg = err.data.message;
            }
        }).finally(function () {
            resetPage(); // TODO Change to reset page only if capture was successful
        });
    });

    // To reset the page to the start
    function resetPage() {
        $scope.msg = '';
        $scope.showReview = 'List';
        $scope.disputeList = [];
        $scope.reviewBallot = null;
        getDisputeList();
    }

    // Remove the $rootScope listener when the controller is destroyed
    $scope.$on('$destroy', function () {
        deregister();
    });
    
    // Start the page
    getSessDetails();
    resetPage();

}];


