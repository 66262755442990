// auth-middleware
var angular = require('angular');

module.exports = ['appState', '$state', function (appState, $state) {
    this.run = function (event) {
        if (angular.isUndefined(appState.token)) {
            event.preventDefault();
            $state.go('elo.login');
        }
    };
    return this;
}];