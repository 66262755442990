// Controller to edit the access rights
module.exports = ['$http', '$scope', 'params', function ($http, scope, params) {
    scope.name = params.user.Name;
    scope.surname = params.user.Surname;
	//scope.sessionsList = [];
    scope.userAccess = {};
    var sessionId = params.sessionId;
    scope.sessionName = params.sessionName;

    // Get the user access, limited to what the person has admin roles on
	function getUserAccess() {
		$http.get('/users/getUserAccess/' + sessionId + '/' + params.user.Id).then(function (resp) {
			scope.userAccess = resp.data;
		}).catch(function (err) {
			if (err.data && err.data.message)
				scope.errorMsg = err.data.message;
			else
				scope.errorMsg = 'An unknown error has occurred, please contact your administrator';
		});
	}

	getUserAccess();

	// To change the access rights of a specific function
	scope.changeAccess = function (type) {
		scope.errorMsg = null;
		$http.post('/users/changeUserAccess', {
			type: type,
            user: params.user.Id,
            email: params.user.Email,
            sessionId: sessionId,
            newVal: scope.userAccess[type] ? 0 : 1
		}).then(function (resp) {
			if (resp.data === 'Success') {
				getUserAccess();
			} else {
				scope.errorMsg = 'Unknown error has occurred';
			}
		}).catch(function (err) {
			if (err.data && err.data.message)
				scope.errorMsg = err.data.message;
			else
				scope.errorMsg = 'Unknown error has occurred';
		});
	};
}];
