// user-login-middleware
module.exports = ['appState', '$state', function (appState, $state) {
    this.run = function (event) {
        if (appState.token) {
            event.preventDefault();
            // Depend on user role
            if (appState.isAdmin == "AdminWithSession") {
                $state.go('el.sessions');
            } else {
                // console.log('jumping to rc.sessions');
                $state.go('el.sessions');
            }
        }
    };
    return this;
}];