// Controller to add or edit voters on the roll

module.exports = ['$scope', 'params', function (scope, params) {

    // NOTE: closeThisDialog() is injected to the scope by ngDialog

    scope.heading = params.heading;
    scope.Bio = params.Bio;
    scope.Type = params.Type;

    scope.saveBio = function () {
        scope.closeThisDialog({ Bio: scope.Bio });
    };
}];