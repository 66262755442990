// User management page controller
module.exports = ['$scope', '$rootScope', '$http', '$stateParams', 'ngDialog', '$state', 'popups', function ($scope, $rootScope, $http, $stateParams, ngDialog, $state, popups) {

    // Initiate variables
    $scope.loginlist = [];
    $scope.activeLogin = null;

    // Get all the login requests from the database
    function getAllLogins() {
        $scope.msg = null;
        $http.get('/altLogin/getList').then(function (resp) {
            if (resp.data.status) {
                $scope.loginlist = resp.data.list;
                $scope.loginlist.forEach(function (login) {
                    login.AuditLog = JSON.parse(login.AuditLog);
                    login.AuditLog.forEach(function (log) {
                        var logdate = log.date;
                        log.date = new Date(logdate).toLocaleString();
                    });
                });
            } else {
                popups.alertPopup("An unknown error has occurred. Please contact the administrator.", "Error");
            }
        }).catch(function (err) {
            if (err.status === 403) {
                popups.alertPopup("You don't have enough privilege to access the alternative logins.", "Error");
                $state.go('el.sessions', null, { reload: true });
            } else {
                $scope.msg = err;
                popups.alertPopup("There was an error while fetching users data. Please try refresh the page.", "Error");
            }
        });
    }

    // Remove the login request from the database
    $scope.deactiveLogin = function (login) {
        $scope.msg = null;
        popups.confirmPopup("Are you sure you want to remove this login?").then(function () {
            $http.post('/altLogin/removeLogin', {
                LoginId: login.Id
            }).then(function (resp) {
                if (resp.data && resp.data.status) {
                    popups.alertPopup('The login has been removed');
                    getAllLogins();
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later.');
                    getAllLogins();
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            });
        });
    };

    $scope.loginUser = function (login) {
        $scope.activeLogin = login;
        if ($scope.activeLogin.Status == 'Requested') {
            $http.post('/altLogin/activateLogin', {
                LoginId: $scope.activeLogin.Id
            }).then(function (resp) {
                if (resp.data && resp.data.status) {
                    $scope.activeLogin = resp.data.login;
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later.');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            });
        }
    };

    $scope.resetList = function () {
        $scope.activeLogin = null;
        getAllLogins();
    };

    $scope.refreshUser = function () {
        if (!$scope.activeLogin) {
            popups.alertPopup('There is no active login selected', 'Error');
        } else {
            $http.post('/altLogin/refreshUser', {
                LoginId: $scope.activeLogin.Id
            }).then(function (resp) {
                if (resp.data && resp.data.status) {
                    $scope.activeLogin = resp.data.login;
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later.');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                }
            });
        }
    };

    // Run upon loading the page. This will get the data and set up the form.
    getAllLogins();
}];

