// Controller for the nomination administration page
module.exports = ['$scope', '$http', '$state', 'appState', 'popups', '$stateParams', '$timeout', 'Upload', 'ngDialog', function ($scope, $http, $state, appState, popups, $stateParams, $timeout, Upload, ngDialog) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Scope variables
    $scope.msg = '';
    $scope.showArea = null;



    // Get the session settings from the database
    function getSessDetails () {
        $scope.msg = '';
        $http.get('/api/getNomAdmin/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Session;
            $scope.NominationSettings = resp.data.Settings;
            $scope.Questions = resp.data.Questions;
            $scope.Questions.forEach(function (quest) {
                if (quest.Mandatory == 1) {
                    quest.Mandatory = true;
                } else {
                    quest.Mandatory = false;
                }
                if (quest.Type == 'Nominate' || quest.Type == 'Second') { // Change the options in these to flags
                    quest.options.forEach(function (option) {
                        if (option.Value == 'Email') {
                            quest.EmailOption = true;
                        }
                        if (option.Value == 'Numb') {
                            quest.NumbOption = true;
                        }
                    });
                }
            });
            $scope.NominationSettings.Start_Date = new Date($scope.NominationSettings.Start_Date);
            $scope.NominationSettings.End_Date = new Date($scope.NominationSettings.End_Date);
            $scope.customisationChange = false;
            $scope.settingsChange = false;
            $scope.questionChange = false;
            $scope.updateValue = false;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                $scope.msg = err.data.message;
            }
        });
    }

    // Post the customisation settings to the database
    $scope.updateCustomisation = function () {
        $scope.msg = '';
        $http.post('/api/postNominationCustomisation/' + sessionId, {
            Details: $scope.NominationSettings
        }).then(function (resp) {
            if (resp.data.result) {
                popups.alertPopup("Customisation settings updated", "Confirmation");
                $timeout(function () { getSessDetails(); }, 1000);
            } else {
                $scope.msg = 'Failure in updating the settings. Please contact the administrator.';
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, "Error");
                $scope.msg = err.data.message;
            }
        });
    };

    // Post the session settings to the database.
    $scope.updateSettings = function () {
        $scope.msg = '';
        if (testSettings()) {
            $scope.msg = '';
            $http.post('/api/postNominationSettings/' + sessionId, {
                Details: $scope.NominationSettings
            }).then(function (resp) {
                if (resp.data.result == true) {
                    popups.alertPopup("Nomination settings have been updated", "Confirmation");
                    $timeout(function () { getSessDetails(); }, 1000);
                } else {
                    popups.alertPopup('An unknown error has occured. Please contact your administrator', 'Error');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                    $scope.msg = err.data.message;
                }
            });
        }
    };

    // Test the dates on the settings to ensure that the start date is before the end date.
    function testSettings() {
        var startDate = new Date($scope.NominationSettings.Start_Date);
        var endDate = new Date($scope.NominationSettings.End_Date);
        if ($scope.NominationSettings.Start_Date != null && $scope.NominationSettings.End_Date != null) {
            if (startDate >= endDate) {
                $scope.msg = 'The start date cannot be before the end date';
                return false;
            } else {
                return true;
            }
        } else {
            $scope.msg = 'The dates cannot be blank';
            return false;
        }
    }

    // Add a question to the list of questions, with the defaults enabled.
    $scope.addQuestion = function () {
        var question = {
            Id: $scope.Questions.length + 1,
            Questions: '',
            Type: 'Nominate',
            Mandatory: false,
            options: []
        };
        $scope.Questions.push(question);
    };

    // Remove a question completely from the list displayed
    $scope.removeQuestion = function (quest) {
        popups.confirmPopup('Are you sure you want to delete the question?', 'Delete').then(function (resp) {
            var index = $scope.Questions.indexOf(quest);
            $scope.Questions.splice(index, 1);
        });
    };

    // Add an option to the list displayed.
    $scope.addOption = function (quest) {
        var newoption = {
            QNr: quest.Id,
            Value: ''
        };
        quest.options.push(newoption);
    };

    // Remove an option from the list displayed
    $scope.removeOption = function (quest, option) {
        var index = quest.options.indexOf(option);
        quest.options.splice(index, 1);
    };

    // Validated that the questions are set up within parameters
    function validateQuestions(callback) {
        var validated = true;
        var portprocess = 0;
        $scope.Questions.forEach(function (quest) {
            if (quest.Questions.length < 1) {
                validated = false;
                popups.alertPopup('The question cannot be empty', 'Error');
            } else if (!quest.Type || quest.Type == '') {
                validated = false;
                popups.alertPopup('The type cannot be empty', 'Error');
            } else if ((quest.Type == 'Radio' || quest.Type == 'Dropdown') && quest.options.length < 2) {
                validated = false;
                popups.alertPopup('The options are incorrect. There should at least be more than one for the relevant questions.', 'Error');
            } else if ((quest.Type == 'Nominate' || quest.Type == 'Second') && !quest.EmailOption && !quest.NumbOption) {
                validated = false;
                popups.alertPopup('At least one validation field should be selected for the Nomination and Secondee questions', 'Error');
            }
            portprocess++;
            if (portprocess >= $scope.Questions.length) {
                callback(validated);
            }
        });
    }

    // Correct the validation options for the Nominate and Second fields
    function addValidationOptions(callback) {
        var portprocess = 0;
        $scope.Questions.forEach(function (quest) {
            if (quest.Type == 'Nominate' || quest.Type == 'Second') {
                quest.options = [];
                if (quest.EmailOption) {
                    var newoption1 = {
                        QNr: quest.Id,
                        Value: 'Email'
                    };
                    quest.options.push(newoption1);
                }
                if (quest.NumbOption && $scope.SessionSettings.Show_Number) {
                    var newoption2 = {
                        QNr: quest.Id,
                        Value: 'Numb'
                    };
                    quest.options.push(newoption2);
                }
            }
            portprocess++;
        });
        if (portprocess >= $scope.Questions.length) {
            callback(true);
        }
    }

    // Post the list of questions and options to the database.
    $scope.updateList = function () {
        validateQuestions(function (result) {
            if (result) {
                $scope.updateValue = true;
                addValidationOptions(function (result) {
                    $http.post('/api/postNominationQuestions/' + sessionId, {
                        Questions: $scope.Questions
                    }).then(function (resp) {
                        if (resp.data.result == true) {
                            popups.alertPopup("The question list was updated", "Confirmation");
                            $timeout(function () {
                                getSessDetails();
                            }, 1000);
                        } else {
                            popups.alertPopup('An unknown error has occured. Please contact your administrator', 'Error');
                        }
                    }).catch(function (err) {
                        if (err && err.data && err.data.message) {
                            popups.alertPopup(err.data.message, 'Error');
                            $scope.msg = err.data.message;
                        }
                    });
                });
            }
        });
    };

    // Get the number of nominations currently on the database.
    $scope.getNominations = function () {
        $scope.msg = '';
        $http.get('/api/getNominationsAdmin/' + sessionId).then(function (resp) {
            $scope.totalNominations = resp.data.RowNr;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    };

    // This will clear all of the Nominations from the database
    $scope.clearNominations = function () {
        $scope.msg = '';
        popups.confirmPopup('Are you sure you want to clear the nominations from the database?', 'Clear Nominations').then(function () {
            popups.confirmPopup('All nominations will be deleted. Are you very sure you want to continue?', 'Confirmation').then(function () {
                $http.post('/api/clearNominationsAdmin/' + sessionId, {}).then(function (resp) {
                    if (resp.data.status) {
                        popups.alertPopup('The nominations have been cleared', 'Information');
                        $scope.getNominations();
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        popups.alertPopup(err.data.message, 'Error');
                        $scope.msg = err.data.message;
                    }
                });
            });
        });
    };

    $scope.exportNominations = function () {
        // TODO Export the nominations in excel
        popups.alertPopup('To export the nominations in Excel');
    };

    
    // To keep the loading scripts together
    getSessDetails();
}];

