// Controller to add a user to the voters roll

module.exports = ['$scope', '$http', 'params', function (scope, $http, params) {

    // NOTE: closeThisDialog() is injected to the scope by ngDialog

    // Create the variable or passed from the script

    var origin = params.origin;
    var sessionId = params.session;
    scope.show_number = params.show_number;
    scope.number_name = params.number_name;
    scope.show_number_2 = params.show_number_2;
    scope.number_name_2 = params.number_name_2;

    scope.name = '';
    scope.surname = '';
    scope.email = '';
    scope.mobile = null;
    scope.numb = '';
    scope.numb_2 = '';
    scope.organisation = null;
    scope.territory = null;
    scope.query = null;

    scope.error = false;
    scope.errorMsg = null;
    scope.isProcessing = false;

    scope.countries = [ // Populate the countries dropdown list
        {
            Country: 'South Africa',
            Code: '+27',
            Territory: 'ZA'
        }, {
            Country: 'Lesotho',
            Code: '+266',
            Territory: 'LS'
        }, {
            Country: 'Botswana',
            Code: '+267',
            Territory: 'BW'
        }, {
            Country: 'Namibia',
            Code: '+264',
            Territory: 'NA'
        }, {
            Country: 'Zimbabwe',
            Code: '+263',
            Territory: 'ZW'
        }, {
            Country: 'Mozambique',
            Code: '+258',
            Territory: 'MZ'
        }, {
            Country: 'Swaziland',
            Code: '+268',
            Territory: 'SZ'
        }, {
            Country: 'Malawi',
            Code: '+265',
            Territory: 'MW'
        }, {
            Country: 'Other',
            Code: '+',
            Territory: 'Not Applicable'
        }
    ];

    scope.MobileCode = null;
    scope.mobileNr = '';

    scope.populateMobile = function () {
        if (scope.mobileNr.startsWith('0')) {
            scope.mobile = scope.MobileCode.Code + scope.mobileNr.substring(1);
        } else {
            scope.mobile = scope.MobileCode.Code + scope.mobileNr;
        }
    };

    function _validateInput() {
        scope.error = false;
        scope.errorMsg = null;
        var validated = true;

        if (!scope.email || scope.email === '') {
            validated = false;
            scope.errorMsg = 'Please add an email address';
        }

        if (!scope.name || scope.name === '') {
            validated = false;
            scope.errorMsg = 'Please add a name';
        }

        if (!scope.surname || scope.surname === '') {
            validated = false;
            scope.errorMsg = 'Please add a surname';
        }

        if (!scope.mobile || scope.mobile.length < 4) {
            validated = false;
            scope.errorMsg = 'Please add a full mobile number';
        }

        if (!scope.territory) {
            validated = false;
            scope.errorMsg = 'Please add a territory for the user.';
        }

        if (!scope.organisation || scope.organisation.length < 2) {
            validated = false;
            scope.errorMsg = 'Please add an organisation for the user.';
        }

        if (!scope.query || scope.query === '') {
            validated = false;
            scope.errorMsg = 'Please add details for the query.';
        }

        return validated;
    }


    scope.saveQuery = function () {
        if (scope.isProcessing)
            return;

        if (!_validateInput())
            return;

        
        scope.isProcessing = true;
        var url = '/api/postQueryVotersRoll/' + sessionId;
        var voter = {
            Name: scope.name,
            Surname: scope.surname,
            Email: scope.email,
            Mobile: scope.mobile,
            Numb: scope.numb,
            Numb_2: scope.numb_2,
            Organisation: scope.organisation,
            Territory: scope.territory.Territory
        };
        var postData = {
            voter: voter,
            query: scope.query,
            origin: origin
        };
        $http.post(url, postData).then(function (resp) {
            if (resp.data && resp.data.status) { // If the submit was successful
                scope.closeThisDialog(true);
            } else {
                scope.error = true;
                scope.errorMsg = 'An unknown error has occurred. Please try again.';
            }
        }).catch(function (err) {
            scope.error = true;
            scope.errorMsg = 'An unknown error has occurred. Please try again.';
        }).finally(function () {
            scope.isProcessing = false;
        });
    };
}];