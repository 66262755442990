// Controller for the proxy capturing and reviewing page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'popups', '$stateParams', '$timeout', function ($scope, $rootScope, $http, $state, popups, $stateParams, $timeout) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.Candidates = resp.data.Portfolios;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
        $http.get('/api/getProxySettings/' + sessionId, null).then(function (resp) {
            if (resp.data && resp.data.status) {
                $scope.ProxySettings = resp.data.ProxySettings;
                $scope.ProxySettings.Comment_Options.push({ name: 'Other', disqualified: false });
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    // To activate the proxy capturing
    $scope.captureProxy = function () {
        if (!$rootScope.ProxyBatch) { // If there is no current proxy batch, create batches
            $rootScope.ProxyBatch = {};
            $rootScope.ProxyBatch.Batch = 1;
            $rootScope.ProxyBatch.Number = 1;
        }
        $rootScope.$emit('CreateCaptureProxy', {
            SessionSettings: $scope.SessionSettings,
            ProxySettings: $scope.ProxySettings,
            sessionId: sessionId
        });
        $scope.captureShow = 'Capture';
    };

    $scope.reviewProxy = function () {
        if (!$rootScope.ProxyBatch) { // If there is no current proxy batch, create batches
            $rootScope.ProxyBatch = {};
            $rootScope.ProxyBatch.Batch = 1;
            $rootScope.ProxyBatch.Number = 1;
        }
        $rootScope.$emit('CreateReviewProxy', {
            SessionSettings: $scope.SessionSettings,
            ProxySettings: $scope.ProxySettings,
            sessionId: sessionId
        });
        $scope.captureShow = 'Review';
    };

    $scope.secondReview = function () {
        if (!$rootScope.ProxyBatch) { // If there is no current proxy batch, create batches
            $rootScope.ProxyBatch = {};
            $rootScope.ProxyBatch.Batch = 1;
            $rootScope.ProxyBatch.Number = 1;
        }
        $rootScope.$emit('CreateReview2Proxy', {
            SessionSettings: $scope.SessionSettings,
            ProxySettings: $scope.ProxySettings,
            sessionId: sessionId
        });
        $scope.captureShow = 'Second Review';
    };

    // To reopen the proxy
    function reopenProxy(Proxy, ProxyType, reviewList) {
        $rootScope.$emit('ReopenProxy', {
            SessionSettings: $scope.SessionSettings,
            ProxySettings: $scope.ProxySettings,
            sessionId: sessionId,
            Proxy: Proxy,
            ProxyType: ProxyType,
            reviewList: reviewList
        });
        if (ProxyType == 'Capture' || ProxyType == 'Review') {
            $scope.captureShow = ProxyType;
        } else if (ProxyType == 'Review_2') {
            $scope.captureShow = 'Second Review';
        }
    }

    // To capture the proxy on the database based on the return
    var deregister1 = $rootScope.$on('CreateCaptureProxyReturn', function (event, DataObj) {
        var Proxy = DataObj.Proxy;
        var Type = DataObj.Type;
        var ProxyType = DataObj.ProxyType;
        var reviewList = DataObj.reviewList || null;
        $http.post('/api/sendProxyCaptureReview/' + sessionId, {
            proxy: Proxy,
            fields: $scope.ProxySettings.AddFields,
            type: ProxyType
        }).then(function (resp) {
            if (resp.data.status) { // If the capture was valid
                if (ProxyType == 'Capture') {
                    popups.alertPopup('The proxy form was added to the database', 'Success');
                } else if (ProxyType == 'Review') {
                    popups.alertPopup('The proxy form was successfully reviewed', 'Success');
                } else if (ProxyType == 'Review_2') {
                    popups.alertPopup('The proxy form was successfully secondary reviewed', 'Success');
                }

                if (Type == 'Close') { // If no more proxies will be captured
                    resetPage();
                } else if (Type == 'More') { // If we will capture or review more proxies
                    if (ProxyType == 'Capture') {
                        $rootScope.ProxyBatch.Number++;
                        $rootScope.$emit('CreateCaptureProxy', {
                            SessionSettings: $scope.SessionSettings,
                            ProxySettings: $scope.ProxySettings,
                            sessionId: sessionId
                        });
                        $scope.captureShow = 'Capture';
                    } else if (ProxyType == 'Review') {
                        $rootScope.ProxyBatch.Number++;
                        $rootScope.$emit('CreateReviewProxy', {
                            SessionSettings: $scope.SessionSettings,
                            ProxySettings: $scope.ProxySettings,
                            sessionId: sessionId
                        });
                        $scope.captureShow = 'Review';
                    } else if (ProxyType == 'Review_2') { // For the second review, move to the next item on the sample
                        var index = reviewList.indexOf($rootScope.ProxyBatch.Number);
                        if (index == -1) { // If the item was not found
                            popups.alertPopup('An unknown error has occurred. Please try again', 'Second Review');
                            resetPage();
                        } else if ((index + 1) >= reviewList.length) { // If the end of the list was received
                            popups.alertPopup('There are no more proxies to second review', 'Second Review');
                            resetPage();
                        } else {
                            $rootScope.ProxyBatch.Number = reviewList[index + 1];
                            $rootScope.$emit('CreateReview2Proxy', {
                                SessionSettings: $scope.SessionSettings,
                                ProxySettings: $scope.ProxySettings,
                                sessionId: sessionId,
                                reviewList: reviewList
                            });
                            $scope.captureShow = 'Second Review';
                        }
                    }
                } else {
                    popups.alertPopup('An unknown error has occurred. Please contact the administrator');
                    resetPage();
                }
            } else {
                // If the complete response was not received, open the proxy again
                reopenProxy(Proxy, ProxyType, reviewList);
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Proxy Capture');
                $scope.msg = err.data.message;
            } else {
                reopenProxy(Proxy, ProxyType, reviewList);
            }
        });
    });

    // To close the proxy and return to the menu
    var deregister2 = $rootScope.$on('CreateProxyBackReturn', function (event) {
        resetPage();
    });

    // To edit the proxy form
    var deregister3 = $rootScope.$on('CreateEditProxyReturn', function (event, DataObj) {
        var Proxy = DataObj.Proxy;
        $http.post('/api/sendProxyEdit/' + sessionId, {
            proxy: Proxy
        }).then(function (resp) {
            if (resp.data.status) { // If the capture was valid
                popups.alertPopup('The proxy form was saved', 'Success');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Proxy Edit');
                $scope.msg = err.data.message;
            }
        }).finally(function () {
            resetPage();

        });
    });

    // Reset the page to the menu
    function resetPage() {
        $scope.msg = '';
        $scope.captureShow = 'Menu';
    }

    // Destroy the listeners to not have the procedures created and executed more than once
    $scope.$on('$destroy', function () {
        deregister1();
        deregister2();
        deregister3();
    });

    // Start the page
    getSessDetails();
    resetPage();

}];

