'use strict';
var angular = require('angular');

/**
 * Config for the router
 */
angular.module('Elections')
    .run(['$rootScope', '$state', '$stateParams', '$injector',
        function ($rootScope, $state, $stateParams, $injector) {
            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;

            $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
                var currentState = toState;
                callMiddlewares(event, currentState);

                function callMiddlewares(event, state) {
                    if (state && state.hasOwnProperty('middleware')) {
                        if (typeof currentState.middleware === 'object') {
                            angular.forEach(state.middleware, function (middleWare) {
                                callMiddleware(middleWare, event);
                            });
                            return;
                        }
                    }
                }

                function callMiddleware(middleWare, event) {
                    try {
                        $injector.get(middleWare).run(event);
                    } catch (e) {
                        console.log('the factory : ' + middleWare + ' does not exist');
                    }
                };
            });
        }
    ])
    .config(['$stateProvider', '$urlRouterProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $locationProvider) {
            $locationProvider.html5Mode(true);

            if (angular.isUndefined(localStorage.token)) {
                $urlRouterProvider.otherwise(function ($injector, $location) {
                    var $state = $injector.get("$state");
                    $state.go("elo.login");
                });
            } else {
                $urlRouterProvider.otherwise('/home');
            }

            $stateProvider
                // public urls
                .state('elo', { // Login background page
                    url: '',
                    templateUrl: 'src/tpl/footer_only.html',
                    middleware: ['userLoginMiddleware']
                })
                .state('elo.login', {
                    url: '^/login_page?err',
                    stateName: 'login',
                    template: require('./views/login.html'),
                    middleware: ['userLoginMiddleware']
                })
                .state('elo.logging-in', {
                    url: '^/login?code&state',
                    stateName: 'loggingIn',
                    template: require('./views/logging_in.html'),
                    middleware: ['userLoginMiddleware']
                })
                .state('elo.acc-manage', {
                    url: '^/accountManage?email&err',
                    stateName: 'manageAcc',
                    template: require('./views/account-management.html'),
                    middleware: ['userLoginMiddleware']
                })
                .state('elo.choose', {
                    url: '^/chooseQuery?email',
                    stateName: 'errorChoice',
                    template: require('./views/error_choose.html'),
                    middleware: ['userLoginMiddleware']
                })
                .state('elo.acc-create', {
                    url: '^/accountCreate?email',
                    stateName: 'createAcc',
                    template: require('./views/account-creation.html'),
                    middleware: ['userLoginMiddleware']
                })
                .state('elo.alternate-login', {
                    url: '^/alternativeLogin',
                    stateName: 'alternativeLogin',
                    template: require('./views/alternative-login.html'),
                    middleware: ['userLoginMiddleware']
                })
                .state('leg', { // Legal background page
                    url: '',
                    templateUrl: 'src/tpl/notices.html'
                })
                .state('leg.tou', {
                    url: '/terms_of_use',
                    stateName: 'termsOfUse',
                    template: require('./views/terms_of_use.html')
                })
                .state('leg.privacy', {
                    url: '/privacy',
                    stateName: 'privacy',
                    template: require('./views/privacy.html')
                })
                .state('leg.cookies', {
                    url: '/cookies',
                    stateName: 'cookies',
                    template: require('./views/cookies.html')
                })

                // requires authentication
                .state('el', { // Background page for the main application. This contains the header and the footer
                    url: '',
                    templateUrl: 'src/tpl/app.html',
                    middleware: ['authMiddleware']
                })
                .state('el.sessions', {
                    url: '/sessions',
                    stateName: 'sessions',
                    templateUrl: 'src/views/sessions.html',
                    middleware: ['authMiddleware']
                })
                .state('el.admin-mgmt', {
                    url: '/manage-admins',
                    stateName: 'useradmin',
                    templateUrl: 'src/views/admin-mgmt.html',
                    middleware: ['authMiddleware']
                })
                .state('el.sql-interface', {
                    url: '/sql-interface',
                    stateName: 'sqlInterface',
                    templateUrl: 'src/views/sql-interface.html',
                    middleware: ['authMiddleware']
                })
                .state('el.user-mgmt', {
                    url: '/manage-users/:sessionId',
                    stateName: 'sessionUsers',
                    templateUrl: 'src/views/users-mgmt.html',
                    middleware: ['authMiddleware']
                })
                .state('el.alt-login-mgmt', {
                    url: '/manage-alt-logins',
                    stateName: 'altLoginManag',
                    templateUrl: 'src/views/alt-login-mgmt.html',
                    middleware: ['authMiddleware']
                })
                .state('el.voting', {
                    url: '/voting/:sessionId/:type',
                    stateName: 'activeVoting',
                    templateUrl: 'src/views/voting.html',
                    middleware: ['authMiddleware']
                })
                .state('el.nomination', {
                    url: '/nomination/:sessionId',
                    stateName: 'activeNomination',
                    templateUrl: 'src/views/nomination.html',
                    middleware: ['authMiddleware']
                })
                .state('el.sessionAdmin', {
                    url: '/votingadmin/:sessionId',
                    stateName: 'sessionAdmin',
                    templateUrl: 'src/views/session-admin.html',
                    middleware: ['authMiddleware']
                })
                .state('el.nominationAdmin', {
                    url: '/nominationadmin/:sessionId',
                    stateName: 'nominationAdmin',
                    templateUrl: 'src/views/nomination-admin.html',
                    middleware: ['authMiddleware']
                })
                .state('el.rollstatsinternal', {
                    url: '/rollstats/:sessionId',
                    stateName: 'votersrollStatsInternal',
                    templateUrl: 'src/views/vstats_in.html',
                    middleware: ['authMiddleware']
                })
                .state('el.votersrollAdmin', {
                    url: '/rollAdmin/:sessionId',
                    stateName: 'votersrollAdministration',
                    templateUrl: 'src/views/votersroll.html',
                    middleware: ['authMiddleware']
                })
                .state('el.voterSupport', {
                    url: '/rollSupport/:sessionId',
                    stateName: 'votersrollSupport',
                    templateUrl: 'src/views/support.html',
                    middleware: ['authMiddleware']
                })
                .state('el.supportQuery', {
                    url: '/rollQuery/:sessionId/:queryId',
                    stateName: 'votersrollQuery',
                    templateUrl: 'src/views/support-query.html',
                    middleware: ['authMiddleware']
                })
                .state('el.clientApprovals', {
                    url: '/clientApprovals/:sessionId',
                    stateName: 'clientVoterApprovals',
                    templateUrl: 'src/views/client-approvals.html',
                    middleware: ['authMiddleware']
                })
                .state('el.managedVotingAgent', {
                    url: '/VotingAgent/:sessionId',
                    stateName: 'managedVotingAgent',
                    templateUrl: 'src/views/manage-voting-agent.html',
                    middleware: ['authMiddleware']
                })
                .state('el.managedVotingManage', {
                    url: '/VotingManager/:sessionId',
                    stateName: 'managedVotingManage',
                    templateUrl: 'src/views/manage-voting-management.html',
                    middleware: ['authMiddleware']
                })
                .state('el.viewResults', {
                    url: '/viewResultsInternal/:sessionId',
                    stateName: 'viewResultsInternal',
                    templateUrl: 'src/views/viewResults_in.html',
                    middleware: ['authMiddleware']
                })
                .state('el.votingIntegrity', {
                    url: '/votingIntegrity/:sessionId',
                    stateName: 'votingIntegrity',
                    templateUrl: 'src/views/votingIntegrity.html',
                    middleware: ['authMiddleware']
                })
                .state('el.votingCapture', {
                    templateUrl: 'src/views/vote_capturing.html',
                    middleware: ['authMiddleware']
                })
                .state('el.votingCapture.ballot', {
                    url: '/capturevote/:sessionId',
                    stateName: 'votingCapture',
                    templateUrl: 'src/tpl/ballot.html',
                    middleware: ['authMiddleware']
                })
                .state('el.votingReview', {
                    templateUrl: 'src/views/vote_review.html',
                    middleware: ['authMiddleware']
                })
                .state('el.votingReview.ballot', {
                    url: '/reviewvote/:sessionId',
                    stateName: 'votingReview',
                    templateUrl: 'src/tpl/ballot.html',
                    middleware: ['authMiddleware']
                })
                .state('el.votingFinalise', {
                    url: '/reviewFinalise/:sessionId',
                    stateName: 'votingFinalise',
                    templateUrl: 'src/views/vote_finalisation.html',
                    middleware: ['authMiddleware']
                })
                .state('el.votingLookup', {
                    url: '/voteLookup/:sessionId',
                    stateName: 'votingLookup',
                    templateUrl: 'src/views/vote_lookup.html',
                    middleware: ['authMiddleware']
                })
                .state('el.proxySettings', {
                    url: '/proxySettings/:sessionId',
                    stateName: 'proxySettings',
                    templateUrl: 'src/views/proxy_settings.html',
                    middleware: ['authMiddleware']
                })
                .state('el.proxyImport', {
                    url: '/proxyImport/:sessionId',
                    stateName: 'proxyImport',
                    templateUrl: 'src/views/proxy_import.html',
                    middleware: ['authMiddleware']
                })
                .state('el.proxyCapture', {
                    templateUrl: 'src/views/proxy_capturing.html',
                    middleware: ['authMiddleware']
                })
                .state('el.proxyCapture.proxy', {
                    url: '/captureproxy/:sessionId',
                    stateName: 'proxyCapture',
                    templateUrl: 'src/tpl/proxy.html',
                    middleware: ['authMiddleware']
                })
                .state('el.proxyManagement', {
                    templateUrl: 'src/views/proxy_management.html',
                    middleware: ['authMiddleware']
                })
                .state('el.proxyManagement.proxy', {
                    url: '/manageproxy/:sessionId',
                    stateName: 'proxyManagement',
                    templateUrl: 'src/tpl/proxy.html',
                    middleware: ['authMiddleware']
                })
                .state('el.proxyViews', {
                    templateUrl: 'src/views/proxy_views.html',
                    middleware: ['authMiddleware']
                })
                .state('el.proxyViews.proxy', {
                    url: '/viewproxy/:sessionId',
                    stateName: 'proxyViews',
                    templateUrl: 'src/tpl/proxy.html',
                    middleware: ['authMiddleware']
                })
                .state('el.meetingVotingRegistration', {
                    url: '/votingRegistration/:sessionId',
                    stateName: 'votingRegistration',
                    templateUrl: 'src/views/meeting_registration.html',
                    middleware: ['authMiddleware']
                })
                .state('el.meetingVotingManagement', {
                    url: '/votingManagement/:sessionId',
                    stateName: 'votingManagement',
                    templateUrl: 'src/views/meeting_management.html',
                    middleware: ['authMiddleware']
                })
                .state('el.controlDashboard', {
                    url: '/controlDashboard/:sessionId',
                    stateName: 'controlDashboard',
                    templateUrl: 'src/views/control_dashboard.html',
                    middleware: ['authMiddleware']
                })
                .state('eom', { // Empty background page
                    abstract: true,
                    template: '<div ui-view></div>'
                })
                .state('eom.rollstatsexternal', {
                    url: '/rollstatsexternal/:sessionId',
                    stateName: 'votersrollStatsExternal',
                    templateUrl: 'src/views/vstats_ex.html',
                    middleware: ['authMiddleware']
                })
                .state('eom.resultsexternal', {
                    url: '/resultsexternal/:sessionId',
                    stateName: 'resultsExternal',
                    templateUrl: 'src/views/viewResults_ex.html',
                    middleware: ['authMiddleware']
                })
                .state('eom.printcertificate', {
                    url: '/certificateprint/:data',
                    stateName: 'certificatePrint',
                    templateUrl: 'src/views/print-certificate.html',
                    middleware: ['authMiddleware']
                })
                .state('eom.resultsprint', {
                    url: '/resultsprint/:sessionId',
                    stateName: 'resultsPrint',
                    templateUrl: 'src/views/viewResults_print.html',
                    middleware: ['authMiddleware']
                })
                .state('eom.openanonymousresults', {
                    url: '/anonymousresults/:sessionId/:data',
                    stateName: 'anonymousResults',
                    templateUrl: 'src/views/viewResults_anonymous.html',
                    middleware: ['authMiddleware']
                })
                .state('eom.managedVotingAgentDisplay', {
                    url: '/managedVotingAgentDisplay/:sessionId',
                    stateName: 'managedVotingAgentDisplay',
                    templateUrl: 'src/views/manage-voting-display.html',
                    middleware: ['authMiddleware']
                })
                .state('eom.managedVotingClient', {
                    templateUrl: 'src/views/managed-voting-client.html', // TODO Add the screen
                    middleware: ['authMiddleware']
                })
                .state('eom.managedVotingClient.ballot', {
                    url: '/VotingClient/:sessionId',
                    stateName: 'managedVotingClient',
                    templateUrl: 'src/tpl/ballot.html',
                    middleware: ['authMiddleware']
                });

        }
    ]);