// Controller for the votersroll support page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'ngDialog', 'popups', '$stateParams', 'appState', '$interval', function ($scope, $rootScope, $http, $state, ngDialog, popups, $stateParams, appState, $interval) {
    
    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Initiate the variables
    $scope.msg = '';
    $scope.globalQueries = [];
    $scope.sessionQueries = [];
    var userAccess = appState.access;
    var isAdmin = appState.isAdmin;
    $scope.statusOptions = ['Open', 'New', 'In Progress', 'On Hold', 'Resolved', 'All'];
    $scope.filters = {};
    $scope.filters.status = 'Open';
    //$scope.statusSelected = null;
    $scope.votedOptions = ['All', 'Voted', 'Not Voted', 'N/A'];
    //$scope.votedSelected = 'All';
    $scope.sessionFiltered = [];

    $scope.origins = {
        AccCreation: 'External Account Creation Page',
        AccManagement: 'External Account Management Page',
        votersRoll: 'Internal Votersroll Page',
        clientApproval: 'Client Approval Page'
    };


    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    $scope.filterQueries = function () {
        $scope.sessionFiltered = [];
        $scope.sessionQueries.forEach(function (query) {
            if ($scope.filters.status == 'All' || ($scope.filters.status == 'Open' && (query.CurrentStatus == 'Created' || query.CurrentStatus == 'In Progress' || query.CurrentStatus == 'Unapproved' || query.CurrentStatus == 'On Hold')) || ($scope.filters.status == 'In Progress' && (query.CurrentStatus == 'In Progress' || query.CurrentStatus == 'Unapproved')) || ($scope.filters.status == 'On Hold' && query.CurrentStatus == 'On Hold') || ($scope.filters.status == 'Resolved' && (query.CurrentStatus == 'Resolved' || query.CurrentStatus == 'Approved'))) {
                // TODO Add the filter for the voted as well
                $scope.sessionFiltered.push(query);
            }
        });
    };

    // Get the queries from the database
    function getQueries() {
        $scope.msg = '';
        $http.get('/api/getQueries/' + sessionId, null).then(function (resp) {
            $scope.globalQueries = resp.data.global;
            $scope.sessionQueries = resp.data.session;
            $scope.filterQueries();
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    $scope.allocate = function (query) {
        popups.confirmPopup('Are you sure you want to allocate the global query to this session?', 'Allocation').then(function () {
            $http.post('/api/allocateQuery/' + sessionId, {
                query: query
            }).then(function (resp) {
                if (resp.data && resp.data.status) {
                    getQueries();
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later', 'Allocate Query');
                }
            }).catch(function (err) {
                if (err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later', 'Allocate Query');
                }
            });
        });
    };

    $scope.reject = function (query) {
        popups.confirmPopup('Are you sure you want to cancel and delete this global query. It could affect other sessions still in progress?', 'Allocation').then(function () {
            $http.post('/api/deleteQuery/' + sessionId, {
                query: query
            }).then(function (resp) {
                if (resp.data && resp.data.status) {
                    getQueries();
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later', 'Allocate Query');
                }
            }).catch(function (err) {
                if (err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                } else {
                    popups.alertPopup('An unknown error has occurred. Please try again later', 'Allocate Query');
                }
            });
        });
    };

    // Test if the user has access to this function
    $scope.hasAccess = function (type) {
        var found = false;
        if (isAdmin) {
            return true;
        } else {
            userAccess.forEach(function (access) {
                if (access.Session == sessionId && access[type] === 1) {
                    found = true;
                }
            });
        }
        return found;
    };

    $scope.Manage = function (query) {
        $state.go('el.supportQuery', {
            sessionId: sessionId,
            queryId: query.Id
        }, {
                reload: true
            }
        );
    };

    $scope.unlock = function (query) {
        $http.post('/api/releaseQueryAdmin/' + sessionId, {
            query: query
        }).then(function (resp) {
            if (resp.data && !resp.data.status) {
                popups.alertPopup('An unknown error has occurred', 'Allocation');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Allocation');
            } else {
                popups.alertPopup('An unknown error has occurred', 'Allocation');
            }
        }).finally(function () {
            getQueries();
        });
    };

    $scope.updateVotingStatus = function () {
        var updateList = [];
        $scope.sessionQueries.forEach(function (query) {
            if (query.VoterId != '' && query.VoterId != null && query.VoterStatus == 1 && query.Voted != 1) {
                updateList.push(query);
            }
        });
        $http.post('/api/updateVotingStatus/' + sessionId, {
            updateList: updateList
        }).then(function (resp) {
            if (resp.data && resp.data.status) {
                popups.alertPopup('The query voting status has been updated', 'Queries');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Allocation');
            } else {
                popups.alertPopup('An unknown error has occurred', 'Allocation');
            }
        }).finally(function () {
            getQueries();
        });
    };

    $scope.exportQueries = function () {
        window.open(apiUrl + '/api/queries/' + sessionId + '/exportxls?token=' + appState.token);
    };

    // Set up a recurring function to get the data and update the view
    function startUpdates() {
        var intervalseconds = 30;
        $scope.getDataInterval = null;
        $scope.getDataInterval = $interval(function () {
            getQueries();
        }, 1000 * intervalseconds);
    }

    function stopUpdates() {
        if ($scope.getDataInterval) {
            $interval.cancel($scope.getDataInterval);
        }
    }

    // To stop the $interval function on destroying the element
    $scope.$on('$destroy', function () {
        stopUpdates();
    });


    // Load the data after script creation
    getSessDetails();
    getQueries();
    startUpdates();

     
}];
