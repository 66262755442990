// Controller for the session administration page

module.exports = ['$scope', '$rootScope', '$http', '$state', 'appState', 'popups', '$stateParams', '$timeout', 'Upload', 'ngDialog', function ($scope, $rootScope, $http, $state, appState, popups, $stateParams, $timeout, Upload, ngDialog) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Scope variables
    $scope.msg = '';
    $scope.showArea = null;
    $scope.importData = null;
    $scope.errorData = [];
    $scope.importProcessing = null;
    $scope.rollInfo = {};
    $scope.Logo = null;
    $scope.submittingSuppUpdate = false;
    $scope.submittingSuppResult = false;


    // Get the session settings from the database
    $scope.getSessDetails = function () {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.Candidates = resp.data.Portfolios;
            $scope.SessionSettings = resp.data.Details;
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
            $scope.SessionSettings.Start_Date = new Date($scope.SessionSettings.Start_Date);
            $scope.SessionSettings.End_Date = new Date($scope.SessionSettings.End_Date);
            $scope.customisationChange = false;
            $scope.settingsChange = false;
            $scope.candidateChange = false;
            $scope.updateValue = false;
            $http.get('/api/getFullBio/' + sessionId, null).then(function (resp) {
                if (resp && resp.data) {
                    var newBios = resp.data;
                    $scope.Candidates.forEach(function (port) {
                        port.Candidates.forEach(function (cand) {
                            newBios.forEach(function (cand2) {
                                if (cand2.Id === cand.Id) {
                                    cand.Bio = cand2.Bio;
                                }
                            });
                        });
                    });
                    $scope.$apply();
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                    $scope.msg = err.data.message;
                }
            });
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                $scope.msg = err.data.message;
            }
        });
    };

    // Get the fields listed for the PowerBI integration
    function getBIFields() {
        $scope.powerBI.Fields = [];
        $http.get('/api/getPBIFields/' + sessionId).then(function (resp) {
            $scope.powerBI.Fields = resp.data;
            $scope.powerBI.Fields.forEach(function (field) {
                field.PowerBI = field.PowerBI == 1 ? true : false;
            });
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                $scope.msg = err.data.message;
            }
        });
    }

    // Get the PowerBI integration settings
    function getPowerBI() {
        $scope.msg = '';
        $scope.powerBI = {};
        $http.get('/api/getPowerBISettings/' + sessionId).then(function (resp) {
            $scope.powerBI.Integration = resp.data.Integration == 1 ? true : false;
            $scope.powerBI.LinkURL = resp.data.LinkURL;
            $scope.powerBI.getURL = apiUrl + '/api/getPowerBIData/' + sessionId;
            $scope.powerBI.tokendetails = null;
            if ($scope.powerBI.Integration) {
                getBIFields();
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                $scope.msg = err.data.message;
            }
        });
    }

    // Post the customisation settings to the database
    $scope.updateCustomisation = function () {
        $scope.msg = '';
        $http.post('/api/postCustomisation/' + sessionId, {
            Details: $scope.SessionSettings
        }).then(function (resp) {
            if (resp.data.result) {
                popups.alertPopup("Customisation settings updated", "Confirmation");
                $timeout(function () { $scope.getSessDetails() }, 1000);
            } else {
                $scope.msg = 'Failure in updating the settings. Please contact the administrator.';
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, "Error");
                $scope.msg = err.data.message;
            }
        });
    };

    // Post the session settings to the database.
    $scope.updateSettings = function () {
        $scope.msg = '';
        if (testSettings()) {
            $scope.msg = '';
            $http.post('/api/postSettings/' + sessionId, {
                Details: $scope.SessionSettings
            }).then(function (resp) {
                if (resp.data.result == true) {
                    popups.alertPopup("Online voting settings have been updated", "Confirmation");
                    $timeout(function () { $scope.getSessDetails() }, 1000);
                } else {
                    popups.alertPopup('An unknown error has occured. Please contact your administrator', 'Error');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                    $scope.msg = err.data.message;
                }
            });
        }
    };

    // Test the dates on the settings to ensure that the start date is before the end date.
    function testSettings () {
        var startDate = new Date($scope.SessionSettings.Start_Date);
        var endDate = new Date($scope.SessionSettings.End_Date);
        if ($scope.SessionSettings.Start_Date != null && $scope.SessionSettings.End_Date != null) {
            if (startDate >= endDate) {
                $scope.msg = 'The start date cannot be before the end date';
                return false;
            } else {
                return true;
            }
        } else {
            $scope.msg = 'The dates cannot be blank';
            return false;
        }
    }

    // Test the dates to warn if the session is still open while releasing the results. The user can still override this and display live results.
    $scope.resultsReleaseChange = function () {
        if ($scope.SessionSettings.Results) {
            var now = new Date();
            var endDate = new Date($scope.SessionSettings.End_Date);
            if (endDate >= now) {
                // This will disable the results release if the voting is currently open.
                popups.alertPopup('The voting is still open. You cannot release the results');
                $scope.SessionSettings.Results = false;
                // Commented out: This will ask the user to confirm whether they can release the results since the voting is still open.
                /**popups.confirmPopup("The end date is after today's date. Are you sure you want to release the results?", 'Warning').then(function () {
                    $scope.settingsChange = true;
                }).catch(function () {
                    $scope.SessionSettings.Results = false;
                });**/
            } else {
                $scope.settingsChange = true;
            }
        } else {
            $scope.settingsChange = true;
        }
    };

    // Add a candidate to the list displayed.
    $scope.addCandidate = function (portfolio) {
        var person = {
            Name: '',
            Party: '',
            Image: '',
            Bio: null
        };
        portfolio.Candidates.push(person);
    };

    // Remvoe a candidate from the list displayed
    $scope.removeCandidate = function (portfolio, person) {
        var index = portfolio.Candidates.indexOf(person);
        portfolio.Candidates.splice(index, 1);
    };

    // Remove a portfolio completely from the list displayed
    $scope.removePortfolio = function (port) {
        if (port.Candidates.length > 0) {
            popups.alertPopup('The portfolio cannot be deleted, there are still candidates listed.', 'Error');
        } else {
            var index = $scope.Candidates.indexOf(port);
            $scope.Candidates.splice(index, 1);
        }
    };

    // Add a portfolio to the list of portfolios and candidates, with the defaults enabled.
    $scope.addPortfolio = function () {
        var port = {
            PortNr: $scope.Candidates.length+1,
            Portfolio: '',
            PortColour: '#ffffff',
            TextColour: '#000000',
            NumVotes: 1,
            Candidates: []
        };
        $scope.Candidates.push(port);
    };

    function validatePortfolios(callback) {
        var validated = true;
        portprocess = 0;
        $scope.Candidates.forEach(function (port) {
            if (port.Portfolio.length < 1) {
                validated = false;
                popups.alertPopup('The portfolio name cannot be empty', 'Error');
            } else if (port.NumVotes < 1) {
                validated = false;
                popups.alertPopup('The number of votes per portfolio cannot be zero', 'Error');
            } else if (port.Candidates.length < 1) {
                validated = false;
                popups.alertPopup('At least one candidate should be added to the portfolio', 'Error');
            }
            portprocess++;
            if (portprocess >= $scope.Candidates.length) {
                callback(validated);
            }
        });
    }

    $scope.editBio = function (candidate) {
        ngDialog.open({
            template: require('../tpl/view-edit-bio.html'),
            plain: true,
            controller: 'editBio',
            showClose: false,
            closeByDocument: false,
            resolve: {
                'params': function () {
                    return {
                        'Bio': candidate.Bio,
                        'heading': $scope.SessionSettings.Bio_Name,
                        'Type': 'Edit'
                    };
                }
            },
            preCloseCallback: function (resp) {
                if (resp && resp.Bio) {
                    candidate.Bio = resp.Bio;
                }
            }
        });
    };

    // Post the list of portfolios and candidates to the database.
    $scope.updateList = function () {
        validatePortfolios(function (result) {
            if (result) {
                $scope.updateValue = true;
                $http.post('/api/postList/' + sessionId, {
                    Portfolios: $scope.Candidates
                }).then(function (resp) {
                    if (resp.data.result == true) {
                        popups.alertPopup("The candidate list was updated", "Confirmation");
                        $timeout(function () {
                            $scope.getSessDetails();
                        }, 1000);
                    } else {
                        popups.alertPopup('An unknown error has occured. Please contact your administrator', 'Error');
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        popups.alertPopup(err.data.message, 'Error');
                        $scope.msg = err.data.message;
                    }
                }).finally(function () {
                    $scope.updateValue = false;
                });
            }
        });
    };

    // Test if any of the keys are empty
    function testKeys(keys, callback) {
        var empty = true;
        var process = 0;
        keys.forEach(function (key) {
            if (!key || key == '') {
                empty = false;
            }
            process++;
            if (process >= keys.length) {
                callback(empty);
            }
        });
    }

    // Receive image and convert to base64 string
    $scope.convertFile = function (file, Cand) {
        if (file) {
            var mimeType = file.type;
            if (mimeType.match(/image\/*/) === null) {
                popups.alertPopup('Please select an image', 'Error');
            } else {
                var filesize = (file.size / 1024).toFixed(4); // Gets the kb of the file
                if (filesize > 200) {
                    popups.alertPopup('The file cannot be larger than 200 KB', 'Error');
                } else {
                    var reader = new FileReader();
                    reader.onload = function () {
                        Cand.Image = reader.result;
                        $scope.$apply();
                    };
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    // Receive image and convert to base64 string
    $scope.convertLogo = function (file) {
        if (file) {
            var mimeType = file.type;
            if (mimeType.match(/image\/*/) === null) {
                popups.alertPopup('Please select an image', 'Error');
            } else {
                var filesize = (file.size / 1024).toFixed(4); // Gets the kb of the file
                if (filesize > 2000) { // Logo is increased to 2Mb, however it is not suggested to use large logos
                    popups.alertPopup('The file cannot be larger than 200 KB', 'Error');
                } else {
                    var reader = new FileReader();
                    reader.onload = function () {
                        $scope.SessionSettings.Logo = reader.result;
                        $scope.customisationChange = true
                        $scope.$apply();
                    };
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    // Remove the image for the candidate
    $scope.removeImage = function (Cand) {
        popups.confirmPopup('Are you sure you want to remove the image for ' + Cand.Name, 'Confirmation').then(function() {
            Cand.Image = null;
            $scope.$apply();
        });
    };

    $scope.removeLogo = function () {
        popups.confirmPopup('Are you sure you want to remove the branding image?', 'Confirmation').then(function () {
            $scope.SessionSettings.Logo = null;
            $scope.customisationChange = true;
            $scope.$apply();
        });
    };

    $scope.removeBio = function (Cand) {
        popups.confirmPopup('Are you sure you want to remove the ' + $scope.SessionSettings.Bio_Name + ' for ' + Cand.Name, 'Confirmation').then(function () {
            Cand.Bio = null;
            $scope.$apply();
        });
    };

    // Receive pdf biography and convert to base64 string
    $scope.convertBio = function (file, Cand) {
        if (file) {
            var mimeType = file.type;
            if (mimeType !== 'application/pdf') {
                popups.alertPopup('Please select a pdf file', 'Error');
            } else {
                var filesize = (file.size / 1024).toFixed(4); // Gets the kb of the file
                if (filesize > 2000) {
                    popups.alertPopup('The file cannot be larger than 2 Mb', 'Error');
                } else {
                    var reader = new FileReader();
                    reader.onload = function () {
                        Cand.Bio = reader.result;
                        $scope.$apply();
                    };
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    // Upload the selected file to the server. The file is returned in an object converted manner for allocation.
    $scope.uploadfile = function (file) {
        $scope.msg = '';
        if (file) {
            $scope.importProcessing = 'Sending to server...';
            Upload.upload({
                url: '/api/uploadfile/' + sessionId,
                file: file
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    $scope.importProcessing = 'Server Processing...';
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                    $scope.msg = err.data.message;
                }
            });
        }
    };

    $scope.getImportStatus = function (type) {
        $scope.msg = '';
        $scope.importProcessing = 'Getting Status...';
        $scope.importData = null;
        $scope.errorData = [];
        $scope.resultExtract = [];
        $scope.rollMapping = {};
        $scope.submittingRoll = 'Unavailable';
        $http.get('/api/getServerProcessingStatus/' + sessionId + '/'+ type, null).then(function (resp) {
            if (resp && resp.data) {
                if (resp.data.status) { // If the data was returned
                    $scope.importData = resp.data.datalist;
                    $scope.importCountries = resp.data.countries;
                    $scope.importKeys = Object.keys($scope.importData[0]);
                    testKeys($scope.importKeys, function (test) {
                        if (!test) {
                            popups.alertPopup('The fields cannot be blank. Please check the import data', 'Error');
                            $scope.importData = null;
                            $scope.importKeys = null;
                        }
                    });
                    $scope.importProcessing = null;
                } else {
                    $scope.importProcessing = 'Server Processing...';
                    popups.alertPopup('The server is still processing the data', 'Processing');
                }
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                $scope.msg = err.data.message;
                $scope.importProcessing = null;
            }
        });
    };

    // Upload the selected file to the server. The file is returned in an object converted manner for allocation.
    $scope.uploadSuppfile = function (file) {
        $scope.msg = '';
        if (file) {
            $scope.importProcessing = 'Sending to server...';
            Upload.upload({
                url: '/api/uploadSuppfile/' + sessionId,
                file: file
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    $scope.importProcessing = 'Server Processing...';
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                    $scope.msg = err.data.message;
                }
            });
        }
    };

    // Post the imported voters roll to the database
    $scope.submitRoll = function () {
        $scope.submittingRoll = 'Validating';

        popups.confirmPopup('This will remove the current voters roll entirely and replace it with the new one. Are you sure you want to continue? If there are any votes on the database, the process will be cancelled. Are you sure you want to continue?', 'Submit Voters Roll').then(function () {
            $scope.submittingRoll = 'Submitting';
            $scope.importProcessing = 'Submitting to server...';
            $scope.msg = '';
            $http.post('/api/loadVotersroll/' + sessionId, {
                Fields: $scope.importKeys, // Send the full list of keys and data to the database to compile the supplemental information table
                Mapping: $scope.rollMapping
            }).then(function (resp) {
                if (resp.data.status) { // If the status is true, the import was successful
                    var newrows = resp.data.Rows;
                    popups.confirmPopup('The number of users uploaded was: ' + newrows + '. Does this agree to the original voters roll received', 'Confirmation').then(function () {
                        $scope.importProcessing = null;
                        $scope.msg = '';
                        $scope.importData = null;
                        $scope.errorData = [];
                        $scope.resultExtract = [];
                        $scope.rollMapping = {};
                        $scope.submittingRoll = 'Unavailable';
                        var mintime = Math.round((newrows * 3) / 60 / 60 * 100) / 100; // Accepted that it will take minimum 3 seconds to test a user
                        var maxtime = Math.round((newrows * 15) / 60 / 60 * 100) / 100; // Accepted that it will take maximum 15 seconds to test a user
                        popups.confirmPopup("The votersroll was loaded onto the server. Do you want to test the status of the voters on Identity? It will take between " + mintime + " and " + maxtime + " hours to complete? ", "GUM Syncronisation").then(function () {
                            $http.post('/api/syncVotersroll/' + sessionId, {}).then(function (resp) {
                                if (resp.data && resp.data.status) {
                                    popups.alertPopup("The syncronisation with Identity has started", "GUM Sync");
                                } else {
                                    popups.alertPopup('The syncronisation did not start. Please try again later', 'GUM Sync');
                                }
                            }).catch(function (err) {
                                if (err && err.data && err.data.message) {
                                    popups.alertPopup(err.data.message, 'Error');
                                } else {
                                    popups.alertPopup('An unknown error has occurred. Please contact the administrator', 'Error');
                                }
                            });
                        }).catch(function () {
                            popups.alertPopup("The voters roll will not be syncronised with GUM at this time.", "GUM Sync");
                        });
                    }).catch(function () { // If the users do not agree
                        revertUpdate();
                    });
                } else { // Data errors were noted
                    if (resp.data.Data) {
                        $scope.errorData = resp.data.Data;
                        popups.alertPopup('Some errors were noted in the data to be imported. Please correct this and import the file again.', 'Error');
                        $scope.importProcessing = 'Please Correct Data First';
                    } else {
                        popups.alertPopup('An unknown error has occurred. Please contact your administrator');
                    }
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                    $scope.importProcessing = null;
                    $scope.importData = null;
                    $scope.errorData = [];
                    $scope.resultExtract = [];
                    $scope.rollMapping = {};
                    $scope.submittingRoll = 'Unavailable';
                }
            });
        }).finally(function () {
            $scope.submittingRoll = 'Import Roll';
        });
    };

    function markAllUpdate() {
        $scope.changeList.forEach(function (change) {
            if (change.Old.Voted !== 1) {
                change.Update = true;
            } else {
                change.Update = false;
            }
        });
        $scope.suppList.forEach(function (supp) {
            supp.Update = true;
        });
        $scope.remList.forEach(function (change) {
            if (change.Voted !== 1) {
                change.Update = true;
            } else {
                change.Update = false;
            }
        });
    }

    // Post the imported voters roll to the database
    $scope.submitSuppRoll = function () {
        $scope.submittingRoll = 'Validating';
        popups.confirmPopup('This will import a supplemental roll to the database. Are you sure you want to continue?', 'Submit Supplemental Roll').then(function () {
            $scope.submittingRoll = 'Submitting';
            $scope.importProcessing = 'Submitting to server...';
            $scope.msg = '';
            $http.post('/api/loadSuppVotersroll/' + sessionId, {
                Fields: $scope.importKeys, // Send the full list of keys and data to the database to compile the supplemental information table
                Mapping: $scope.rollMapping
            }).then(function (resp) {
                if (resp.data.status) { // If the status is true, the import was successful
                    popups.alertPopup('The import was successful with ' + resp.data.total + ' rows. The processing has started.');
                    $scope.importProcessing = null;
                    $scope.msg = '';
                    $scope.importData = null;
                    $scope.errorData = [];
                    $scope.resultExtract = [];
                    $scope.rollMapping = {};
                    $scope.submittingRoll = 'Unavailable';
                } else { // Data errors were noted
                    if (resp.data.Data) {
                        $scope.errorData = resp.data.Data;
                        popups.alertPopup('Some errors were noted in the data to be imported. Please correct this and import the file again.', 'Error');
                        $scope.importProcessing = 'Please Correct Data First';
                    } else {
                        popups.alertPopup('An unknown error has occurred. Please contact your administrator');
                    }
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    $scope.msg = err.data.message;
                    $scope.importProcessing = null;
                    $scope.importData = null;
                    $scope.errorData = [];
                    $scope.resultExtract = [];
                    $scope.rollMapping = {};
                    $scope.submittingRoll = 'Unavailable';
                }
            });
        }).finally(function () {
            $scope.submittingRoll = 'Import Roll';
        });
    };

    // Get the changed data to be imported as part of the supplemental roll
    $scope.getImportData = function () {
        $http.get('/api/getImportData/' + sessionId).then(function (resp) {
            if (resp && resp.data) {
                if (resp.data.status) {
                    $scope.changeList = resp.data.Changes;
                    $scope.suppList = resp.data.Suppl;
                    $scope.remList = resp.data.Remain;
                    markAllUpdate();
                    if ($scope.changeList.length == 0 && $scope.suppList.length == 0 && $scope.remList.length == 0) {
                        popups.alertPopup('The process was complete and no changes were noted', 'Supplemental Roll');
                    } else {
                        popups.alertPopup('The process was complete and changes were noted', 'Supplemental Roll');
                    }
                } else {
                    popups.alertPopup(resp.data.message, 'Supplemental Roll');
                }
            } else {
                pop.alertPopup('An unknown error has occurred', 'Error');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    };

    // To submit the changes to the votersroll for processing
    $scope.submitUpdateRoll = function () {
        popups.confirmPopup('Are you sure you want to submit the changes to the database?', 'Update').then(function () {
            $scope.submittingSuppUpdate = true;
            $http.post('/api/submitRollChanges/' + sessionId, {
                Changes: $scope.changeList,
                Import: $scope.suppList,
                Remain: $scope.remList
            }).then(function (resp) {
                if (resp && resp.data && resp.data.status) {
                    popups.alertPopup('The supplemental roll was imported and is being processed');
                    $scope.importProcessing = null;
                    $scope.importData = null;
                    $scope.errorData = [];
                    $scope.resultExtract = [];
                    $scope.rollMapping = {};
                    $scope.submittingRoll = 'Unavailable';
                    $scope.changeList = null;
                    $scope.suppList = null;
                    $scope.remList = null;
                } else {
                    popups.alertPopup('An unknown error has occurred');
                    $scope.importProcessing = null;
                    $scope.importData = null;
                    $scope.errorData = [];
                    $scope.resultExtract = [];
                    $scope.rollMapping = {};
                    $scope.submittingRoll = 'Unavailable';
                    $scope.changeList = null;
                    $scope.suppList = null;
                    $scope.remList = null;
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                }
            }).finally(function () {
                $scope.submittingSuppUpdate = false;
            });
        }).catch(function () {
            popups.alertPopup('The updates were not submitted', 'Cancel');
        });
    };

    $scope.getDataProcessRes = function () {
        $scope.submittingSuppResult = true;
        $scope.msg = null;
        $http.get('/api/getProcessResults/' + sessionId).then(function (resp) {
            if (resp && resp.data) {
                if (resp.data.status) {
                    popups.alertPopup('Processing was done successfully', 'Supplemental Roll Processing');
                } else {
                    popups.alertPopup(resp.data.message, 'Supplemental Roll Processing');
                }
            } else {
                pop.alertPopup('An unknown error has occurred', 'Error');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                $scope.msg = err.data.Errors;
            }
        });
    };

    // Ensure that all the required fields are mapped before allowing the import.
    $scope.testRollChange = function () {
        if ($scope.submittingRoll !== 'Validating') {
            $scope.submittingRoll = 'Unavailable';
            if ($scope.rollMapping.Name != null && $scope.rollMapping.Surname != null && $scope.rollMapping.Email != null && $scope.rollMapping.Organisation != null && $scope.rollMapping.Organisation != '' && $scope.rollMapping.Country != null && $scope.rollMapping.Mobile != null && ($scope.rollMapping.Numb != null || !$scope.SessionSettings.Show_Number)) { // Test that all of the fields should have values
                $scope.submittingRoll = 'Import Roll';
            }
        }
    };

    // To delete the voters roll when it did not upload correctly
    function revertUpdate() {
        $scope.importProcessing = null;
        $scope.importData = null;
        $scope.errorData = [];
        $scope.resultExtract = [];
        $scope.rollMapping = {};
        $scope.submittingRoll = 'Unavailable';
        $http.post('/api/removeVoterRoll/' + sessionId, {}).then(function (resp) {
            popups.alertPopup('The database was cleared', 'Confirmation');
            $scope.msg = '';
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                $scope.msg = err.data.message;
            }
        });
    }

    // Get the number of items in the voters roll. The type specifies whether the original or the supplemental voterroll is counted.
    $scope.getRoll = function (type) {
        $scope.msg = '';
        $http.get('/api/getRollAdmin/' + sessionId + '/' + type).then(function (resp) {
            if (type == 'Original') {
                $scope.rollInfo.Original = resp.data.RowNr;
            } else if (type == 'Supplemental') {
                $scope.rollInfo.Supplemental = resp.data.RowNr;
            } else {
                popups.alertPopup('Unknown voters roll type selected', 'Error');
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    };

    // Get the number of votes currently on the database.
    $scope.getVotes = function () {
        $scope.msg = '';
        $http.get('/api/getVotesAdmin/' + sessionId).then(function (resp) {
            $scope.rollInfo.Votes = resp.data.RowNr;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    };

    // This will clear the items in the supplemental roll. The full voters roll is cleared when a new original roll is imported.
    $scope.clearRoll = function () {
        $scope.msg = '';
        popups.confirmPopup('Are you sure you want to clear the supplemental voters roll?', 'Clear Roll').then(function () {
            $http.post('/api/clearRollAdmin/' + sessionId, {}).then(function (resp) {
                if (resp.data.status) {
                    popups.alertPopup('The supplemental roll has been cleared', 'Information');
                    $scope.getRoll('Supplemental');
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, 'Error');
                    $scope.msg = err.data.message;
                }
            });
        });
    };

    // This will clear all of the votes from the database and mark all of the individuals on the voters roll as not voted.
    $scope.clearVotes = function () {
        $scope.msg = '';
        popups.confirmPopup('Are you sure you want to clear the votes from the database?', 'Clear Votes').then(function () {
            popups.confirmPopup('All votes will be deleted. Are you very sure you want to continue?', 'Confirmation').then(function () {
                $http.post('/api/clearVotesAdmin/' + sessionId, {}).then(function (resp) {
                    if (resp.data.status) {
                        popups.alertPopup('The votes have been cleared', 'Information');
                        $scope.getVotes();
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        popups.alertPopup(err.data.message, 'Error');
                        $scope.msg = err.data.message;
                    }
                });
            });
        });
    };

    $scope.changeIntegration = function () {
        $scope.msg = '';
        $http.post('/api/updatePowerBIIntegration/' + sessionId, {
            Integration: $scope.powerBI.Integration ? 1 : 0
        }).then(function (resp) {
            getPowerBI();
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                $scope.msg = err.data.message;
            }
        });
    };

    $scope.updateIntegrationFields = function () {
        $scope.msg = '';
        $scope.powerBI.Fields.forEach(function (field) {
            field.PowerBI = field.PowerBI ? 1 : 0;
        });
        $http.post('/api/updateBIFields/' + sessionId, {
            Fields: $scope.powerBI.Fields,
            LinkURL: $scope.powerBI.LinkURL
        }).then(function (resp) {
            getPowerBI();
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                $scope.msg = err.data.message;
            }
        });
    };

    $scope.generateGetToken = function () {
        $http.get('/api//getPowerBIToken/' + sessionId).then(function (resp) {
            $scope.powerBI.tokendetails = resp.data;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    };

    // To keep the loading scripts together
    $scope.getSessDetails();
    getPowerBI();
}];



