// Controller for the database management page
module.exports = ['$rootScope', '$scope', '$http', 'appState', 'popups', function ($rootScope, $scope, $http, appState, popups) {

    // Scope variables
    $scope.Databases = [];
    $scope.database = null;
    $scope.sqlScript = '';
    $scope.response = null;
    $scope.sqlResponse = null;
    $scope.keys = null;
    $scope.voterdata = appState.voterdata;
    $rootScope.CurrentSession = null;
    $rootScope.CurrentSessionImage = null;

    // Get the list of sessions from the database
    $scope.getSessions = function () {
        $http.get('/api/sessions').then(function (value) {
            $scope.Databases = value.data;
            $scope.Databases.unshift({
                Id: 0,
                Name: 'Main Database'
            });
        }).catch(function (err) {
            if (err.data && err.data.message)
                alert(err.data.message);
        });
    };

    // Run the script on the database
    $scope.runSQL = function () {
        $scope.response = null;
        $scope.sqlResponse = null;
        $scope.keys = null;
        if (!$scope.database) {
            popups.alertPopup('Please select a database to run on');
        } else {
            $http.post('/api/postSQLStatement', {
                Database: $scope.database,
                statement: $scope.sqlScript
            }).then(function (resp) {
                if (resp.data.status) {
                    if (resp.data.data) { // If there is data returned
                        $scope.response = resp.data.data;
                        if ($scope.response.length > 0) {
                            $scope.keys = Object.keys($scope.response[0]);
                        } else {
                            $scope.keys = null;
                        }
                    } else {
                        $scope.sqlResponse = resp.data.result;
                    }
                }
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    popups.alertPopup(err.data.message, "Error");
                    $scope.sqlResponse = err.data.message;
                }
            });
        }
    };

    // Export the results
    $scope.exportResult = function () {
        $http.post('/api/getSqlResult', {
            data: $scope.response
        }, {
                responseType: 'blob'
            }).then(function (resp) {
                var url = window.URL.createObjectURL(resp.data);
                window.open(url);
            });
    };

    // Download the error log
    $scope.getErrorLog = function () {
        window.open(apiUrl + '/api/getErrorLog/exporttxt?token=' + appState.token);
    };

    // To keep the loading scripts together
    function reloadpage() {
        $scope.getSessions();
    }

    // Initialise
    reloadpage();
}];

