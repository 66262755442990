// Controller to add a non-member to the meeting list
module.exports = ['$http', '$scope', 'params', 'appState', function ($http, scope, params, appState) {

    // Set up the initial variables
    var sessionId = params.sessionId;
    scope.voter = {
        Name: '',
        Surname: '',
        Contact: '',
        Organisation: ''
    };
	scope.errorMsg = null;
    scope.isProcessing = false;

    function _validateInput() {
        scope.errorMsg = null;
        var validated = true;

        if (!scope.voter.Name || scope.voter.Name == '') {
            validated = false;
            scope.errorMsg = 'Please add a Name';
        }

        return validated;
    }

    scope.saveMember = function () {
        if (scope.isProcessing)
			return;

        if (!_validateInput())
            return;

        scope.isProcessing = true;
        
        $http.post('/api/addNonMember/' + sessionId, {
            member: scope.voter
        }).then(function (resp) {
            if (resp && resp.data && resp.data.status) {
                var data = {
                    member: scope.voter,
                    timestamp: resp.data.timestamp
                };
                scope.closeThisDialog(data);
            } else {
                scope.errorMsg = 'Unknown error occurred. Please try again.';
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                scope.error = true;
                scope.errorMsg = err.data.message;
            } else {
                scope.errorMsg = 'Unknown error occured';
            }
        }).finally(function () {
            scope.isProcessing = false;
        });
	};
}];
