// Controller for the account management page
module.exports = ['$scope', '$http', '$cookies', '$state', '$stateParams', 'popups', function ($scope, $http, $cookies, $state, $stateParams, popups) {
    $scope.csrfToken = $cookies.get('XSRF-TOKEN');
    $scope.Email = $stateParams.email;
    $scope.Instructions1 = "Your email address was not on any of the voters' rolls. Please complete all of the required(*) fields in the form below and a support ticket will be raised. Please indicate which session/election you should be added to as well as any other relevant information.";
    $scope.Instructions2 = 'If your access request is approved, you will receive an email from PwC to activate your account. Please follow the instructions in the email. After activation, please try to log in again. Thank you.';

    $scope.activationSubmit = false;
    $scope.msg = null;
    $scope.Name = '';
    $scope.Surname = '';
    $scope.Mobile = '';
    $scope.Organisation = '';
    $scope.Numb = '';
    $scope.VoteSession = '';
    $scope.Comments = '';

    function testFields() {
        $scope.msg = null;
        var errors = [];
        if ($scope.Name === '') {
            errors.push('First Name');
        }
        if ($scope.Surname === '') {
            errors.push('Surname');
        }
        if ($scope.Mobile === '') {
            errors.push('Cellphone Number');
        }
        if ($scope.Organisation === '') {
            errors.push('Organisation');
        }
        if ($scope.VoteSession === '') {
            errors.push('Voting Session');
        }

        if (errors.length > 0) {
            if (errors.length > 1) {
                var errorMsg = 'Please complete the following fields: ';
                errors.forEach(function (err) {
                    errorMsg = errorMsg + err + ', ';
                });
                $scope.msg = errorMsg.slice(0, -2);
                return false;
            } else {
                $scope.msg = 'Please complete the following field: ' + errors[0];
                return false;
            }
        } else {
            return true;
        }
    }

    $scope.SubmitForm = function () {

        if (testFields()) {
            $scope.activationSubmit = true;
            var voter = {
                Name: $scope.Name,
                Surname: $scope.Surname,
                Email: $scope.Email,
                Mobile: $scope.Mobile,
                Numb: $scope.Numb,
                Organisation: $scope.Organisation
            };
            var details = {
                VoteSession: $scope.VoteSession,
                Comments: $scope.Comments
            };
            $http.post('/api/accCreation', {
                voter: voter,
                details: details
            }).then(function (resp) {
                if (resp.data && resp.data.status) {
                    popups.alertPopup('Your query has been submitted. If the access is approved, you will receive an email from PwC to activate your account. Please follow the instructions in the email. After activation, please try to log in again.', 'Account Creation');
                    $state.go('elo.login', {
                        reload: true
                    });
                }
                }).catch(function (err) {
                    popups.alertPopup('There was an error with your submission. Please try again later.', 'Account Creation');
            }).finally(function () {
                $scope.activationSubmit = false;
            });
            
        }
    };
}];