// Controller to add a new user or edit a current user
module.exports = ['$http', '$scope', 'params', 'appState', function ($http, scope, params, appState) {

    // Set up the initial variables
    var users = params.users || [];
    var user = params.user || null;
    var type = params.type;
    var sessionId = params.session || null;

    scope.isCreating = !user;
    scope.isAdministrator = appState.isAdmin;

    scope.countries = [ // Populate the countries dropdown list
        {
            Country: 'South Africa',
            Code: '+27',
            Territory: 'ZA'
        }, {
            Country: 'Lesotho',
            Code: '+266',
            Territory: 'LS'
        }, {
            Country: 'Botswana',
            Code: '+267',
            Territory: 'BW'
        }, {
            Country: 'Namibia',
            Code: '+264',
            Territory: 'NA'
        }, {
            Country: 'Zimbabwe',
            Code: '+263',
            Territory: 'ZW'
        }, {
            Country: 'Mozambique',
            Code: '+258',
            Territory: 'MZ'
        }, {
            Country: 'Swaziland',
            Code: '+268',
            Territory: 'SZ'
        }, {
            Country: 'Malawi',
            Code: '+265',
            Territory: 'MW'
        }, {
            Country: 'Other',
            Code: '+',
            Territory: 'Not Applicable'
        }
    ];

    scope.MobileCode = null;
    scope.mobileNr = '';

    if (type === 'Admin') { // Populate the types dropdown
        scope.userTypes = ['PwC Internal'];
    } else {
        scope.userTypes = ['PwC Internal', 'External User'];
    }

    scope.searchGUID = function () {
        scope.guidErr = null;
        if (!scope.guid || scope.guid === '') {
            scope.guidErr = 'The GUID cannot be empty';
        } else {
            scope.lookupBusy = true;
            $http.post('/users/getInternal', {
                guid: scope.guid
            }).then(function (resp) {
                scope.name = resp.data.name;
                scope.surname = resp.data.surname;
                scope.email = resp.data.email;
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    scope.guidErr = err.data.message;
                }
            }).finally(function () {
                scope.lookupBusy = false;
            });
        }
    };

    scope.populateMobile = function () {
        if (scope.mobileNr.startsWith('0')) {
            scope.mobile = scope.MobileCode.Code + scope.mobileNr.substring(1);
        } else {
            scope.mobile = scope.MobileCode.Code + scope.mobileNr;
        }
    };

    if (!scope.isCreating) { // Populate the fields based on whether this is a new user or editing an existing user
		scope.email = user.Email;
        scope.name = user.Name;
        scope.surname = user.Surname;
        scope.organisation = user.Organisation;
        scope.mobile = user.Mobile;
        scope.countries.forEach(function (item) {
            if (item.Territory === user.Territory) {
                scope.territory = item;
            }
        });
        if (scope.email.includes('pwc.com')) {
            scope.userType = 'PwC Internal';
        } else {
            scope.userType = 'External User';
        }
	} else {
		scope.email = '';
        scope.name = '';
        scope.surname = '';
        scope.organisation = null;
        scope.territory = null;
        scope.mobile = null;
        if (type === 'Admin') {
            scope.userType = 'PwC Internal';
        } else {
            scope.userType = 'External User';
        }
	}

	// status
	scope.error = false;
	scope.errorMsg = null;
    scope.isProcessing = false;

    function _validateInput() {
		scope.error = false;
        scope.errorMsg = null;
        var validated = true;

        if (!scope.email)
			validated = false;

        if (!scope.name || scope.name === '') {
            validated = false;
            scope.errorMsg = 'Please add a name';
        }

        if (!scope.surname || scope.surname === '') {
            validated = false;
            scope.errorMsg = 'Please add a surname';
        }

        if (!scope.isCreating) {
            users.forEach(function (testuser) {
                if (testuser.Email === scope.email && testuser.Id !== user.Id) {
                    scope.frm.email.$error.duplicate = true;
                    validated = false;
                }
            });
        } else {
            users.forEach(function (testuser) {
                if (testuser.Email === scope.email) {
                    scope.frm.email.$error.duplicate = true;
                    validated = false;
                }
            });
        }

        if (scope.userType === 'External User' && (!scope.mobile || scope.mobile.length < 4)) {
            validated = false;
            scope.errorMsg = 'Please add a full mobile number';
        }

        if (scope.userType === 'External User' && !scope.territory) {
            validated = false;
            scope.errorMsg = 'Please add a territory for the user.';
        }

        if (scope.userType === 'External User' && (!scope.organisation || scope.organisation.length < 2)) {
            validated = false;
            scope.errorMsg = 'Please add an organisation for the user.';
        }

        return validated;
    }

    scope.updateUser = function () {
        if (scope.isProcessing)
			return;

        if (!_validateInput())
            return;

        scope.isProcessing = true;
        var url = '';
        var senduser = {
            email: scope.email.toLowerCase(),
            name: scope.name,
            surname: scope.surname,
            organisation: scope.organisation,
            territory: scope.territory ? scope.territory.Territory : null,
            mobile: scope.mobile
        };
        if (type === 'Admin') {
            url = (scope.isCreating ? '/users/NewAdmin' : '/users/editAdmin');
            senduser.isAdmin = 1;
        } else {
            url = (scope.isCreating ? '/users/NewUser/' + sessionId : '/users/editUser/' + sessionId);
        }
        $http.post(url, {
            Id: user ? user.Id : null,
            user: senduser
        }).then(function (resp) {
            if (resp.data && resp.data.status) {
                scope.closeThisDialog();
            } else {
                scope.error = true;
                scope.errorMsg = 'Unknown error occurred. Please try again.';
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                scope.error = true;
                scope.errorMsg = err.data.message;
            } else {
                scope.error = true;
                scope.errorMsg = 'Unknown error occured';
            }
        }).finally(function () {
            scope.isProcessing = false;
        });
	};
}];