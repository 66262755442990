// Controller for the voting screen

module.exports = ['$scope', '$http', '$state', '$rootScope', '$stateParams', 'appState', 'popups', 'ngDialog',
    function ($scope, $http, $state, $rootScope, $stateParams, appState, popups, ngDialog) {
        var sessionId = $stateParams.sessionId;
        var sesstype = $stateParams.type;
        if (!sessionId || isNaN(sessionId) || (!appState.voterdata && sesstype == 'live')) { // If there is no session ID or there is no voterdata on a live session
            $state.go('el.sessions');
            return;
        }

        // Set up the top variables
        $scope.userId = appState.userId;
        $scope.sessionId = sessionId;
        $scope.msg = '';
        $scope.confirmMessage = '';
        $scope.Candidates = [];
        $scope.currentPortfolio = 1;
        var inSummary = false;
        $scope.submitting = false;

        // Set up the views at the start
        $scope.showVoter = true;
        $scope.showDetails = false;
        $scope.showSummary = false;
        $scope.showConfirmation = false;

        // Get the session data from the appState variable
        function setSession() {
            if (sesstype == 'test') {
                $scope.votesession = {};
                $scope.votesession.Name = appState.user.email + ' (Testdata)';
                $scope.votesession.Surname = '';
                $scope.votesession.Numb = '12345 (Testdata)';
                $scope.votesession.Numb_2 = '67890 (Testdata)';
                $scope.votesession.Email = appState.user.email + '(Testdata)';
            } else {
                appState.voterdata.forEach(function (session) {
                    if (session.session.Id == sessionId) {
                        $scope.votesession = session;
                    }
                });
            }
        }

        // Get the candidates from the list. This is called after the user is verified as active on the voters roll.
        function getCandidates() {
            if (sesstype == 'test') {
                $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
                    $scope.Candidates = resp.data.Portfolios;
                    $scope.SessionSettings = resp.data.Details;
                    $rootScope.CurrentSession = $scope.SessionSettings.Name;
                    $rootScope.CurrentSessionImage = null;
                    if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                        $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
                    }
                    setupVoting();
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        $scope.msg = err.data.message;
                    }
                });
            } else {
                $http.get('/api/getCandidates/' + sessionId, null).then(function (resp) {
                    $scope.Candidates = resp.data.Portfolios;
                    $scope.SessionSettings = resp.data.Details;
                    $rootScope.CurrentSession = $scope.SessionSettings.Name;
                    $rootScope.CurrentSessionImage = null;
                    if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                        $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
                    }
                    setupVoting();
                }).catch(function (err) {
                    if (err && err.message) {
                        $scope.msg = err.message;
                    }
                });
            }
        }

        // Add the required fields to the object for the voting screen to function
        function setupVoting() {
            $scope.Candidates.forEach(function (port) {
                port.Candidates.forEach(function (person) {
                    person.Voted = false;
                });
            });
        }

        // Test if the voter is still active on the voters roll
        function testActive() {
            if (sesstype == 'test') {
                setSession();
                getCandidates();
            } else {
                $http.get('/api/isActive/' + sessionId, null).then(function (resp) {
                    if (resp.data.status === 'Active') {
                        setSession();
                        getCandidates();
                    }
                }).catch(function (err) {
                    if (err && err.message) {
                        hideall();
                        $scope.msg = err.message;
                    }
                });
            }
        }

        // Activate the voting boxes if the user is verified
        $scope.voterVerified = function (res) {
            if (res === 'No') {
                popups.alertPopup('Please contact your administrator to correct your details.');
                $rootScope.logout();
                return;
            } else if (res === 'Yes') {
                window.scroll(0, 0);
                $scope.showVoter = false;
                $scope.showDetails = true;
            }
        };

        $scope.calcClass = function () {
            var ending = 85;
            if ($scope.SessionSettings.Show_Party) {
                ending = ending - 20;
            }
            if ($scope.SessionSettings.Show_Photo) {
                ending = ending - 10;
            }
            if ($scope.SessionSettings.Show_Bio) {
                ending = ending - 20;
            }
            var colclass = 'col_' + ending;
            return colclass;
        };

        $scope.viewBio = function (Cand) {
            if (sesstype == 'test') {
                window.open(apiUrl + '/api/getCandBioTest/' + sessionId + '/' + Cand + '?token=' + appState.token);
            } else {
                window.open(apiUrl + '/api/getCandBio/' + sessionId + '/' + Cand + '?token=' + appState.token);
            }
        };

        // Verify that the number of votes per portfolio is correct
        function checkVotes(port, callback) {
            var remaining = port.NumVotes;
            port.Candidates.forEach(function (cand) {
                if (cand.Voted) {
                    remaining--;
                }
            });
            if (remaining < 0) {
                popups.alertPopup('You have selected too many votes. You only have ' + port.NumVotes + ' ' + (port.NumVotes > 1 ? 'votes' : 'vote') + ' available');
                callback(false, null);
            } else if (remaining > 0) {
                popups.confirmPopup('There ' + (remaining > 1 ? 'are' : 'is') + ' ' + remaining + ' ' + (remaining > 1 ? 'votes' : 'vote') + ' remaining. Do you want to abstain the remaining ' + (remaining > 1 ? 'votes' : 'vote') + '?', 'Abstain Votes').then(function () {
                    callback(true, remaining);
                }).catch(function () {
                    callback(false, null);
                });
            } else {
                callback(true, null);
            }
        }

        // To scroll through each portfolio and add the votes
        $scope.selectVote = function (portfolio) {
            checkVotes(portfolio, function (proceed, remain) {
                if (proceed) {
                    window.scroll(0, 0);
                    portfolio.Abstains = remain;

                    if (inSummary) {
                        $scope.currentPortfolio = $scope.Candidates.length + 1;
                        setSummary();
                    } else {
                        $scope.currentPortfolio += 1;
                    }
                    if ($scope.currentPortfolio > $scope.Candidates.length) {
                        inSummary = true;
                        setSummary();
                    }
                }
            });
        };

        // To set the view as the summary
        function setSummary() {
            $scope.showVoter = false;
            $scope.showDetails = false;
            $scope.showSummary = true;
        }

        // To change the view to the specific portfolio
        $scope.changeVote = function (port) {
            $scope.showVoter = false;
            $scope.showDetails = true;
            $scope.showSummary = false;
            $scope.currentPortfolio = port.PortNr;
        };

        // To hide all of the views and show only the error message
        function hideall() {
            $scope.showVoter = false;
            $scope.showDetails = false;
            $scope.showSummary = false;
        }

        // To show the confirmation screen
        function setConfirmation() {
            $scope.showVoter = false;
            $scope.showDetails = false;
            $scope.showSummary = false;
            $scope.showConfirmation = true;
        }

        // To submit the final ballot to the server and show the confirmation.
        $scope.submitBallot = function () {
            if (sesstype == 'test') {
                popups.alertPopup('The vote would have been sent to the database in the live environment');
                setConfirmation();
                $scope.confirmMessage = 'The vote would have been sent to the database in the live environment';
            } else {
                $scope.submitting = true;
                var newVotes = $scope.Candidates;
                newVotes.forEach(function (port) {
                    port.Candidates.forEach(function (cand) {
                        cand.Image = null;
                    });
                });
                $http.post('/api/postVotes/' + sessionId, {
                    Candidates: newVotes
                }).then(function (resp) {
                    if (resp.data.status) {
                        popups.alertPopup(resp.data.message, 'Confirmation');
                        setConfirmation();
                        $scope.confirmMessage = resp.data.message;
                    } else {
                        popups.alertPopup('Something went wrong, please submit again. If the problem persists, please contact your administrator', 'Error');
                    }
                }).catch(function (err) {
                    if (err && err.data && err.data.message) {
                        popups.alertPopup(err.data.message, 'Error');
                    } else {
                        popups.alertPopup('Something went wrong, please submit again. If the problem persists, please contact your administrator', 'Error');
                    }
                }).finally(function () {
                    $scope.submitting = false;
                });
            }
        };

        // Run the get scripts to setup the page
        testActive();
    }

];