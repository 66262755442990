// User management page controller
module.exports = ['$scope', '$rootScope', '$http', 'ngDialog', '$state', 'appState', 'popups', function ($scope, $rootScope, $http, ngDialog, $state, appState, popups) {

    // Initiate variables
    $scope.Users = [];
    $scope.filterusers = [];
    $scope.isAdmin = appState.isAdmin;
    $rootScope.CurrentSession = null;
    $rootScope.CurrentSessionImage = null;

    if ($scope.isAdmin !== 1) {
        $state.go('el.sessions', null, { reload: true });
    }

    // Get all the administrators from the database
    function getAllUsers () {
        $scope.emailinput = '';
        $http.get('/users/getAllAdmins').then(function (resp) {
            $scope.Users = resp.data;
            $scope.filterEmails();
        }).catch(function (err) {
            if (err.status === 403) {
                popups.alertPopup("You don't have enough privilege to access the user data.", "Error");
                $state.go('el.sessions', null, { reload: true });
            } else {
                $scope.msg = err;
                popups.alertPopup("There was an error while fetching users data. Please try refresh the page.", "Error");
            }
        });
    }

    // Filter the emails based on the input box
    $scope.filterEmails = function () {
        $scope.filterusers = [];
        $scope.Users.forEach(function (user) {
            if ($scope.emailinput.length === 0) {
                $scope.filterusers = $scope.Users;
            } else {
                if (user.Email.toUpperCase().includes($scope.emailinput.toUpperCase())) {
                    $scope.filterusers.push(user);
                }
            }
        });
    };

    // Dialog to edit an administrator or to add a new administrator
    $scope.editUserDialog = function (user) {
        ngDialog.open({
            template: require('../tpl/user-edit-dlg.html'),
            plain: true,
            controller: 'userEdit',
            resolve: {
                'params': function () {
                    if (user) {
                        return {
                            'users': $scope.Users,
                            'user': user,
                            'type': 'Admin'
                        };
                    } else {
                        return {
                            'users': $scope.Users,
                            'type': 'Admin'
                        };
                    }
                }
            },
            preCloseCallback: function () {
                getAllUsers();
            },
            showClose: false,
            closeByDocument: false
        });
    };

    // Confirmation to activate or deactivate an administrator
    $scope.deactiveUser = function (user) {
        var message = user.pwdLock ? 'Do you want to activate this user?' : 'Do you want to deactivate this user?';
        popups.confirmPopup(message).then(function () {
            $http.post('/users/ActivateUser', {
                id: user.Id,
                activate: user.pwdLock ? 0 : 1,
                type: 'Admin'
            }).then(function (resp) {
                getAllUsers();
            }).catch(function (err) {
                if (err.data && err.data.message)
                    alert(err.data.message);
            });
        });
    };

    // Run upon loading the page. This will get the data and set up the form.
    getAllUsers();
}];


