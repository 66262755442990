// Controller to manage the voter selection screen

module.exports = ['$scope', '$http', 'params', function (scope, $http, params) {

    // NOTE: closeThisDialog() is injected to the scope by ngDialog

    // Set up the initial parameters
    var sessionId = null;
    if (params) {
        scope.type = params.type;
        scope.SessionSettings = params.sessionSettings;
        sessionId = params.sessionId;
    }
    scope.errorMsg = null;


    // To filter the roll based on the search criteria
    function filterRoll() {
        scope.filterVoters = [];
        if (scope.fullRoll.length > 0) {
            scope.fullRoll.forEach(function (voter) {
                if (voter.Name.toUpperCase().includes(scope.searchCriteria.Name.toUpperCase()) && voter.Surname.toUpperCase().includes(scope.searchCriteria.Surname.toUpperCase()) && voter.Email.toUpperCase().includes(scope.searchCriteria.Email.toUpperCase()) && (voter.Numb == null || voter.Numb.toUpperCase().includes(scope.searchCriteria.Numb.toUpperCase())) && (voter.Numb_2 == null || voter.Numb_2.toUpperCase().includes(scope.searchCriteria.Numb_2.toUpperCase()))) {
                    scope.filterVoters.push(voter);
                }
            });
        }
    }

    // To search the voters roll interactively
    scope.searchRoll = function () {
        var searchLength = scope.searchCriteria.Name.length + scope.searchCriteria.Surname.length + scope.searchCriteria.Email.length + scope.searchCriteria.Numb.length + scope.searchCriteria.Numb_2.length;
        if (searchLength < 5 && scope.fullRoll) { // If the voters roll has been downloaded, but the search criteria changed below five, remove the roll to start over
            scope.fullRoll = null;
            scope.filterVoters = [];
        } else if (searchLength >= 5 && !scope.fullRoll && !scope.gettingData) { // If the search criteria is above 5 characters, there is no current roll downloaded and the roll is not in the process of being downloaded, download the roll
            scope.gettingData = true;
            $http.post('/api/getSearchRoll/' + sessionId, {
                Critera: scope.searchCriteria
            }).then(function (resp) {
                scope.fullRoll = resp.data;
                filterRoll();
            }).catch(function (err) {
                if (err && err.data && err.data.message) {
                    scope.msg = err.data.message;
                }
            }).finally(function () {
                scope.gettingData = false;
            });
        } else if (searchLength >= 5 && !!scope.fullRoll) { // If the search criteria is above 5 characters, but there is a roll, just filter the current roll.
            filterRoll();
        }
    };
     

    // Clear the search criteria and set the variables
    scope.clearCrit = function () {
        scope.searchCriteria = {
            Name: '',
            Surname: '',
            Email: '',
            Numb: '',
            Numb_2: ''
        };
        scope.fullRoll = null;
        scope.filterVoters = [];
        scope.gettingData = false;
    };

    // Use the selected member
    scope.selectMember = function (voter) {
        var data = { voter: voter };
        scope.closeThisDialog(data);
    };

    // Send back a no voter flag
    scope.sendNone = function () {
        var data = {};
        data.voter = { Id: 0 };
        scope.closeThisDialog(data);
    };


    // Run at the start
    scope.clearCrit();

}];